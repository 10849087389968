import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';


// create slice

const name = 'weeklyReward';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const weeklyRewardActions = { ...slice.actions, ...extraActions };
export const weeklyRewardsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        weeklyReward: [],
        weeklyRewardByApp: [],
        error: null,
        loading: false
    }
}

function createReducers() {
    return {
        resetWeeklyRewards
    };

    function resetWeeklyRewards(state) {
        state.weeklyReward = [];
        state.error = null;
        state.loading = false;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/user`;
    const weekly_reward = `${process.env.REACT_APP_API_URL}/weekly_reward`;

    return {
        getWeeklyRewardsList: getWeeklyRewardsList(),
        getWeeklyRewardByApp: getWeeklyRewardByApp(),
    };

    function getWeeklyRewardsList() {
        return createAsyncThunk(
            `${name}/getWeeklyRewardsList`,
            async (data) => await fetchWrapper.post(`${baseUrl}/leaderboard/details`, {user_id: data})
        );
    }

    function getWeeklyRewardByApp() {
        return createAsyncThunk(
            `${name}/getWeeklyRewardByApp`,
            async (data) => await fetchWrapper.post(`${weekly_reward}/get_by_app`, {application_id: data})
        );
    }
}

function createExtraReducers() {
    return {
        ...getWeeklyRewardsList(),
        ...getWeeklyRewardByApp(),
    };

    function getWeeklyRewardsList() {
        var { pending, fulfilled, rejected } = extraActions.getWeeklyRewardsList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.weeklyReward = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }
    function getWeeklyRewardByApp() {
        var { pending, fulfilled, rejected } = extraActions.getWeeklyRewardByApp;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.loading = false;
                state.weeklyRewardByApp = action.payload.data;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }
}
