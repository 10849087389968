import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';


// create slice

const name = 'wallet';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const walletActions = { ...slice.actions, ...extraActions };
export const walletReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        wallet: [],
        error: null,
        loading: false
    }
}

function createReducers() {
    return {
        resetWallet
    };

    function resetWallet(state) {
        state.wallet = [];
        state.error = null;
        state.loading = false;
    }
}

function createExtraActions() {
    // const baseUrl = `${process.env.REACT_APP_API_URL}/wallet`;
    const baseUrl = `${process.env.REACT_APP_API_URL}/user/wallet`;
    return {
        getWalletDetails: getWalletDetails(),
    };

    function getWalletDetails() { 
        return createAsyncThunk(
            `${name}/getWalletDetails`,
            async (data) =>await fetchWrapper.post(`${baseUrl}/details`,{user_id:data})
        );
    }

}

function createExtraReducers() {
    return {
        ...getWalletDetails(),
    };

    function getWalletDetails() {
        var { pending, fulfilled, rejected } = extraActions.getWalletDetails;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.wallet = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

}
