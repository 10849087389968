import React, {useRef, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { friendActions } from "../../../_store";
import {Formik, Field, Form, ErrorMessage} from 'formik';
import { toast } from 'react-toastify';

import gameLogoIcon from "../../assets/images/icon/Icon ionic-logo-game-controller-b.svg";
import gameIcon from "../../assets/images/icon/game.png";
import gameIcon1 from "../../assets/images/icon/game1.png";
import gameIcon2 from "../../assets/images/icon/game2.png";
import gameIcon3 from "../../assets/images/icon/game3.png";
import gameIcon4 from "../../assets/images/icon/game4.png";
import startIcon from "../../assets/images/icon/star.svg";

import * as Yup from "yup";



export default function SelectGameModal({isOpen, handleClose}) {

    const addFriendFormRef = useRef();

    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authProfile = useSelector(x => x.auth.profile);
    const loading = useSelector(x => x.friends.loading);
    const addFriendError = useSelector(x => x.friends.error);
    const addFriendResponse = useSelector(x => x.friends.friend);

    useEffect(() => {
        if (isOpen) {
            const element = document.createElement("div");
            element.setAttribute("class", "modal-backdrop fade show");
            document.body.appendChild(element);
        }
    }, [isOpen]);

    function _handleSubmit(values) {
        values.profile = authProfile?.profile_link;
        values.appsId = authUser?.appInfo?.id;
        dispatch(friendActions.addFriendByEmail({values})).then((response) => {
            if (response.payload?.message) {
                toast.success(response.payload.message);
            }
            if (response?.error) {
                toast.error(response.error.message);
            }
            addFriendFormRef?.current?.resetForm();
            document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
            return handleClose(true);
        });
    }


    return (
        <>
            <div className={"modal fade " + (isOpen ? 'show' : 'hide')} style={{display: isOpen ? 'block' : 'none'}} id="selectGame"  tabIndex="-1">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header row mx-0 border-0 shadow-none">
                            <div className="col-md-12 d-flex">
                                <img src={gameLogoIcon} alt="" style={{width: "30px"}}/>
                                 <h4 className="modal-title text-white ms-2">Select Game To Send Request</h4>
                            </div>
                        </div>
                        <form className="select_game_modal">
                            <div className="modal-body row mx-0">
                                <div className="col-md-6 pe-3">
                                    <a href="#" className="active">
                                        <div className="row d-flex align-items-center justify-content-center my-2">
                                            <div className="col-md-3 pe-0">
                                                <img src={gameIcon} alt="game icon" style={{width: "67px"}} />
                                            </div>
                                            <div className="col-md-9 ps-0 py-1">
                                                <p className="font-13 fw-bold text-white mb-0">Legends of Runeterra</p>
                                                <p className="text-muted font-13 mb-0">Card</p>
                                                <p className="text-white font-10">4.7 <img
                                                    src={startIcon} alt="rating" style={{width: "10px"}}/></p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="row d-flex align-items-center justify-content-center my-2">
                                            <div className="col-md-3 pe-0">
                                                <img src={gameIcon1} alt="game icon" style={{width: "67px"}} />
                                            </div>
                                            <div className="col-md-9 ps-0 py-1">
                                                <p className="font-13 fw-bold text-white mb-0">Warhammer 40,000:
                                                    Tacticus</p>
                                                <p className="text-muted font-13 mb-0">Strategy</p>
                                                <p className="text-white font-10">4.7 <img
                                                    src={startIcon} alt="rating" style={{width: "10px"}}/></p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="row d-flex align-items-center justify-content-center my-2">
                                            <div className="col-md-3 pe-0">
                                                <img src={gameIcon2} alt="game icon" style={{width: "67px"}} />
                                            </div>
                                            <div className="col-md-9 ps-0 py-1">
                                                <p className="font-13 fw-bold text-white mb-0">We'll always have
                                                    Paris</p>
                                                <p className="text-muted font-13 mb-0">Advanture</p>
                                                <p className="text-white font-10">4.5 <img
                                                    src={startIcon} alt="rating" style={{width: "10px"}}/></p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="row d-flex align-items-center justify-content-center my-2">
                                            <div className="col-md-3 pe-0">
                                                <img src={gameIcon3} alt="game icon" style={{width: "67px"}} />
                                            </div>
                                            <div className="col-md-9 ps-0 py-1">
                                                <p className="font-13 fw-bold text-white mb-0">Homescapes</p>
                                                <p className="text-muted font-13 mb-0">Strategy</p>
                                                <p className="text-white font-10">4.7 <img
                                                    src={startIcon} alt="rating" style={{width: "10px"}}/></p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="row d-flex align-items-center justify-content-center my-2">
                                            <div className="col-md-3 pe-0">
                                                <img src={gameIcon4} alt="game icon" style={{width: "67px"}} />
                                            </div>
                                            <div className="col-md-9 ps-0 py-1">
                                                <p className="font-13 fw-bold text-white mb-0">Legends of Runeterra</p>
                                                <p className="text-muted font-13 mb-0">Card</p>
                                                <p className="text-white font-10">4.7 <img
                                                    src={startIcon} alt="rating" style={{width: "10px"}}/></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-md-6 ps-3">
                                    <a href="#">
                                        <div className="row d-flex align-items-center justify-content-center my-2">
                                            <div className="col-md-3 pe-0">
                                                <img src={gameIcon3} alt="game icon" style={{width: "67px"}} />
                                            </div>
                                            <div className="col-md-9 ps-0 py-1">
                                                <p className="font-13 fw-bold text-white mb-0">Legends of Runeterra</p>
                                                <p className="text-muted font-13 mb-0">Card</p>
                                                <p className="text-white font-10">4.7 <img
                                                    src={startIcon} alt="rating" style={{width: "10px"}}/></p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="row d-flex align-items-center justify-content-center my-2">
                                            <div className="col-md-3 pe-0">
                                                <img src={gameIcon1} alt="game icon" style={{width: "67px"}} />
                                            </div>
                                            <div className="col-md-9 ps-0 py-1">
                                                <p className="font-13 fw-bold text-white mb-0">Legends of Runeterra</p>
                                                <p className="text-muted font-13 mb-0">Card</p>
                                                <p className="text-white font-10">4.7 <img
                                                    src={startIcon} alt="rating" style={{width: "10px"}}/></p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="row d-flex align-items-center justify-content-center my-2">
                                            <div className="col-md-3 pe-0">
                                                <img src={gameIcon} alt="game icon" style={{width: "67px"}} />
                                            </div>
                                            <div className="col-md-9 ps-0 py-1">
                                                <p className="font-13 fw-bold text-white mb-0">Legends of Runeterra</p>
                                                <p className="text-muted font-13 mb-0">Card</p>
                                                <p className="text-white font-10">4.7 <img
                                                    src={startIcon} alt="rating" style={{width: "10px"}}/></p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="row d-flex align-items-center justify-content-center my-2">
                                            <div className="col-md-3 pe-0">
                                                <img src={gameIcon4} alt="game icon" style={{width: "67px"}} />
                                            </div>
                                            <div className="col-md-9 ps-0 py-1">
                                                <p className="font-13 fw-bold text-white mb-0">Legends of Runeterra</p>
                                                <p className="text-muted font-13 mb-0">Card</p>
                                                <p className="text-white font-10">4.7 <img
                                                    src={startIcon} alt="rating" style={{width: "10px"}}/></p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="row d-flex align-items-center justify-content-center my-2">
                                            <div className="col-md-3 pe-0">
                                                <img src={gameIcon} alt="game icon" style={{width: "67px"}} />
                                            </div>
                                            <div className="col-md-9 ps-0 py-1">
                                                <p className="font-13 fw-bold text-white mb-0">Legends of Runeterra</p>
                                                <p className="text-muted font-13 mb-0">Card</p>
                                                <p className="text-white font-10">4.7 <img
                                                    src={startIcon} alt="rating" style={{width: "10px"}}/></p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="modal-footer border-0 mx-0">
                                <div className="row w-100">
                                    <div className="col-md-6 my-2">
                                        <a className="login-btn btn-hover color-1" href="#">Send Game Request</a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <button type="button" className="btn-close rounded-circle opacity-100" data-bs-dismiss="modal" onClick={handleClose}></button>
            </div>
        </>
    );

}
