import React, {useRef, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { friendActions } from "../../../_store";
import {Formik, Field, Form, ErrorMessage} from 'formik';
import { toast } from 'react-toastify';

import userPlusIcon from "../../assets/images/icon/Icon feather-user-plus.svg"
import * as Yup from "yup";
import {Modal} from "react-bootstrap";
import greenClockIcon from "../../assets/images/icon/green-clock.svg";



export default function AddFriendModal({isOpen, handleClose}) {

    const addFriendFormRef = useRef();

    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authProfile = useSelector(x => x.auth.profile);
    const loading = useSelector(x => x.friends.loading);
    const addFriendError = useSelector(x => x.friends.error);
    const addFriendResponse = useSelector(x => x.friends.friend);

    function _handleSubmit(values) {
        values.profile = authProfile?.profile_link;
        values.appsId = authUser?.appInfo?.id;
        dispatch(friendActions.addFriendByEmail({values})).then((response) => {
            if (response.payload?.message) {
                toast.success(response.payload.message);
            }
            if (response?.error) {
                toast.error(response.error.message);
            }
            addFriendFormRef?.current?.resetForm();
            return handleClose(true);
        });
    }

    if (addFriendResponse) {}

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            size="md"
            centered
        >
            <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                <div className="col-md-12 d-flex">
                    <img src={userPlusIcon} alt="" style={{width: "30px"}}/>
                    <h4 className="modal-title text-white ms-2">Add Friend</h4>
                </div>
            </Modal.Header>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    email: ''
                }}
                innerRef={addFriendFormRef}
                validationSchema={Yup.object().shape({
                    email: Yup.string().email("Invalid Email address").required("Email is required!")
                })}
                onSubmit={(values) => {
                    _handleSubmit(values)
                }}
            >
                <Form>
                    <div>
                        <div className="modal-body row mx-0">
                            <div className="col mx-2 mt-2">
                                <div className="material-textfield">
                                    <Field
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                    />
                                    <label>Enter your friend's email</label>
                                </div>
                                <ErrorMessage name="email" component="span" className="invalid-feedback">
                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                </ErrorMessage>
                                {addFriendError &&
                                    <p className="text-center text-wrap text-danger mt-3 mb-0">{addFriendError.message}</p>}
                            </div>
                        </div>
                        <div className="modal-footer border-0 mx-0">
                            <div className="row w-100">
                                <div className="col-md-6 my-2">
                                    <button type="submit" className="login-btn btn-hover color-1">
                                        {loading ?
                                            <div className="spinner-border spinner-border-sm text-warning"
                                                 role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div> : 'Submit'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </Modal>
    );

}
