import React, {useRef, useEffect} from "react";


export default function MaintenanceModeModel({isOpen, handleClose}) {

    useEffect(() => {

        if (isOpen) {
            const element = document.createElement("div");
            element.setAttribute("class", "modal-backdrop fade show");
            document.body.appendChild(element);
        }

    }, [isOpen]);
    return (
        <>
            <div className={"modal fade " + (isOpen ? 'show' : 'hide')} style={{display: isOpen ? 'block' : 'none'}} id="MaintenanceMode" tabIndex="-1" >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header row mx-0 border-0 shadow-none">
                            <h4 className="modal-title text-white text-center">Maintenance Mode</h4>
                        </div>
                        <form>
                            <div className="modal-body row mx-0">
                                <div className="text-center">
                                    <img src="assets/images/icon/Group 4682.svg" height="200px" className="rounded py-4" />
                                        <p style={{color: '#b7b7b7', fontSize: '16px'}}>
                                            Currently the system is undergoing Maintenance. Sorry for the<br/>
                                            inconvenience. We will notify you once we are online.
                                        </p>
                                        <p style={{color: '#b7b7b7', fontSize: '16px', fontWeight: 'bold'}}>Thank you for your Co-operations.</p>
                                        <p style={{color: '#b7b7b7', fontSize: '16px', fontWeight: 'bold'}} className="m-0">Support Team</p>
                                        <p style={{color: '#fcd32c', fontSize: '16px', fontWeight: 'bold'}}>Tuti Games</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <button type="button" className="btn-close rounded-circle opacity-100" data-bs-dismiss="modal" onClick={handleClose}></button>
            </div>

        </>
    )
}
