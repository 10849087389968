import React from "react";

import greenClockIcon from "../../assets/images/icon/green-clock.svg";
import appStoreIcon from "../../assets/images/icon/appstore_white.svg";
import playStoreIcon from "../../assets/images/icon/playstore_white.svg";
import chevronRightIcon from "../../assets/images/icon/chevron-right.svg";
import metroWarningIcon from "../../assets/images/icon/Icon metro-warning.svg";
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import DownloadVCoinApp from "../../DownloadVCoinApp";

export default function BuySellVirtuaCoinModal({isOpen, handleClose}) {
    const navigate = useNavigate();
    const authProfile = useSelector(x => x.auth.profile);
    return (
        <>
            <Modal
                show={isOpen}
                onHide={handleClose}
                size="lg"
                centered
            >
                <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                    <div className="col-md-12 d-flex">
                        <img src={greenClockIcon} alt="" style={{width: "30px"}}/>
                        <h4 className="modal-title text-white ms-2">Buy/Sell VirtuaCoin</h4>
                    </div>
                </Modal.Header>
                <div className="modal-body row mx-0 p-0 rounded-bottom">
                    <div className="row p-0">
                        <div className="col-lg-5 col-md-5 border-secondary border-end">
                            <div className="row p-4 pb-0">
                                <p className="font-16 text-white ps-4 mb-0">Current Price</p>
                                <p className="font-oval-single ps-4 font-34 text-white mb-0">
                                    ${_.get(authProfile,"token_price","0")}/Token
                                </p>
                            </div>
                            <div className="row px-4 mt-2">
                                <p className="text-white ps-4 font-13">Buy only from the following sites</p>
                            </div>
                            <div className="row px-5 pb-5">
                                <span
                                   className="btn border-0 btn-hover color-2 py-3 font-13 fw-bold text-start px-3"
                                   style={{backgroundColor: "#FFBB5B"}}
                                   onClick={()=> {
                                       navigate("/shop");
                                       handleClose();
                                   }}
                                >   BUY NOW
                                    <span className="float-end">
                                        <img src={chevronRightIcon} style={{width: "7px"}}/>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 p-5">
                            <div className="row mt-4">
                                <p className="text-white">Download <span className="yellow-heading fw-bold">VirtuaSqap App</span>
                                </p>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 border-secondary border-bottom pb-3">
                                    <DownloadVCoinApp height={150}/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-lg-1 col-md-1 pe-0">
                                    <img src={metroWarningIcon} style={{width: "15px"}}/>
                                </div>
                                <div className="col-lg-11 col-md-11 px-0 mt-1">
                                    <p className="text-white font-13">Beware of scams. Always buy from official
                                        source.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}
