import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';


// create slice

const name = 'appSettings';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const appSettingActions = { ...slice.actions, ...extraActions };
export const appSettingReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        appSettings: null,
        error: null,
        loading: false
    }
}

function createReducers() {
    return {
        resetAppSetting
    };

    function resetAppSetting(state) {
        state.appSettings = null;
        state.error = null;
        state.loading = false;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/app_settings`;

    return {
        getAppSettingById: getAppSettingById(),
    };

    function getAppSettingById() {
        return createAsyncThunk(
            `${name}/getAppSettingById`,
            async (data) => await fetchWrapper.post(`${baseUrl}/details`, {app_setting_id: data.appSettingValues.app_setting_id})
        );
    }

}

function createExtraReducers() {
    return {
        ...getAppSettingById(),
    };

    function getAppSettingById() {
        var { pending, fulfilled, rejected } = extraActions.getAppSettingById;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.appSettings = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

}
