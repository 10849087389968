import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';


// create slice

const name = 'virtuaSwap';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const virtuaSwapActions = { ...slice.actions, ...extraActions };
export const virtuaSwapsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        nft: [],
        error: null,
        loading: false
    }
}

function createReducers() {
    return {
        resetVirtuaSwap
    };

    function resetVirtuaSwap(state) {
        state.nft = [];
        state.error = null;
        state.loading = false;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/nft`;

    return {
        getNFTList: getNFTList(),
    };

    function getNFTList() {
        return createAsyncThunk(
            `${name}/getNFTList`,
            async (data) => await fetchWrapper.post(`${baseUrl}/get`, data)
        );
    }

}

function createExtraReducers() {
    return {
        ...getNFTList(),
    };

    function getNFTList() {
        var { pending, fulfilled, rejected } = extraActions.getNFTList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.nft = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

}
