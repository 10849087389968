import React, { useState, useEffect } from "react";
import {applicationActions} from "../../_store";
import virtuaCoinIcon from "../../components/assets/images/icon/virtua-coin.svg";
import snakeIcon from "../../components/assets/images/snake.png";
import gameIcon1 from "../../components/assets/images/game1.png";
import infiniteIcon from "../../components/assets/images/icon/Icon ionic-md-infinite.svg";
import gameIcon2 from "../../components/assets/images/game2.png";
import gameIcon3 from "../../components/assets/images/game3.png";
import gameIcon4 from "../../components/assets/images/game4.png";
import gameIcon5 from "../../components/assets/images/game5.png";
import gameIcon6 from "../../components/assets/images/game6.png";
import gameIcon7 from "../../components/assets/images/game7.png";
import gameIcon8 from "../../components/assets/images/game8.png";
import gameIcon9 from "../../components/assets/images/game9.png";
import gameIcon10 from "../../components/assets/images/game10.png";
import gameIcon11 from "../../components/assets/images/game11.png";
import gameIcon12 from "../../components/assets/images/game12.png";
import gameIcon13 from "../../components/assets/images/game13.png";
import gameIcon14 from "../../components/assets/images/game14.png";
import gameIcon15 from "../../components/assets/images/game15.png";
import powerIcon1 from "../../components/assets/images/power1.png";
import powerIcon2 from "../../components/assets/images/power2.png";
import powerIcon3 from "../../components/assets/images/power3.png";
import powerIcon4 from "../../components/assets/images/power4.png";
import powerIcon5 from "../../components/assets/images/power5.png";
import powerIcon6 from "../../components/assets/images/power6.png";
import carretUpGreenIcon from "../../components/assets/images/icon/caret-up-green.svg";
import carretDownRedIcon from "../../components/assets/images/icon/caret-down-red.svg";
import featherShareIcon from "../../components/assets/images/icon/Icon feather-share-2.svg";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import SplashScreen from "../../components/layout/splashScreen";
import GameInfoTab from "../../components/GameInfoContent/GameInfoTab";

export default function GameInfo() {
  const dispatch = useDispatch();
  const {id} = useParams();
  const [activeTab, setActiveTab] = useState("info");
  const loading = useSelector( (x) => x.application.loading);

  useEffect(()=>{
    dispatch(applicationActions.getApplicationDetails({id}));
  },[dispatch])

  const getActiveData = (tab) => {
    console.log(tab);
  };

  if (loading) {
    return <SplashScreen/>
  }

  return (
    <>
      <div className="row info">
        <ul className="nav nav-tabs m-4" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className={"nav-link " + (activeTab === "info" ? "active" : "")}
              onClick={() => {
                setActiveTab("info");
                getActiveData("info");
              }}
              id="info-tab"
              data-bs-toggle="tab"
              data-bs-target="#info"
              type="button"
              role="tab"
              aria-controls="info"
              aria-selected="true"
            >
              Info
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={"nav-link " + (activeTab === "shop" ? "active" : "")}
              onClick={() => {
                setActiveTab("shop");
                getActiveData("info");
              }}
              id="shop-tab"
              data-bs-toggle="tab"
              data-bs-target="#shop"
              type="button"
              role="tab"
              aria-controls="shop"
              aria-selected="false"
            >
              Shop
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={"nav-link " + (activeTab === "stats" ? "active" : "")}
              onClick={() => {
                setActiveTab("stats");
                getActiveData("stats");
              }}
              id="my-stats-tab"
              data-bs-toggle="tab"
              data-bs-target="#my-stats"
              type="button"
              role="tab"
              aria-controls="my-stats"
              aria-selected="false"
            >
              My Stats
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
              className={
                  "tab-pane fade px-2 show " +
                  (activeTab === "info" ? "active" : "")
              }
              id="info"
              role="tabpanel"
              aria-labelledby="info-tab"
          >
            <GameInfoTab/>
        </div>
        <div
            className={
                "tab-pane fade px-2 " + (activeTab === "shop" ? "active" : "")
            }
            id="shop"
            role="tabpanel"
            aria-labelledby="shop-tab"
        >
          <div className="row px-1">
            <div className="col-lg-2 col-md-2">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <img src={snakeIcon} className="img-fluid"/>
                </div>
              </div>
            </div>
            <div className="col-lg-10 col-md-10">
              <div className="row mt-3">
                <p className="text-white font-oval-single font-34">
                  Snake Make
                </p>
              </div>
              <div className="row">
                <div className="col-12 ps-1 pe-4 game-tabs">
                  <ul className="nav nav-tabs" id="shop-tabs" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                          className="nav-link active"
                          id="head-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#head"
                          type="button"
                          role="tab"
                          aria-controls="head"
                          aria-selected="false"
                      >
                        Head
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                          className="nav-link"
                          id="body-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#body"
                          type="button"
                          role="tab"
                          aria-controls="body"
                          aria-selected="false"
                      >
                        Body
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                          className="nav-link"
                          id="tail-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#tail"
                          type="button"
                          role="tab"
                          aria-controls="tail"
                          aria-selected="false"
                      >
                        Tail
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                          className="nav-link"
                          id="Powers-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#powers"
                          type="button"
                          role="tab"
                          aria-controls="powers"
                          aria-selected="false"
                      >
                        Powers
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                          className="nav-link"
                          id="taunts-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#taunts"
                          type="button"
                          role="tab"
                          aria-controls="taunts"
                          aria-selected="false"
                      >
                        Taunts
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContentGamesHead">
                    <div
                        className="tab-pane fade show active px-2"
                        id="head"
                        role="tabpanel"
                        aria-labelledby="head-tab"
                    >
                      <div className="row mt-4">
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon1} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                              FREE{" "}
                              <span>
                                <img
                                    src={infiniteIcon}
                                    style={{width: "20px"}}
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon2} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                              FREE{" "}
                              <span>
                                <img
                                    src={infiniteIcon}
                                    style={{width: "20px"}}
                                />
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon3} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              20 <span className="text-secondary">|</span> 2
                              <span className="text-secondary">/50</span>
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon4} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              5 <span className="text-secondary">|</span> 6
                              <span className="text-secondary">/75</span>
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon5} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              10 <span className="text-secondary">|</span> 3
                              <span className="text-secondary">/5</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon6} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              20 <span className="text-secondary">|</span> 2
                              <span className="text-secondary">/50</span>
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon7} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              5 <span className="text-secondary">|</span> 6
                              <span className="text-secondary">/75</span>
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon8} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              20 <span className="text-secondary">|</span> 2
                              <span className="text-secondary">/50</span>
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon9} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              5 <span className="text-secondary">|</span> 6
                              <span className="text-secondary">/75</span>
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon10} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              10 <span className="text-secondary">|</span> 3
                              <span className="text-secondary">/5</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon11} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              20 <span className="text-secondary">|</span> 6
                              <span className="text-secondary">/50</span>
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon12} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              20 <span className="text-secondary">|</span> 6
                              <span className="text-secondary">/50</span>
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon13} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              20 <span className="text-secondary">|</span> 6
                              <span className="text-secondary">/50</span>
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon14} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              20 <span className="text-secondary">|</span> 6
                              <span className="text-secondary">/50</span>
                            </p>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            <img src={gameIcon15} className="img-fluid"/>
                          </div>
                          <div className="row text-center mt-2">
                            <p className="font-16 text-white fw-bold">
                                <span>
                                  <img
                                      src={virtuaCoinIcon}
                                      style={{width: "25px"}}
                                  />
                                </span>
                              20 <span className="text-secondary">|</span> 6
                              <span className="text-secondary">/50</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                        className="tab-pane fade px-2"
                        id="body"
                        role="tabpanel"
                        aria-labelledby="body-tab"
                    ></div>
                    <div
                        className="tab-pane fade px-2"
                        id="tail"
                        role="tabpanel"
                        aria-labelledby="tail-tab"
                    ></div>
                    <div
                        className="tab-pane fade px-2"
                        id="powers"
                        role="tabpanel"
                        aria-labelledby="powers-tab"
                    >
                      <div className="row mt-3">
                        <div className="col-lg-12 col-md-12 border-bottom border-secondary pb-2">
                          <div className="row">
                            <div className="col-lg-1 col-md-1 pe-0">
                              <img
                                  src={powerIcon1}
                                  style={{width: "57px"}}
                              />
                            </div>
                            <div className="col-lg-7 col-md-7 px-0 pt-2">
                              <p className="yellow-heading font-16 mb-0">
                                Hungry
                              </p>
                              <p className="font-13 text-white">
                                Attract all nearest food
                              </p>
                            </div>
                            <div className="col-lg-4 col-md-4 pt-3 text-end">
                              <div className="row">
                                <div className="col pe-0">
                                  <p className="text-white font-13">
                                    3
                                    <span className="text-secondary">
                                        /5{" "}
                                      <a className="btn border-0 ms-2 bg-warning btn-sm font-13 fw-bold btn-hover color-2">
                                          <img
                                              src={virtuaCoinIcon}
                                              style={{width: "20px"}}
                                          />
                                          <span className="text-dark">10</span>
                                        </a>
                                      </span>
                                  </p>
                                </div>
                                <div className="col ps-0">
                                  <a
                                      className="font-13 fw-bold login-btn btn-hover color-1 px-4 py-2"
                                      href="#"
                                  >
                                    Buy{" "}
                                    <i className="far fa-add text-black"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-12 col-md-12 border-bottom border-secondary pb-2">
                          <div className="row">
                            <div className="col-lg-1 col-md-1 pe-0">
                              <img
                                  src={powerIcon2}
                                  style={{width: "57px"}}
                              />
                            </div>
                            <div className="col-lg-7 col-md-7 px-0 pt-2">
                              <p className="yellow-heading font-16 mb-0">
                                Growth Multiplier
                              </p>
                              <p className="font-13 text-white">
                                2x the rate of growth of snakes
                              </p>
                            </div>
                            <div className="col-lg-4 col-md-4 pt-3 text-end">
                              <div className="row">
                                <div className="col pe-0">
                                  <p className="text-white font-13">
                                    3
                                    <span className="text-secondary">
                                        /5{" "}
                                      <a className="btn border-0 ms-2 bg-warning btn-sm font-13 fw-bold btn-hover color-2">
                                          <img
                                              src={virtuaCoinIcon}
                                              style={{width: "20px"}}
                                          />
                                          <span className="text-dark">10</span>
                                        </a>
                                      </span>
                                  </p>
                                </div>
                                <div className="col ps-0">
                                  <a
                                      className="font-13 fw-bold login-btn btn-hover color-1 px-4 py-2"
                                      href="#"
                                  >
                                    Buy{" "}
                                    <i className="far fa-add text-black"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-12 col-md-12 border-bottom border-secondary pb-2">
                          <div className="row">
                            <div className="col-lg-1 col-md-1 pe-0">
                              <img
                                  src={powerIcon3}
                                  style={{width: "57px"}}
                              />
                            </div>
                            <div className="col-lg-7 col-md-7 px-0 pt-2">
                              <p className="yellow-heading font-16 mb-0">
                                Death Alert
                              </p>
                              <p className="font-13 text-white">
                                When a snake dies you will get{" "}
                              </p>
                            </div>
                            <div className="col-lg-4 col-md-4 pt-3 text-end">
                              <div className="row">
                                <div className="col pe-0">
                                  <p className="text-white font-13">
                                    3
                                    <span className="text-secondary">
                                        /5{" "}
                                      <a className="btn border-0 ms-2 bg-warning btn-sm font-13 fw-bold btn-hover color-2">
                                          <img
                                              src={virtuaCoinIcon}
                                              style={{width: "20px"}}
                                          />
                                          <span className="text-dark">10</span>
                                        </a>
                                      </span>
                                  </p>
                                </div>
                                <div className="col ps-0">
                                  <a
                                      className="font-13 fw-bold login-btn btn-hover color-1 px-4 py-2"
                                      href="#"
                                  >
                                    Buy{" "}
                                    <i className="far fa-add text-black"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-12 col-md-12 border-bottom border-secondary pb-2">
                          <div className="row">
                            <div className="col-lg-1 col-md-1 pe-0">
                              <img
                                  src={powerIcon4}
                                  style={{width: "57px"}}
                              />
                            </div>
                            <div className="col-lg-7 col-md-7 px-0 pt-2">
                              <p className="yellow-heading font-16 mb-0">
                                Booster
                              </p>
                              <p className="font-13 text-white">
                                Increase the speed of snake for short span
                              </p>
                            </div>
                            <div className="col-lg-4 col-md-4 pt-3 text-end">
                              <div className="row">
                                <div className="col pe-0">
                                  <p className="text-white font-13">
                                    3
                                    <span className="text-secondary">
                                        /5{" "}
                                      <a className="btn border-0 ms-2 bg-warning btn-sm font-13 fw-bold btn-hover color-2">
                                          <img
                                            src={virtuaCoinIcon}
                                            style={{ width: "20px" }}
                                          />
                                          <span className="text-dark">10</span>
                                        </a>
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col ps-0">
                                    <a
                                      className="font-13 fw-bold login-btn btn-hover color-1 px-4 py-2"
                                      href="#"
                                    >
                                      Buy{" "}
                                      <i className="far fa-add text-black"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-12 col-md-12 border-bottom border-secondary pb-2">
                            <div className="row">
                              <div className="col-lg-1 col-md-1 pe-0">
                                <img
                                  src={powerIcon5}
                                  style={{ width: "57px" }}
                                />
                              </div>
                              <div className="col-lg-7 col-md-7 px-0 pt-2">
                                <p className="yellow-heading font-16 mb-0">
                                  Life
                                </p>
                                <p className="font-13 text-white">
                                  Grant a life when you die
                                </p>
                              </div>
                              <div className="col-lg-4 col-md-4 pt-3 text-end">
                                <div className="row">
                                  <div className="col pe-0">
                                    <p className="text-white font-13">
                                      3
                                      <span className="text-secondary">
                                        /5{" "}
                                        <a className="btn border-0 ms-2 bg-warning btn-sm font-13 fw-bold btn-hover color-2">
                                          <img
                                            src={virtuaCoinIcon}
                                            style={{ width: "20px" }}
                                          />
                                          <span className="text-dark">10</span>
                                        </a>
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col ps-0">
                                    <a
                                      className="font-13 fw-bold login-btn btn-hover color-1 px-4 py-2"
                                      href="#"
                                    >
                                      Buy{" "}
                                      <i className="far fa-add text-black"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-lg-12 col-md-12 border-bottom border-secondary pb-2">
                            <div className="row">
                              <div className="col-lg-1 col-md-1 pe-0">
                                <img
                                  src={powerIcon6}
                                  style={{ width: "57px" }}
                                />
                              </div>
                              <div className="col-lg-7 col-md-7 px-0 pt-2">
                                <p className="yellow-heading font-16 mb-0">
                                  Zoom Out
                                </p>
                                <p className="font-13 text-white">
                                  Zoom out and show the view for short
                                </p>
                              </div>
                              <div className="col-lg-4 col-md-4 pt-3 text-end">
                                <div className="row">
                                  <div className="col pe-0">
                                    <p className="text-white font-13">
                                      3
                                      <span className="text-secondary">
                                        /5{" "}
                                        <a className="btn border-0 ms-2 bg-warning btn-sm font-13 fw-bold btn-hover color-2">
                                          <img
                                            src={virtuaCoinIcon}
                                            style={{ width: "20px" }}
                                          />
                                          <span className="text-dark">10</span>
                                        </a>
                                      </span>
                                    </p>
                                  </div>
                                  <div className="col ps-0">
                                    <a
                                      className="font-13 fw-bold login-btn btn-hover color-1 px-4 py-2"
                                      href="#"
                                    >
                                      Buy{" "}
                                      <i className="far fa-add text-black"></i>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade px-2"
                        id="taunts"
                        role="tabpanel"
                        aria-labelledby="taunts-tab"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "tab-pane fade px-2 " + (activeTab === "stats" ? "active" : "")
            }
            id="my-stats"
            role="tabpanel"
            aria-labelledby="my-stats-tab"
          >
            <div className="row px-3">
              <div className="col-lg-12 col-md-12 background-light-grey rounded-3 border border-secondary px-0 overflow-hidden">
                <div className="row">
                  <table className="table table-condensed table-borderless text-center text-white align-middle">
                    <colgroup>
                      <col span="3"></col>
                      <col
                        span="4"
                        className="background-dark-green-shade2"
                      ></col>
                      <col
                        span="3"
                        className="background-dark-grey-shade2"
                      ></col>
                    </colgroup>
                    <thead>
                      <tr className="background-dark-grey text-white">
                        <td colSpan="3"></td>
                        <td
                          colSpan="4"
                          className="text-center yellow-heading font-13 fw-bold background-dark-green-shade1"
                        >
                          MATCHES
                        </td>
                        <td
                          colSpan="3"
                          className="text-center yellow-heading font-13 fw-bold background-dark-grey-shade1"
                        >
                          TOURNAMENTS
                        </td>
                      </tr>
                      <tr className="background-dark-grey text-secondary font-13 fw-bold">
                        <th></th>
                        <th>Global</th>
                        <th>India</th>
                        <th className="background-dark-green-shade1">Played</th>
                        <th className="background-dark-green-shade1">Wins</th>
                        <th className="background-dark-green-shade1">Lose</th>
                        <th className="background-dark-green-shade1">Draw</th>
                        <th className="background-dark-grey-shade1">Played</th>
                        <th className="background-dark-grey-shade1">
                          2<sup>nd</sup>
                        </th>
                        <th className="background-dark-grey-shade1">
                          1<sup>st</sup>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-secondary border-bottom text-white fw-bold font-13">
                        <td>
                          <div className="col">
                            <p className="text-white font-oval-single font-30 mb-0">
                              30
                            </p>
                            <p className="text-white font-13 mb-0">MIN</p>
                          </div>
                        </td>
                        <td>
                          415{" "}
                          <span>
                            <img
                              src={carretUpGreenIcon}
                              style={{ width: "8px" }}
                            />
                          </span>
                        </td>
                        <td>
                          50{" "}
                          <span>
                            <img
                              src={carretDownRedIcon}
                              style={{ width: "8px" }}
                            />
                          </span>
                        </td>
                        <td>100</td>
                        <td>50</td>
                        <td>40</td>
                        <td>10</td>
                        <td>40</td>
                        <td>5</td>
                        <td>12</td>
                      </tr>
                      <tr className="border-secondary border-bottom text-white fw-bold font-13">
                        <td>
                          <div className="col">
                            <p className="text-white font-oval-single font-30 mb-0">
                              15
                            </p>
                            <p className="text-white font-13 mb-0">MIN</p>
                          </div>
                        </td>
                        <td>
                          51{" "}
                          <span>
                            <img
                              src={carretUpGreenIcon}
                              style={{ width: "8px" }}
                            />
                          </span>
                        </td>
                        <td>
                          5{" "}
                          <span>
                            <img
                              src={carretDownRedIcon}
                              style={{ width: "8px" }}
                            />
                          </span>
                        </td>
                        <td>151</td>
                        <td>85</td>
                        <td>51</td>
                        <td>15</td>
                        <td>23</td>
                        <td>1</td>
                        <td>4</td>
                      </tr>
                      <tr className="border-bottom border-secondary text-white fw-bold font-13">
                        <td>
                          <div className="col">
                            <p className="text-white font-oval-single font-30 mb-0">
                              5
                            </p>
                            <p className="text-white font-13 mb-0">MIN</p>
                          </div>
                        </td>
                        <td>
                          600{" "}
                          <span>
                            <img
                              src={carretUpGreenIcon}
                              style={{ width: "8px" }}
                            />
                          </span>
                        </td>
                        <td>
                          595{" "}
                          <span>
                            <img
                              src={carretDownRedIcon}
                              style={{ width: "8px" }}
                            />
                          </span>
                        </td>
                        <td>221</td>
                        <td>200</td>
                        <td>20</td>
                        <td>1</td>
                        <td>4</td>
                        <td>2</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 text-center pb-3">
                    <button className="btn border-0 bg-white py-2 px-3 font-13 fw-bold">
                      Earned{" "}
                      <span>
                        <img src={virtuaCoinIcon} style={{ width: "20px" }} />
                      </span>{" "}
                      2354
                    </button>
                    <button
                      className="btn border-0 btn-hover color-1 py-2 px-3 font-13 fw-bold"
                      style={{ backgroundColor: "#42dbcb" }}
                    >
                      <img src={featherShareIcon} style={{ width: "15px" }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
