import React, {useState, useEffect} from "react";
import {Col} from "react-bootstrap";



export default function ChatSidebar() {
    return (
        <>
            <Col xs={12} md={4} className="sidebar-chat sidebar-chat-left">
                <div className="sidebar-chat-content">
                    <header className="chat-heading">
                        <h2 className="mb-0">Chat</h2>
                    </header>
                    <div tabIndex="-1">
                        <div className="search_bar mb-2">
                            <div className="input-group align-items-center">
                                <input type="text" className="form-control rounded text-white shadow-none chat-search" placeholder="Search" />
                                <i className="fa-solid fa-magnifying-glass text-white"></i>
                            </div>
                        </div>
                    </div>
                    <div className="chat-list">
                        <div tabIndex="-1" data-tab="3">
                            <div style={{pointerEvents: 'auto'}}>
                                <div className="chat-scroll">
                                    <div className="chat-player" style={{zIndex: 53, height: '72px', transform: 'translateY(216px)', transition: "none 0s ease 0s"}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user.svg"
                                                             alt="" />
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="Johnathan Crane"
                                                                                  className="player-name">Johnathan
                                                                                Crane</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">yesterday</span></div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="Neque porro quisquam...">
                                                                                <span className="player-name chat-msg">Neque
                                                                                    porro
                                                                                    quisquam...</span>
                                                                            </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 50, height: '72px', transform: 'translateY(1008px)', transition: "none 0s ease 0s"}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user1.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="test7"
                                                                                  className="player-name">test7</span>
                                                        </div>
                                                        <div className="playerchat-time">
                                                            <span className="chat-time">Wednesday</span>
                                                        </div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;Ok sir&#x202C;"><span
                                                            className="player-name chat-msg">Ok
                                                                                    sir</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 49, height: '72px', transform: 'translateY(1080px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user2.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="test6"
                                                                                  className="player-name">test6</span>

                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">Wednesday</span></div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;send error screen shot&#x202C;">
                                                                                <span className="player-name chat-msg">send
                                                                                    error
                                                                                    screen
                                                                                    shot</span>
                                                                            </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 47, height: '72px', transform: 'translateY(936px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user3.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="test5"
                                                                                  className="player-name">test5</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">Wednesday</span></div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;Okay&#x202C;"><span
                                                            className="player-name chat-msg">Okay</span></span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 46, height: '72px', transform: 'translateY(648px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user4.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="test1"
                                                                                  className="player-name">test2</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">Thursday</span>
                                                        </div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;I have a credential on the gds but want to know that the reservation I have done on your system to be reflected on my gds account&#x202C;"><span
                                                            className="player-name chat-msg">I
                                                                                    have a
                                                                                    credential on the gds but want to
                                                                                    know
                                                                                    that the reservation I have done on
                                                                                    your
                                                                                    system to be reflected on my gds
                                                                                    account</span></span></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 45, height: '72px', transform: 'translateY(864px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user5.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="test2"
                                                                                  className="player-name">test2</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">Wednesday</span></div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;ok&#x202C;">
                                                                                <span
                                                                                    className="player-name chat-msg">ok</span>
                                                                            </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 44, height: '72px', transform: 'translateY(576px)', transition: 'none 0s ease 0s' }}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user6.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="John Smith"
                                                                                  className="player-name">John
                                                                                Smith</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">Thursday</span>
                                                        </div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;Please go ahead&#x202C;">
                                                                                <span className="player-name chat-msg">Please
                                                                                    go
                                                                                    ahead</span>
                                                                            </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 43, height: '72px', transform: 'translateY(792px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user7.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="test3"
                                                                                  className="player-name">test3</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">Wednesday</span></div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;Great! Thanks&#x202C;"><span
                                                            className="player-name chat-msg">Great!
                                                                                    Thanks</span></span></div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 42, height: '72px', transform: 'translateY(720px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user8.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="test5"
                                                                                  className="player-name">test4</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">Thursday</span>
                                                        </div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages">
                                                                            <span className="_2_LEW"
                                                                                  title="&#x202A;No spanish&#x202C;">
                                                                                <span className="player-name chat-msg">No
                                                                                    spanish</span>
                                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 41, height: '72px', transform: 'translateY(432px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="Berry Allen"
                                                                                  className="player-name">Berry Allen</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">yesterday</span></div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;test.brobongo.com&#x202C;"><span
                                                            className="player-name chat-msg">test.brobongo.com</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 40, height: '72px', transform: 'translateY(504px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user2.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="Jessica Alba"
                                                                                  className="player-name">Jessica Alba</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">Thursday</span>
                                                        </div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;Your payment received&#x202C;">
                                                                                <span className="player-name chat-msg">Your
                                                                                    payment
                                                                                    received</span>
                                                                            </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 39, height: '72px', transform: 'translateY(72px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content _1f1zm">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user3.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="Bruce Wayne"
                                                                                  className="player-name">Bruce Wayne</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">5:37
                                                                                PM</span>
                                                        </div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;ok&#x202C;">
                                                                                <span
                                                                                    className="player-name chat-msg">ok</span>
                                                                            </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 38, height: '72px', transform: 'translateY(144px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user4.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="Oliver Queen"
                                                                                  className="player-name">Oliver Queen</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">4:42
                                                                                PM</span>
                                                        </div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;so we will compile and send you the desktop app&#x202C;">
                                                                                <span className="player-name chat-msg">consectetur
                                                                                    adipiscing elit.</span>
                                                                            </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 37, height: '72px', transform: 'translateY(288px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user5.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="Alfred Pennyworth "
                                                                                  className="player-name">Alfred
                                                                                Pennyworth</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">yesterday</span></div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;Tour visa by uk&#x202C;"><span
                                                            className="player-name chat-msg">sit
                                                                                    amet
                                                                                    consectetur adipiscing
                                                                                    elit.</span></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 36, height: '72px', transform: 'translateY(360px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic"
                                                         style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user6.svg"
                                                             alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="Samantha Smith"
                                                                                  className="player-name">Samantha
                                                                                Smith</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">yesterday</span></div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;Hi&#x202C;">
                                                                                <span
                                                                                    className="player-name chat-msg">Hi</span>
                                                                            </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chat-player" style={{zIndex: 35, height: '72px', transform: 'translateY(0px)', transition: 'none 0s ease 0s'}}>
                                        <div tabIndex="-1">
                                            <div className="chat-player-content">
                                                <div className="player-profile">
                                                    <div className="player-pic" style={{height: '49px', width: '49px'}}>
                                                        <img src="assets/images/icon/chat-user7.svg" alt=""/>
                                                    </div>
                                                </div>
                                                <div className="player-msg">
                                                    <div className="player-last-msg">
                                                        <div className="player-last-msg-show">
                                                                            <span title="Qasim Pk"
                                                                                  className="player-name">Qasim Pk</span>
                                                        </div>
                                                        <div className="playerchat-time"><span
                                                            className="chat-time">7:43
                                                                                PM</span>
                                                        </div>
                                                    </div>
                                                    <div className="player-msg-show">
                                                        <div className="player-messages"><span
                                                            className="_2_LEW"
                                                            title="&#x202A;test&#x202C;">
                                                                                <span
                                                                                    className="player-name chat-msg">test</span>
                                                                            </span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </>
    )
}
