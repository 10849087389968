import React, { useEffect } from 'react';




export default function ReferralHandleSuccessModal({isOpen, handleClose, handleGo}) {

    useEffect(() => {
        if (isOpen) {
            const element = document.createElement("div");
            element.setAttribute("class", "modal-backdrop fade show");
            document.body.appendChild(element);
        }
    }, [isOpen]);

    return (
        <>
            <div className={"modal fade " + (isOpen ? 'show' : 'hide')} style={{display: isOpen ? 'block' : 'none'}} id="REFERRAL"  tabIndex={1} aria-modal='true'>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body row mx-0 py-4">
                            <div className="text-center">
                                <h4 className="modal-title" style={{color: "#42dbcb"}}>REFERRAL HANDLE CREATED
                                    SUCCESSFULLY!</h4>
                                <h4 className="text-white modal-title text-uppercase">Start referring your friends &
                                    earn</h4>
                            </div>
                        </div>
                        <div className="modal-footer border-0 mx-0">
                            <div className="row w-100">
                                <div className="text-center py-2">
                                    <button type="button" className="login-btn btn-hover color-1" onClick={handleGo}>Let's Go</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="button" className="btn-close rounded-circle opacity-100" data-bs-dismiss="modal" onClick={handleClose}></button>
            </div>
        </>
    );

}
