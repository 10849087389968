import React, {useState, useEffect, useRef} from "react";
import {useSelector, useDispatch} from "react-redux";
import {shopActions} from "../../_store";

import ChatSidebar from "../../components/chat/ChatSidebar";
import SelectGameModal from "../../components/layout/modal/SelectGameModal";

import gameControllerIcon from "../../components/assets/images/icon/icon-game-controller.svg";
import sendIcon from "../../components/assets/images/icon/icon-send.svg";
import MaintenanceModeModel from "../../components/appSettings/MaintenanceModeModal";
import {Col, Row} from "react-bootstrap";

function Chat() {
    const [selectGameModal, setSelectGameModal] = useState(false);
    const [maintenanceModeModel, setMaintenanceModeModel] = useState(false);
    const [message, setMessage] = useState('');

    // Redux Methods
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authProfile = useSelector(x => x.auth.profile);


    const closeSelectGameModal = () => {
        document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
        setSelectGameModal(false);
    }
    const closeMaintenanceModeModel = () => {
        document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
        setMaintenanceModeModel(false);
    }

    // write/save message to the database
    async function sendMessage(){
        // check if message is not empty
        if(message) {
            setMessage('');
        } else {
            console.log('message is empty.')
        }
    }

    return (
        <>
            <div className="row">
                <div id="app">
                    <div className="chat-body">
                        <Row className="chat-content two m-0">
                            <ChatSidebar />
                            <Col xs={12} md={8} className="sidebar-chat player-chat-body">
                                <div className="player-chat-content">
                                    <header className="player-chat-header">
                                        <div className="player-chat-profile" role="button">
                                            <div className="player-pic" style={{height: '40px', width: '40px'}} >
                                                <img src="assets/images/icon/chat-user3.svg" alt=""/>
                                                    <span className="active-status"></span>
                                            </div>
                                        </div>
                                        <div className="player-name-header" role="button">
                                            <div className="player-name-chat-body"><span title="Bruce Wayne" className="player-name">Bruce Wayne</span>
                                            </div>
                                            <div className="player-last-seen player-last-seen-cotent"><span
                                                title="I'm playing Tuti Games!" className="game-player-title">
                                                    <img src="assets/images/icon/icon-smile.svg" alt=""
                                                         className="w-auto"/> I'm playing Tuti Games!</span></div>
                                        </div>
                                        <div className="char-header-menu">
                                            <a href="#"><i className="fa-solid fa-ellipsis"></i></a>
                                        </div>
                                    </header>
                                    <div className="players-chat-body">
                                        <div className="players-chat-scroll">
                                            <div className="game-player-chat" tabIndex="0">
                                                <div className="wml2-"></div>
                                                <div className="chat-last-time">
                                                    <div className="chat-last-date" title="Sep 26, 2022">
                                                        <p><span>Sep 26, 2022</span></p>
                                                    </div>
                                                </div>
                                                <div className="players-chat">
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit
                                                                            ame</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit
                                                                            ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit
                                                                            ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit
                                                                            ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit
                                                                            ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit
                                                                            ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="player-out-msg">
                                                        <div className="msg-out-time"><span
                                                            className="_1ORuP"></span><span>12:34 PM</span></div>
                                                        <div className="chat-msg-color chatmsg-width message-in">
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] +90 536 719 04 97: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Lorem
                                                                            ipsum dolor sit amet, consectetur adipiscing
                                                                            elit,
                                                                            sed do eiusmod tempor incididunt ut labore
                                                                            et dolore
                                                                            magna aliqua. </span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="msg-out-time"><span
                                                            className="_1ORuP"></span><span>12:34 PM</span></div>
                                                        <div className="chat-msg-color chatmsg-width message-in">
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] +90 536 719 04 97: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Lorem
                                                                            ipsum dolor sit amet, consectetur adipiscing
                                                                            elit,
                                                                            sed do eiusmod tempor incididunt ut labore
                                                                            et dolore
                                                                            magna aliqua. </span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <footer tabIndex="2" className="chat-footer">
                                        <div className="chat-footer-scroll players-chat-scroll">
                                            <textarea name="name" cols="30" rows="10" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Type here..."></textarea>
                                            <div className="send-msg d-flex align-items-center">
                                                <button type="button" className="btn btn-transparent" onClick={() => {setSelectGameModal(true);}}>
                                                    <img className="w-auto me-3" src={gameControllerIcon} alt="game"/>
                                                </button>
                                                <button type="button" className="btn btn-transparent" onClick={sendMessage}>
                                                    <img className="w-auto" src={sendIcon} alt="send"/>
                                                </button>
                                            </div>
                                        </div>
                                    </footer>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <SelectGameModal isOpen={selectGameModal} handleClose={closeSelectGameModal}></SelectGameModal>
            <MaintenanceModeModel isOpen={maintenanceModeModel} handleClose={closeMaintenanceModeModel}/>
        </>
    );
}


export default Chat;
