import React, { useRef, useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import svgBackImg from "../../assets/images/icon/Group 2344.svg";
import svgPersonImg from "../../assets/images/icon/Icon ionic-ios-person-add.svg";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { userActions } from "../../../_store";
const API_URL = process.env.REACT_APP_API_URL;
export const RESET_PASSWORD = `${API_URL}/password/reset`;
export default function NewPasswordSetModal({
  isOpen,
  handleClose,
  handleBack,
  value,
  modelData,
}) {
  const dispatch = useDispatch();

  const [NewPasswordSetModal, setNewPasswordSetModal] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [responseError, setResponseError] = useState("");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const forgotFormRef = useRef();
  const backLoginModal = () => {
    //document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
    setForgotOtpModal(false);
  };

  return (
    <>
      <div
        className={"modal fade " + (isOpen ? "show" : "hide")}
        style={{ display: isOpen ? "block" : "none" }}
        id="newPasswordSet"
        tabIndex="10"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header row mx-0 border-0 shadow-none">
              <div className="col-md-6 d-flex">
                <img src={svgPersonImg} alt=""/>
                <h4 className="modal-title text-white ms-2">
                  {modelData.title}
                </h4>
              </div>
              <div className="col-md-6 d-flex justify-content-end">
                <img
                    className="virtualogin w-auto"
                    src={require("../../assets/images/virtualogin.png")}
                    alt="virtualogin"
                />
              </div>
            </div>

            <Formik
                enableReinitialize={true}
                initialValues={{
                  email: value?.email,
                  auth_otp: value?.auth_otp,
                  password: "",
                  confirm_password: "",
                }}
                innerRef={forgotFormRef}
                validationSchema={Yup.object().shape({
                  password: Yup.string().required("Password is required"),
                  confirm_password: Yup.string().required(
                      "Confirm password is required"
                  ),
                })}
                onSubmit={(values, {setSubmitting}) => {
                  dispatch(userActions.resetPassword(values))
                      .then((response) => {
                        if (!response?.error && !response.payload?.error) {
                          toast.success(response.payload.message);
                          setNewPasswordSetModal(false);
                          document
                              .querySelectorAll(".modal-backdrop")
                              .forEach((e) => e.remove());
                          return handleClose(true);
                        }
                        if (response?.error) {
                          toast.error(response?.error.message);
                          setNewPasswordSetModal(true);
                        }
                      })
                      .catch((error) => {
                        console.error("An error occurred:", error);
                      })
                      .finally(() => {
                        setSubmitting(false);
                      });
                }}
            >
              {({
                  values,
                  errors,
                  isSubmitting,
                  touched,
                  handleReset,
                  handleSubmit,
                }) => (
                  <>
                    <Form>
                      <div className="modal-body row mx-0">
                        {responseError ? (
                            <p className="text-danger" role="alert">
                              {responseError}
                            </p>
                        ) : (
                            ""
                        )}
                        <div className="row g-3 my-3">
                          <div className="col-12 my-2">
                            <div className="material-textfield">
                              <Field
                                  id="Rpassword"
                                  type={passwordType}
                                  name="password"
                                  placeholder="Password"
                                  autoComplete="off"
                              />
                              <label>
                                Password
                              </label>
                              <i
                                  className={
                                      "far float-end toggle-password " +
                                      (passwordType === "text"
                                          ? "fa-eye"
                                          : "fa-eye-slash")
                                  }
                                  onClick={() => {
                                    setPasswordType(
                                        passwordType === "text" ? "password" : "text"
                                    );
                                  }}
                              />
                            </div>
                            <ErrorMessage name="password" component="span" className="invalid-feedback">
                              {(msg) => <div style={{color: "red"}}>{msg}</div>}
                            </ErrorMessage>
                          </div>
                          <div className="col-12 mt-2">
                            <div className="material-textfield">
                              <Field
                                  id="Cpassword"
                                  type={confirmPasswordType}
                                  name="confirm_password"
                                  placeholder="Confirm Password"
                                  autoComplete="off"
                              />
                              <label> Confirm Password </label>
                              <i
                                  className={
                                      "far float-end toggle-password " +
                                      (confirmPasswordType === "text"
                                          ? "fa-eye"
                                          : "fa-eye-slash")
                                  }
                                  onClick={() => {
                                    setConfirmPasswordType(
                                        confirmPasswordType === "text"
                                            ? "password"
                                            : "text"
                                    );
                                  }}
                              />
                            </div>
                            <ErrorMessage
                                name="confirm_password"
                                component="span"
                                className="invalid-feedback"
                            >
                              {(msg) => <div style={{color: "red"}}>{msg}</div>}
                            </ErrorMessage>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-start modal-footer border-0 mx-0">
                        <div className="d-flex align-items-center">
                          <button
                              type="button"
                              className="no-btn-small me-4"
                              onClick={handleBack}
                              data-bs-target="registerotp"
                              role="modal"
                              data-bs-toggle="modal"
                          >
                            <img src={svgBackImg} alt=""/>
                          </button>

                          <button
                              type="submit"
                              id="newPassword"
                              className="login-btn btn-hover color-1"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Form>
                  </>
              )}
            </Formik>
            <button
                type="button"
                className="btn-close rounded-circle opacity-100"
                data-bs-dismiss="modal"
                onClick={handleClose}
            ></button>
          </div>
        </div>
      </div>
    </>
  );
}
