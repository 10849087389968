import React, {useRef, useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import {authActions, appSettingActions} from "../../../_store";
import {Formik, Field, Form, ErrorMessage} from 'formik';
import {toast} from 'react-toastify';
import ForgotPasswordModal from "./ForgotPasswordModal";
import _ from "lodash";

import registerRighArrowtImg from "../../assets/images/icon/register-right-arrow.svg"
import registerIcon from "../../assets/images/icon/register_icon.svg"
import * as Yup from "yup";
import {Modal} from "react-bootstrap";


function LoginModal({isOpen, handleClose, handleBack, backRegister}) {
    const formRef = useRef();
    const [fieldType, setFieldType] = useState('password');
    const [frgPasswordModal, setFrgPasswordModal] = useState(false);
    // Redux Methods
    const dispatch = useDispatch();
    const authLoading = useSelector(x => x.auth.loading);

    function _handleSubmit(values) {
        dispatch(authActions.login({values})).then((response) => {
            if (response.payload?.message) {
                toast.success(response.payload.message);
                formRef?.current?.resetForm();
                const appSettingValues = {app_setting_id: 1};
                dispatch(appSettingActions.getAppSettingById({appSettingValues}));
                dispatch(authActions.getUserDetails(_.get(response.payload, "data.user_id", "")));
                handleClose();
            }
            if (response?.error) {
                toast.error(response.error.message);
            }
        })
    }

    const closeForgotPasswordModal = (e) => {
        if (e) {
            setFrgPasswordModal(false)
            return handleClose(true);
        } else {
            setFrgPasswordModal(false)
            return handleClose(false);
        }
    }

    const backLoginModal = (e) => {
        setFrgPasswordModal(false);
        return handleBack()
    }


    const openForgotModal = () => {
        setFrgPasswordModal(true);
    }
    return (
        <>
            <Modal
                show={isOpen}
                onHide={handleClose}
                size="md"
                centered
            >
                <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                    <div className="d-flex align-items-center header_vcoin">
                        <img src="/assets/images/logo/LogoYellow.svg" alt=""/>
                        <h4 className="modal-title text-white ms-2" id="loginModalLable">Login</h4>
                    </div>
                </Modal.Header>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                        remember_me:false
                    }}
                    enableReinitialize
                    innerRef={formRef}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email("Invalid email address").required("Email is required!"),
                        password: Yup.string().required("password is required")
                    })}
                    onSubmit={_handleSubmit}
                >
                    <>
                        <div className="modal-body row mx-0">
                            <Form autoComplete="off">
                                <div className="col mt-4">
                                    <div className="material-textfield">
                                        <Field
                                            name="email"
                                            type="email"
                                            placeholder="Enter Email Address"
                                            autoComplete="off"
                                        />
                                        <label>Enter Email Address</label>
                                    </div>
                                    <ErrorMessage name="email" component="span" className="invalid-feedback">
                                        {msg => <p className="text-danger">{msg}</p>}
                                    </ErrorMessage>
                                </div>
                                <div className="col mt-4">
                                    <div className="material-textfield">
                                        <Field
                                            id="password"
                                            type={fieldType} name="password" placeholder="Enter Password"
                                            autoComplete="off"
                                        />
                                        <label>Enter Password</label>
                                        <i
                                            className={"far float-end toggle-password " + (fieldType === 'text' ? 'fa-eye' : 'fa-eye-slash')}
                                            onClick={() => {
                                                setFieldType(fieldType === 'text' ? 'password' : 'text')
                                            }}
                                        />
                                    </div>
                                    <ErrorMessage name="password" component="span" className="invalid-feedback">
                                        {msg => <p className="text-danger">{msg}</p>}
                                    </ErrorMessage>
                                </div>
                                <div className="row my-3">
                                    <div className="d-flex">
                                        <div className="text-white form-check">
                                            <Field
                                                name="remember_me" type="checkbox"
                                                id="remember_me"
                                                className="form-check-input"
                                            />
                                            <label title="" htmlFor="remember_me" className="form-check-label">
                                                Remember Me
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-flex">
                                        <button
                                            type="button"
                                            className="color-light-blue fw-bold font-13 transparent-link"
                                            data-bs-toggle="modal" data-bs-target="#forgotPassword"
                                            onClick={() => {
                                                openForgotModal();
                                                handleClose();
                                            }}
                                        > Forgot Password?
                                        </button>
                                    </div>
                                    <div className="col-md-6 d-flex justify-content-end">
                                        <button type="submit" className="login-btn btn-hover color-1">
                                            {authLoading ?
                                                <div
                                                    className="spinner-border spinner-border-sm text-warning"
                                                    role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                                : 'Login'}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className="modal-footer border-0 mx-0">
                            <div className="row w-100"
                                 onClick={() => {
                                     handleClose();
                                     backRegister();
                                 }}
                            >
                                <div className="col-md-10 d-flex align-items-center">
                                <img src={registerIcon}
                                         className="pe-3 w-auto" alt="Register"/>
                                    <div className="account">
                                        <p className="text-white mb-0">Don't have an account?</p>
                                        <h6>Register</h6>
                                    </div>
                                </div>
                                <div className="col-md-2 my-3 d-flex justify-content-end">
                                    <Link to="#">
                                        <img src={registerRighArrowtImg} alt="register-right-arrow"
                                             className="w-auto"
                                        />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                </Formik>
            </Modal>
            {frgPasswordModal && (
                <ForgotPasswordModal
                    isOpen={frgPasswordModal}
                    handleClose={(e) => {
                        closeForgotPasswordModal(e)
                    }}
                    handleBack={(e) => {
                        backLoginModal(e)
                    }}
                    modelData={{title: "Forgot Password"}}
                />
            )}
        </>
    );

}

export default LoginModal;
