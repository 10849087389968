import React from 'react';

const ActiveStepProfile = (props) => {
    const {
        isActive = false,
        label = ""
    } = props;
    return (
        <>
            {Boolean(isActive) ? (
                <div className="row profile-header">
                    <p className="text-white fw-bold font-16 mb-0">{label}</p>
                    <p className="color-light-green font-10">
                        <span className="btn-label">
                          <i className="fa fa-check color-light-green"></i>
                        </span>{" "}
                        {label}
                    </p>
                </div>
            ) : (
                <div className="row profile-header">
                    <p className="text-muted fw-bold font-16 mb-0">{label}</p>
                    <p className="font-10" style={{color: "#fb8c11", opacity: "0.5"}}>
                        {label}
                    </p>
                </div>
            )}
        </>
    );
};

export default ActiveStepProfile;