import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';


// create slice

const name = 'questions';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const questionActions = { ...slice.actions, ...extraActions };
export const questionsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        questions: [],
        error: null
    }
}

function createReducers() {
    return {
        resetQuestions
    };

    function resetQuestions(state) {
        state.questions = [];
        state.error = null;
    }
}

function createExtraActions() {
    const recoveryUrl = `${process.env.REACT_APP_API_URL}/recovery_questions`

    return {
        getAll: getAll()
    };

    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async () => await fetchWrapper.get(recoveryUrl)
        );
    }

}

function createExtraReducers() {
    return {
        ...getAll()
    };

    function getAll() {
        var { pending, fulfilled, rejected } = extraActions.getAll;
        return {
            [pending]: (state) => {
                state.questions = { loading: true };
            },
            [fulfilled]: (state, action) => {
                var questionList = action.payload.data.map((item) => {
                   return {...item, ans: ''}
                });
                state.questions = questionList;
            },
            [rejected]: (state, action) => {
                state.questions = { error: action.error };
            }
        };
    }

}
