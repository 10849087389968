import React from 'react';
import {Col, Row, Table} from "react-bootstrap";
import firstRankImage from "../../../components/assets/images/icon/firstRankIcon.png";
import secondRankImage from "../../../components/assets/images/icon/secondRankIcon.png";
import thirdRankImage from "../../../components/assets/images/icon/thirdRankImage.png";
import tableUserImage from "../../../components/assets/images/avatar_1.png";
import firstRank from "../../../components/assets/images/icon/first.svg";
import secondRank from "../../../components/assets/images/icon/second.svg";
import thirdRank from "../../../components/assets/images/icon/third.svg";
import spiritPoints from "../../../components/assets/images/icon/spirit-points.svg";

const LeaderboardTab = () => {
    return (
        <>
            <div className="leaderboard_tab">
                <Row>
                    <Col className="first_rank d-flex">
                        <img src={firstRankImage} alt="firstRankImage"/>
                        <div>
                            <div>Spirit Point</div>
                            <div className="spirit_point">
                                <img src={spiritPoints} alt="spiritPoints"/>
                                900,000,000
                            </div>
                        </div>
                        <div className="rank_icon">
                            <img src={firstRank} alt="firstRank"/>
                        </div>
                    </Col>
                    <Col className="second_rank d-flex">
                        <img src={secondRankImage} alt="firstRankImage"/>
                        <div>
                            <div>Spirit Point</div>
                            <div className="spirit_point">
                                <img src={spiritPoints} alt="spiritPoints"/>
                                900,000,000
                            </div>
                        </div>
                        <div className="rank_icon">
                            <img src={secondRank} alt="firstRank"/>
                        </div>
                    </Col>
                    <Col className="third_rank d-flex">
                        <img src={thirdRankImage} alt="firstRankImage"/>
                        <div>
                            <div>Spirit Point</div>
                            <div className="spirit_point">
                                <img src={spiritPoints} alt="spiritPoints"/>
                                900,000,000
                            </div>
                        </div>
                        <div className="rank_icon">
                            <img src={thirdRank} alt="firstRank"/>
                        </div>
                    </Col>
                </Row>
            </div>
            <Table>
                <tbody>
                <tr>
                    <td><div className="table_rank_data">1</div></td>
                    <td>
                        <div className="table_rank_image">
                            <img src={tableUserImage} alt="spiritPoints"/>
                            Spirit Points
                        </div>
                    </td>
                    <td>
                        <div className="spirit_point text-right mt-2">
                            <img src={spiritPoints} alt="spiritPoints"/>
                            900,000,000
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><div className="table_rank_data">2</div></td>
                    <td>
                        <div className="table_rank_image">
                            <img src={tableUserImage} alt="spiritPoints"/>
                            Spirit Points
                        </div>
                    </td>
                    <td>
                        <div className="spirit_point text-right mt-2">
                            <img src={spiritPoints} alt="spiritPoints"/>
                            900,000,000
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><div className="table_rank_data">3</div></td>
                    <td>
                        <div className="table_rank_image">
                            <img src={tableUserImage} alt="spiritPoints"/>
                            Spirit Points
                        </div>
                    </td>
                    <td>
                        <div className="spirit_point text-right mt-2">
                            <img src={spiritPoints} alt="spiritPoints"/>
                            900,000,000
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><div className="table_rank_data">4</div></td>
                    <td>
                        <div className="table_rank_image">
                            <img src={tableUserImage} alt="spiritPoints"/>
                            Spirit Points
                        </div>
                    </td>
                    <td>
                        <div className="spirit_point text-right mt-2">
                            <img src={spiritPoints} alt="spiritPoints"/>
                            900,000,000
                        </div>
                    </td>
                </tr>
                <tr>
                    <td><div className="table_rank_data">5</div></td>
                    <td>
                        <div className="table_rank_image">
                            <img src={tableUserImage} alt="spiritPoints"/>
                            Spirit Points
                        </div>
                    </td>
                    <td>
                        <div className="spirit_point text-right mt-2">
                            <img src={spiritPoints} alt="spiritPoints"/>
                            900,000,000
                        </div>
                    </td>
                </tr>
                </tbody>
            </Table>
        </>
    );
};

export default LeaderboardTab;