import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import groupPlus from "../assets/images/icon/Icon feather-plus-circle.svg";
import {Field, Form, Formik} from "formik";
import exchangeIcon from '../assets/images/exchangeIcon.svg'
import virtuaCoin from '../assets/images/icon/virtua-coin-big.svg'
import deleteIcon from '../assets/images/icon/deleteIcon.svg'
import exchangeVirtuaCoin from '../assets/images/icon/exchangeVirtuaCoin.svg'

const CreateVirtualWalletModal = (props) => {
    const [currentStep, setCurrentStep] = useState(1);

    function _handleSubmit(values) {
        console.log(values)
    }

    const handleClose = () => {
        props.toggle();
        setCurrentStep(1);
    };
    return (
        <Modal
            show={props.isOpen}
            onHide={handleClose}
            size="md"
            centered
        >
            <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                <div className="d-flex align-items-center header_vcoin">
                    <img src="/assets/images/icon/vcoin.svg" alt="" height="25px"/>
                    <h4 className="modal-title text-white ms-2">
                        {currentStep === 1 ? "VirtuaCoin Wallet" : currentStep === 2 ? "Your VirtuaCoin Wallet" : "Remove VirtuaCoin Wallet"}
                    </h4>
                </div>
            </Modal.Header>
            <Modal.Body className="text-white">
                {currentStep === 1 && (
                    <>
                        <span className="mt-3 d-block">Create your VirtuaCoin Wallet</span>
                        <Button
                            type="button"
                            size="lg"
                            className="border-0 font-13 fw-bold btn-hover mt-3 d-block text-black d-flex gap-2 align-items-center px-3 "
                            style={{backgroundColor: "#42DBCB"}}
                            onClick={() => setCurrentStep(2)}
                        > <img src={groupPlus} height="12px" alt=""/> Create
                        </Button>
                    </>
                )}
                {currentStep === 2 && (
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            virtuacoin: ''
                        }}
                        onSubmit={(values) => {
                            _handleSubmit(values)
                        }}
                    >
                        <Form>
                            <div className="d-flex virtuacoin_wallet gap-2 align-items-center">
                                <div>
                                    <div className="field">
                                        <img className="virtua_coin_image" src={virtuaCoin} alt="virtuaCoin"/>
                                        <Field
                                            className="form-control shadow-none fill border-0 py-2 font-13"
                                            type="number"
                                            name="virtuacoin"
                                        />
                                    </div>
                                    <label className="floating-label font-13 text-white">
                                        Exchange the VirtuaCoin Wallet to other coins
                                    </label>
                                </div>
                                <div className="virtuacoin_wallet_image">
                                    <img src={exchangeIcon} alt="exchangeIcon"/>
                                </div>
                                <div>
                                    <div className="field">
                                        <img className="virtua_coin_image" src={exchangeVirtuaCoin} alt="virtuaCoin"/>
                                        <Field
                                            className="form-control shadow-none fill border-0 py-2 font-13"
                                            type="number"
                                            name="virtuacoin"
                                        />
                                    </div>
                                    <label className="floating-label font-13 text-white">
                                        Exchange the VirtuaCoin Wallet to other coins
                                    </label>
                                </div>
                            </div>
                            <Button
                                type="button"
                                size="lg"
                                className="border-0 font-13 fw-bold btn-hover mt-3 d-block text-black d-flex gap-2 align-items-center px-3 "
                                style={{backgroundColor: "#42DBCB"}}
                                onClick={() => setCurrentStep(3)}
                            > Exchange
                            </Button>
                        </Form>
                    </Formik>
                )}
                {currentStep === 3 && (
                    <div className="remove_virtua_coin">
                        <img src={deleteIcon} alt="deleteIcon"/>
                        <div>
                            <span className="mt-3 d-block">Are you sure you want to remove the wallet?</span>
                            <div className="d-flex gap-3">
                                <Button
                                    type="submit"
                                    size="lg"
                                    className="border-0 font-13 fw-bold btn-hover mt-3 d-block text-black d-flex gap-2 align-items-center px-3 "
                                    style={{backgroundColor: "#42DBCB"}}
                                    // onClick={() => setCurrentStep(2)}
                                > Yes
                                </Button>
                                <Button
                                    type="button"
                                    size="lg"
                                    className="border-0 font-13 fw-bold btn-hover mt-3 d-block text-black d-flex gap-2 align-items-center px-3 "
                                    style={{backgroundColor: "#42DBCB"}}
                                    onClick={handleClose}
                                > No
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default CreateVirtualWalletModal;