import React from "react";
import {Link} from 'react-router-dom';

import Group5232Icon from "../../components/assets/images/icon/Group 5232.png";

function Errors() {
    return(
        <>
            <div className="d-flex align-items-center justify-content-center vh-100">
                <div className="text-center">
                    <img src={Group5232Icon} alt="404 image" style={{height: "300px"}} className="rounded py-4"/>
                        <h4 className="text-white font-oval-single font-34">PAGE NOT FOUND</h4>
                        <br/>
                        <Link className="login-btn btn-hover color-1" to="/">Back to Home</Link>
                </div>
            </div>
        </>
    );
}
export default Errors;
