import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {gameActions} from "../../../_store";

function TabMenuItem() {
    const dispatch = useDispatch();
    const gameCategory = useSelector(x => x.games.gameCategory);

    useEffect(() => {
        dispatch(gameActions.getGameCategories());
    }, [dispatch])

    const [activeId, setActiveId] = useState();
    return (
        <>
            <div className="row px-4">
                <div className="player_list py-4 ps-0 float-start">
                    <ul className="ps-0 mb-0">
                        {gameCategory && gameCategory.map((category, index) => (
                            <li
                                key={index}
                                className={"me-2 " + (activeId === category.id ? 'active' : '')}
                                onClick={() => setActiveId(category.id)}
                            >
                                <Link className="rounded px-2" to="#">
                                    <img className="icon-white" src={category.icon} alt=""/>
                                    <img className="icon-green" src={category.icon_green} alt=""/>
                                    {category.name}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default TabMenuItem;
