import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import personAdd from "../../assets/images/icon/Icon ionic-ios-person-add.svg";
import {authActions} from "../../../_store";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

const DeleteAccountConfirmation = ({isOpen,handleClose}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authUser = useSelector((x) => x.auth.user);
    const [authLoading,setAuthLoading] = useState(false);

    const deleteAccount = () => {
        setAuthLoading(true);
        const user_id = authUser && authUser.user_id;
        dispatch(authActions.deleteAccount({ account_id:user_id,user_id:user_id })).then((res) => {
            if (!(res.error && res.error.message)){
                localStorage.clear();
                Cookies.remove('jwt');
            }
            navigate("/");
            handleClose();
        });
        setAuthLoading(false);
    };
    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            size="md"
            centered
        >
            <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                <div className="d-flex">
                    <img src={personAdd} alt=""/>
                    <h4 className="modal-title text-white ms-2">
                        Delete account
                    </h4>
                </div>
            </Modal.Header>
            <Modal.Body className="text-white">
                <p className="mb-2">
                    We are sad that you leaving us!,
                    contact our support if you have any concerns at
                    <a href="mailto:Support@virtuacorp.com.au" target="_blank" className="ms-1">
                        Support@virtuacorp.com.au
                    </a>
                </p>
                <br/>
                <p>
                    Are you sure you want to delete your account!, <br/>
                    This action cannot be reversed,<br/>
                    all your wallet balance will be gone.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn border-0 font-13 fw-bold py-2 btn-hover color-1 ms-2"
                    style={{backgroundColor: "#42dbcb"}}
                    id="button-addon3"
                    onClick={handleClose}
                > No cancel
                </button>
                <button
                    className="btn border-0 bg-danger text-white fw-bold font-13 btn-hover color-3 py-2 me-2"
                    id="button-addon2"
                    onClick={deleteAccount}
                >
                    {authLoading ? (
                        <div
                            className="spinner-border spinner-border-sm text-warning"
                            role="status"
                        >
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : "Yes, proceed"}
                </button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteAccountConfirmation;