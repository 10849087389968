import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";

import group2344Icon from "../../assets/images/icon/Group 2344.svg";
import { profileActions } from '../../../_store';

export default function ResetPinSecondModal({isOpen, handleClose, handleBack}) {

    const [error, setError] = useState(false);
    const [errMessage, setErrMessage] = useState('');
    const dispatch = useDispatch();

    const resetPinSecondFormRef = useRef();
    const otpValue1Ref = useRef(null);
    const otpValue2Ref = useRef(null);
    const otpValue3Ref = useRef(null);
    const otpValue4Ref = useRef(null);
    const otpValue5Ref = useRef(null);
    const otpValue6Ref = useRef(null);
    const otpValue7Ref = useRef(null);
    const otpValue8Ref = useRef(null);

    //Redux Methods
    const authUser = useSelector(x => x.auth.user);

    useEffect(() => {
        if (otpValue1Ref.current) {
            otpValue1Ref.current.focus();
        }
        if (isOpen) {
            const element = document.createElement("div");
            element.setAttribute("class", "modal-backdrop fade show");
            document.body.appendChild(element);
        }
    }, [otpValue1Ref, isOpen]);

    const onChange = (event, valRef) => {
        if (!/[0-9]/.test(event.key)) {
            console.log(resetPinSecondFormRef?.current);
        }
        if (/[0-9]/.test(event.target.value) && valRef) {
            valRef.current.focus();
        }
    }

    function _setResetPin(values) {
        Object.entries(values).map(([key,value])=> {
            if (value === '') {
                setError(true);
                setErrMessage('Please enter pin');
                return false;
            }
        });
        const authOtpGroup1 = Number(`${values.otpValue1}${values.otpValue2}${values.otpValue3}${values.otpValue4}`);
        const authOtpGroup2 = Number(`${values.otpValue5}${values.otpValue6}${values.otpValue7}${values.otpValue8}`);
        if (authOtpGroup1 !== authOtpGroup2) {
            setError(true);
            setErrMessage('Authorized pin & confirm Authorized Pin are Not same')
        } else {
            const values = {
                user_id: authUser.user_id,
                auth_pin: authOtpGroup1,
                confirm_pin: authOtpGroup2,
            }
            if (values) {
                dispatch(profileActions.setPin({values})).then((res)=>{
                    if(res.payload.statusCode === 200){
                        return handleClose(false);
                    }else{
                        return handleClose(true);
                    }
                });
            }
        }
    }

    return(
        <>
            <div className={"modal fade " + (isOpen ? 'show' : 'hide')} style={{display: isOpen ? 'block' : 'none'}} id="resetPin2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header row mx-0 border-0 shadow-none">
                            <div className="col-md-12 d-flex">
                                <img src={group2344Icon} alt="" style={{width: "30px"}} onClick={() => {resetPinSecondFormRef?.current?.resetForm();return handleBack(true)}} />
                                <h4 className="modal-title text-white ms-2">Reset Pin</h4>
                            </div>
                        </div>
                        <Formik
                            initialValues={{
                                otpValue1 : "",
                                otpValue2 : "",
                                otpValue3 : "",
                                otpValue4 : "",
                                otpValue5 : "",
                                otpValue6 : "",
                                otpValue7 : "",
                                otpValue8 : "",
                            }}
                            innerRef={resetPinSecondFormRef}
                            enableReinitialize={true}
                            validationSchema={Yup.object().shape({
                                otpValue1: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                otpValue2: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                otpValue3: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                otpValue4: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                otpValue5: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                otpValue6: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                otpValue7: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                otpValue8: Yup.string().matches(/[0-9]/, "Please enter only digit")
                            })}
                            onSubmit={values => {
                                _setResetPin(values);
                            }}
                        >
                            {({ values, errors, touched, handleReset, handleChange}) => (
                                <Form>
                                    <div className="modal-body row mx-0 py-4">
                                        <p className="text-white mb-0"><small>Enter pin</small></p>
                                        {error ? <p className="text-danger">{errMessage}</p> : ''}
                                        <div className="input-container d-flex flex-row justify-content-start mt-3">
                                            <div className="col-2">
                                                <Field type="text" innerRef={otpValue1Ref} className="m-1 text-center py-3 form-control rounded"
                                                       maxLength="1" placeholder="-" tabIndex={1} name="otpValue1" onInput={(event) => {onChange(event, otpValue2Ref)}}/>
                                            </div>
                                            &nbsp;&nbsp;
                                            <div className="col-2">
                                                <Field type="text" innerRef={otpValue2Ref} className="m-1 text-center py-3 form-control rounded"
                                                       maxLength="1" placeholder="-" tabIndex={2} name="otpValue2" onInput={(event) => {onChange(event, otpValue3Ref)}}/>
                                            </div>
                                            &nbsp;&nbsp;
                                            <div className="col-2">
                                                <Field type="text" innerRef={otpValue3Ref} className="m-1 text-center py-3 form-control rounded"
                                                       maxLength="1" placeholder="-" tabIndex={3} name="otpValue3" onInput={(event) => {onChange(event, otpValue4Ref)}}/>
                                            </div>
                                            &nbsp;&nbsp;
                                            <div className="col-2">
                                                <Field type="text" innerRef={otpValue4Ref} className="m-1 text-center py-3 form-control rounded"
                                                       maxLength="1" placeholder="-" tabIndex={4} name="otpValue4" onInput={(event) => {onChange(event, otpValue5Ref)}}/>
                                            </div>
                                        </div>
                                        <br/>
                                            <p className="text-white mb-0"><small>Confirm Pin</small></p>
                                            <div className="input-container d-flex flex-row justify-content-start mt-3">
                                                <div className="col-2">
                                                    <Field type="text" innerRef={otpValue5Ref} className="m-1 text-center py-3 form-control rounded"
                                                           maxLength="1" placeholder="-" tabIndex={5} name="otpValue5" onInput={(event) => {onChange(event, otpValue6Ref)}}/>
                                                </div>
                                                &nbsp;&nbsp;
                                                <div className="col-2">
                                                    <Field type="text" innerRef={otpValue6Ref} className="m-1 text-center py-3 form-control rounded"
                                                           maxLength="1" placeholder="-" tabIndex={6} name="otpValue6" onInput={(event) => {onChange(event, otpValue7Ref)}}/>
                                                </div>
                                                &nbsp;&nbsp;
                                                <div className="col-2">
                                                    <Field type="text" innerRef={otpValue7Ref} className="m-1 text-center py-3 form-control rounded"
                                                           maxLength="1" placeholder="-" tabIndex={7} name="otpValue7" onInput={(event) => {onChange(event, otpValue8Ref)}}/>
                                                </div>
                                                &nbsp;&nbsp;
                                                <div className="col-2">
                                                    <Field type="text" innerRef={otpValue8Ref} className="m-1 text-center py-3 form-control rounded"
                                                           maxLength="1" placeholder="-" tabIndex={8} name="otpValue8" onInput={(event) => {onChange(event)}}/>
                                                </div>
                                            </div>
                                        <div className="row">
                                            <ErrorMessage name="otpValue1" component="span" className="invalid-feedback">
                                                { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                            </ErrorMessage>
                                            <ErrorMessage name="otpValue2" component="span" className="invalid-feedback">
                                                { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                            </ErrorMessage>
                                            <ErrorMessage name="otpValue3" component="span" className="invalid-feedback">
                                                { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                            </ErrorMessage>
                                            <ErrorMessage name="otpValue4" component="span" className="invalid-feedback">
                                                { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                            </ErrorMessage>
                                            <ErrorMessage name="otpValue5" component="span" className="invalid-feedback">
                                                { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                            </ErrorMessage>
                                            <ErrorMessage name="otpValue6" component="span" className="invalid-feedback">
                                                { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                            </ErrorMessage>
                                            <ErrorMessage name="otpValue7" component="span" className="invalid-feedback">
                                                { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                            </ErrorMessage>
                                            <ErrorMessage name="otpValue8" component="span" className="invalid-feedback">
                                                { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                            </ErrorMessage>
                                        </div>
                                    </div>
                                    <div className="modal-footer border-0 mx-0">
                                        <div className="row w-100">
                                            <div className="col-md-6 my-1">
                                                <button type="submit" className="login-btn btn-hover color-1">Reset Pin</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
                <button type="button" className="btn-close rounded-circle opacity-100" data-bs-dismiss="modal" onClick={() => {resetPinSecondFormRef?.current?.resetForm();return handleClose(false)}}></button>
            </div>
        </>
    )

}
