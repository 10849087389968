import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';

import playerName from "../../components/assets/images/icon/player-name.svg";
import flagIcon from "../../components/assets/images/icon/flag.svg";
import greenUpArrowIcon from "../../components/assets/images/icon/green-up-arrow.svg";
import chess1Icon from "../../components/assets/images/chess1.png";
import dotIcon from "../../components/assets/images/icon/dot.svg";
import sendIcon from "../../components/assets/images/icon/icon-send.svg";

export default function Game() {

    return(
        <>
            <div className="row games-chat">
                <div id="app">
                    <div className="chat-body">
                        <div tabIndex="-1" className="chat-content three">
                            <div className="sidebar-chat sidebar-chat-left">
                                <div className="sidebar-chat-content">
                                    <div className="row px-3 py-2">
                                        <div className="d-flex flex-row ps-3">
                                            <div className="px-3 border-secondary border-end d-flex">
                                                <img src={playerName} style={{width: "35px"}}/>
                                                    <p className="text-white fw-bold font-13 mb-0 pt-2 ps-2">James</p>
                                            </div>
                                            <div className="px-3 border-secondary border-end">
                                                <img src={flagIcon} style={{width: "30px"}} className="pt-2"/>
                                            </div>
                                            <div className="px-3 border-secondary border-end">
                                                <p className="text-secondary pt-2 fst-italic font-13"><span><img
                                                    src={greenUpArrowIcon}
                                                    style={{width: "13px"}}/></span> Ranger</p>
                                            </div>
                                            <div className="px-3">
                                                <p className="text-white fw-bold font-13 pt-2">41231</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row game-background">
                                        <img src={chess1Icon} className="img-fluid" />
                                    </div>
                                    <div
                                        className="row px-2 m-0 py-2 border-top background-dark-grey-shade3 border-secondary"
                                        style={{position: "absolute", bottom: 0, width: "100%"}}>
                                        <div className="d-flex flex-row ps-3">
                                            <div className="px-3 border-secondary border-end d-flex">
                                                <img src={playerName} style={{width: "35px"}}/>
                                                    <p className="text-white fw-bold font-13 mb-0 pt-2 ps-2">James</p>
                                            </div>
                                            <div className="px-3 border-secondary border-end">
                                                <img src={flagIcon} style={{width: "30px"}}
                                                     className="pt-2" />
                                            </div>
                                            <div className="px-3 border-secondary border-end">
                                                <p className="text-secondary pt-2 fst-italic font-13"><span><img
                                                    src={greenUpArrowIcon}
                                                    style={{width: "13px"}}/></span> Ranger</p>
                                            </div>
                                            <div className="px-3">
                                                <p className="text-white fw-bold font-13 pt-2">41231</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-chat player-chat-body player-chat-games">
                                <div className="player-chat-content">
                                    <header className="chat-heading background-body border-bottom border-secondary">
                                        <h2 className="color-green font-22 font-oval-single mb-0">Chat</h2>
                                    </header>
                                    <div className="players-chat-body background-body">
                                        <div className="players-chat-scroll">
                                            <div className="game-player-chat" tabIndex="0">
                                                <div className="players-chat">
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span> 12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit
                                                                            ame</span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit
                                                                            ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit
                                                                            ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit
                                                                            ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit
                                                                            ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit
                                                                            ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="player-out-msg">
                                                        <div className="msg-out-time"><span
                                                            className="_1ORuP"></span><span className="text-secondary">James Wan</span>
                                                            <span><img src={dotIcon}
                                                                       style={{width: "5px"}}/></span> <span
                                                            >12:34 PM</span></div>
                                                        <div className="chat-msg-color chatmsg-width message-in">
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] +90 536 719 04 97: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Lorem
                                                                            ipsum dolor sit amet, consectetur adipiscing
                                                                            elit,
                                                                            sed do eiusmod tempor incididunt ut labore
                                                                            et dolore
                                                                            magna aliqua. </span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="msg-out-time"><span
                                                            className="_1ORuP"></span><span className="text-secondary">James Wan</span>
                                                            <span><img src={dotIcon}
                                                                       style={{width: "5px"}}/></span> <span
                                                            >12:34 PM</span></div>
                                                        <div className="chat-msg-color chatmsg-width message-in">
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] +90 536 719 04 97: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Lorem
                                                                            ipsum dolor sit amet, consectetur adipiscing
                                                                            elit,
                                                                            sed do eiusmod tempor incididunt ut labore
                                                                            et dolore
                                                                            magna aliqua. </span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="player-out-msg">
                                                        <div className="chat-msg-color chatmsg-width message-out">
                                                            <div className="msg-out-time" role="button"><span>12:41
                                                                    PM</span>
                                                            </div>
                                                            <div className="out-msgs">
                                                                <div
                                                                    data-pre-plain-text="[5:37 PM, 11/10/2018] Ahmed Hussain: ">
                                                                    <div className="game-player-chat-msg"><span
                                                                        className="selectable-text">Neque
                                                                            porro quisquam est qui dolorem ipsum quia
                                                                            dolor sit ame</span></div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <footer tabIndex="2" className="chat-footer">
                                        <div className="row">
                                            <div className="chat-footer-scroll players-chat-scroll">
                                            <textarea name="name" cols="30" rows="10"
                                                      placeholder="Type here..."></textarea>
                                                <div className="send-msg d-flex align-items-center">
                                                    <button type="button" className="btn btn-transparent p-0"><img className="w-auto"
                                                                     src={sendIcon}
                                                                     alt="send icon"/></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row background-dark-grey-shade3 border-top border-secondary py-2">
                                            <div className="d-flex flex-row py-1">
                                                <div className="px-3 ms-auto">
                                                    <button type="button" className="btn border-0 bg-warning font-13 fw-bold py-2 btn-hover color-2 px-4">Offer Draw</button>
                                                </div>
                                                <div className="pe-3">
                                                    <button type="button" className="btn font-13 fw-bold py-2 text-white text-center btn-hover color-3 px-3"
                                                       >Leave Game</button>
                                                </div>
                                            </div>
                                        </div>
                                    </footer>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
