import React, {useLayoutEffect, useState, useEffect} from "react";
import { Unity, useUnityContext} from "react-unity-webgl";


function Play() {
    // const [width, setWidth] = useState(window.innerWidth);
    //
    // useEffect(() => {
    //     const handleResize = () => setWidth(window.innerWidth);
    //     window.addEventListener("resize", handleResize);
    //     return () => {
    //         window.removeEventListener("resize", handleResize);
    //     };
    // });
    const { unityProvider } = useUnityContext({
        loaderUrl: "http://build.test/Build/poolFight_webgl_2.loader.js",
        dataUrl: "http://build.test/Build/poolFight_webgl_2.data.unityweb",
        frameworkUrl: "http://build.test/Build/poolFight_webgl_2.framework.js.unityweb",
        codeUrl: "http://build.test/Build/poolFight_webgl_2.wasm.unityweb",
    });

    return(
        <>
            <p>play</p>
            <Unity unityProvider={unityProvider} style={{ height: 600, width: 1360 }}/>
        </>
    )

}


export default Play;
