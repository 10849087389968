import React, {useState, useEffect, useRef} from "react";
import {useSelector, useDispatch} from 'react-redux';
import {referEarnActions} from "../../_store";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Formik, Field, Form, ErrorMessage} from 'formik';

import ApplyHandleModal from "../../components/layout/modal/ApplyHandleModal";
import ReferralHandleSuccessModal from "../../components/layout/modal/ReferralHandleSuccessModal";
import SplashScreen from "../../components/layout/splashScreen";

import group3849 from "../../components/assets/images/icon/Group 3849.svg";
import virtualCoinIcon from "../../components/assets/images/icon/virtua-coin.svg";
import group4743 from "../../components/assets/images/icon/Group 4743.svg";
import group4765 from "../../components/assets/images/icon/Group 4765.svg";
import maskGroup4 from "../../components/assets/images/icon/Mask Group 4.svg";
import awesomeCheckIcon from "../../components/assets/images/icon/Icon awesome-check.svg";
import featherIcon from "../../components/assets/images/icon/Icon feather-copy.svg";
import featherShareIcon from "../../components/assets/images/icon/Icon feather-share-2.svg";
import group5256Icon from "../../components/assets/images/icon/Group 5256.svg";
import group5257Icon from "../../components/assets/images/icon/Group 5257.svg";
import infoCircleIcon from "../../components/assets/images/icon/Icon awesome-info-circle.svg";
import * as Yup from "yup";
import {Col, Row} from "react-bootstrap";
import DownloadVCoinApp from "../../components/DownloadVCoinApp";


function Refer() {

    const [step, setStep] = useState(1);
    const [referral, setReferral] = useState('');
    const [applyHandleModal, setApplyHandleModal] = useState(false);
    const [referralSuccessModal, setReferralSuccessModal] = useState(false);

    const inviteEmailFormRef = useRef();
    const invitePhoneFormRef = useRef();

    // Redux Methods
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authProfile = useSelector(x => x.auth.profile);
    const loading = useSelector(x => x.referEarn.loading);
    const referralDetailsLoading = useSelector(x => x.referEarn.referralDetailsLoading);
    const referralError = useSelector(x => x.referEarn.error);
    const referralDetailsResponse = useSelector(x => x.referEarn.referralDetails);


    const closeApplyHandleModal = (val) => {

        if (val) {
            setReferralSuccessModal(true);
        } else {
            document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
            setApplyHandleModal(false);
        }
    }

    const closeReferralSuccessModal = () => {
        setReferralSuccessModal(false);
        document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
        setStep(3);
    }

    const setNextStep = () => {
        setReferralSuccessModal(false);
        document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
        setStep(3);
        getReferralDetails();
    }

    useEffect(() => {
        if (authUser) {
            dispatch(referEarnActions.getReferralDetails(authUser?.user_id)).then((response) => {
                if (response.payload?.data) {
                    setReferral(response?.payload?.data?.referral_profile_id)
                }
            });
        }
        if (!referralDetailsLoading) {
            if (!!referralDetailsResponse?.referral_profile_id) {
                setReferral(referralDetailsResponse?.referral_profile_id);
                setStep(3);
            }
        }

        //return () => {
        document.body.classList.remove('modal-open');
        document.body.style.overflow = null;
        document.body.style.paddingRight = null;
        //};
    }, []);

    const shareReferralHandle = () => {
        if (navigator.share) {
            navigator
                .share({
                    url: `${referral}`
                })
                .then(() => {
                    console.log("Sharing successfull");
                })
                .catch(() => {
                    console.log("Sharing failed");
                });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    }
    const refreshReferralHandle = () => {
        getReferralDetails()
    }

    const _acceptTerms = (values) => {
        setApplyHandleModal(true);
    }

    function getReferralDetails() {
        const values = {
            user_id: authUser?.user_id
        }
        dispatch(referEarnActions.getReferralDetails({values}));
    }

    function _inviteReferralByEmail(values) {
        values.referral_country_code = referralDetailsResponse?.referral_profile_id;
        values.user_name = authUser?.first_name + ' ' + authUser?.last_name;
        values.link = referralDetailsResponse?.referral_link;
        values.user_email = authUser?.email
        values.user_id= authUser?.user_id
        dispatch(referEarnActions.inviteReferralByEmail({values})).then((response)=>{
            if (!response?.error) {
                inviteEmailFormRef?.current?.resetForm();
            }
        })
    }
    function _inviteReferralByPhone(values) {

        values.referral_country_code = referralDetailsResponse?.referral_profile_id;
        values.user_name = authUser?.first_name + ' ' + authUser?.last_name;
        values.link = referralDetailsResponse?.referral_link;
        values.user_email = authUser?.email
        values.user_id= authUser?.user_id
        dispatch(referEarnActions.inviteReferralByPhone({values})).then((response)=>{
            if (!response?.error) {
                invitePhoneFormRef?.current?.resetForm();
            }
        })
    }

    if (referralDetailsLoading) {
        return (
            <>
                <SplashScreen/>
            </>
        )
    }

    if (!referralDetailsLoading && referralDetailsResponse?.referral_profile_id !== null) {
        //if (referralDetailsResponse?.referral_profile_id !== null) {
            return (
                <>
                    <div className="row">
                        <h4 className="py-3 m-3 text-white page-title">REFER & EARN</h4>
                    </div>
                    <div className="container-fluid">
                        <div className="row px-3">
                            <div className="card px-0 border-0 invite-friend">
                                <div className="card-header" style={{backgroundColor: "#232323"}}>
                                    <div className="row m-0 justify-content-between py-2">
                                        <div className="col-md-2 col-sm-12 pt-2 px-0">
                                            <p className="text-white font-13 ms-0 ms-sm-4">Your referral handle</p>
                                        </div>
                                        <div className="col-md-3 col-sm-12 px-0">
                                            <div className="input-group">
                                                <input
                                                   type="text"
                                                   disabled={true}
                                                   className="form-control group-input-fill font-13 py-2 border-0 shadow-none text-white"
                                                   value={referral !== null ? referral : referralDetailsResponse?.referral_profile_id}
                                                />
                                                <button className="fill border-0 shadow-none rounded-end"
                                                        style={{backgroundColor: "#3B3B3B"}}><img src={awesomeCheckIcon} alt="" className="pe-2" style={{width: "20px"}}/></button>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-12 mt-2 mt-md-0 px-0">
                                            <CopyToClipboard text={referral}>
                                                <button className="btn btn-warning mx-1 btn-hover color-2"><img src={featherIcon}/></button>
                                            </CopyToClipboard>
                                            <button className="btn background-light-green mx-1 btn-hover color-4"
                                                    onClick={() => shareReferralHandle()}><img
                                                src={featherShareIcon}/></button>
                                        </div>
                                        <div className="col-4">
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body rounded-bottom" style={{backgroundColor: "#3B3B3B"}}>
                                    <Col className="row">
                                        <Col className="col-lg-7 col-md-12">
                                            <div className="row">
                                                <h5 className="text-white font-22">INVITE FRIENDS</h5>
                                            </div>
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    email: ''
                                                }}
                                                innerRef={inviteEmailFormRef}
                                                validationSchema={Yup.object().shape({
                                                    email: Yup.string().email("Invalid Email address").required("Email is required!")
                                                })}
                                                onSubmit={(values) => {
                                                    _inviteReferralByEmail(values)
                                                }}
                                            >
                                                {({values, errors, touched, handleReset, handleSubmit}) => (
                                                    <Form>
                                                        <Row className="mt-3 px-1">
                                                            <Col md={1} xs={2}>
                                                                <img src={group5256Icon} alt="" style={{width: "38px"}}/>
                                                            </Col>
                                                            <Col md={9} xs={10}>
                                                                <Field
                                                                    className="form-control shadow-none border-0 font-13 py-2 text-white"
                                                                    type="email" name="email" placeholder="Email"
                                                                    style={{backgroundColor: "#232323"}}/>
                                                                <ErrorMessage name="email" component="span" className="invalid-feedback">
                                                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                                </ErrorMessage>
                                                            </Col>
                                                            <Col md={2} xs={12} className="mt-2 mt-sm-0">
                                                                <button type="submit" className="btn border-0 font-13 fw-bold btn-hover color-1" style={{backgroundColor: "#42dbcb"}} id="button-addon2">
                                                                    {loading && values.email ?
                                                                        <div className="spinner-border spinner-border-sm text-warning" role="status">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </div> : 'invite' }
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    country_code: '',
                                                    phone: ''
                                                }}
                                                innerRef={invitePhoneFormRef}
                                                validationSchema={Yup.object().shape({
                                                    country_code: Yup.number().required("Country code is required!"),
                                                    phone: Yup.string().required("Phone is required!").matches(/[0-9]/, "Please enter only digit"),
                                                })}
                                                onSubmit={(values) => {
                                                    _inviteReferralByPhone(values)
                                                }}
                                            >
                                                {({values, errors, touched, handleReset, handleSubmit}) => (
                                                    <Form>
                                                        <Row className="mt-2 px-1">
                                                            <Col md={1} xs={2}>
                                                                <img src={group5257Icon} alt="" style={{width: "38px"}}/>
                                                            </Col>
                                                            <Col md={2} xs={2}>
                                                                <Field as="select" name="country_code"
                                                                       className="form-select text-white font-13 py-2 border-0 refer-select px-" >
                                                                    <option value="">code</option>
                                                                    <option value="+91">+91</option>
                                                                    <option value="+92">+92</option>
                                                                    <option value="+93">+93</option>
                                                                    <option value="+94">+94</option>
                                                                    <option value="+95">+95</option>
                                                                    <option value="+96">+96</option>
                                                                </Field>
                                                                <ErrorMessage name="country_code" component="span"
                                                                              className="invalid-feedback">
                                                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                                </ErrorMessage>
                                                            </Col>
                                                            <Col md={7} xs={8}>
                                                                <Field
                                                                    className="form-control shadow-none border-0 font-13 text-white py-2 text-white"
                                                                    type="text" name="phone"
                                                                    placeholder="Phone number"
                                                                    style={{backgroundColor: "#232323"}}/>
                                                                <ErrorMessage name="phone" component="span"
                                                                              className="invalid-feedback">
                                                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                                </ErrorMessage>
                                                            </Col>
                                                            <Col md={2} xs={12} className="mt-2 mt-sm-0">
                                                                <button
                                                                    className="btn border-0 font-13 fw-bold btn-hover color-1"
                                                                    style={{backgroundColor: "#42dbcb"}}
                                                                    id="button-addon2">invite
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                            <Row className="">
                                                <Col md={12} className="py-4 px-3 rounded-3" id="refer-earn-body-header">
                                                    <Row>
                                                        <Col md={1}>
                                                            <img src={infoCircleIcon}
                                                                 alt="" style={{width: "25px"}}/>
                                                        </Col>
                                                        <Col md={11}>
                                                            <p className="text-white fw-bold font-13">
                                                                To view earnings go to
                                                                <span style={{color: "#42dbcb"}} className="ms-2">
                                                                    <a target='_blank' style={{color: "#42dbcb"}} href="https://www.virtuacoin.com/">
                                                                        www.virtuacoin.com
                                                                    </a>
                                                                </span>
                                                            </p>
                                                            <p className="text-white font-16 fw-bold">OR</p>
                                                            <p className="text-white font-13 fw-bold mb-2">
                                                                Download the App
                                                            </p>
                                                            <DownloadVCoinApp/>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Col>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        //}
    } else {
        if (step === 1 && !referralDetailsLoading) {
            return (
                <>
                    <div className="row">
                        <h4 className="py-4 m-3 text-white page-title">REFER & EARN</h4>
                    </div>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="row g-3 py-4 m-5">
                                <div className="col-5">
                                    <img src={group3849} alt="" className="pe-2" style={{width: '300px'}}/>
                                </div>
                                <div className="col-1">
                                    <div className="d-flex" style={{width: '300px'}}>
                                        <div className="vr"></div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <p className="text-white font-27">
                                        <span style={{color: '#42dbcb'}} className="fw-bold">Refer</span> your
                                        friends and <span className="text-warning fw-bold">earn</span>
                                    </p>
                                    <p className="text-white font-27 fw-bold text-uppercase"><img
                                        src={virtualCoinIcon} alt="" style={{width: '30px'}}/> VirtuaCoin</p>
                                    <p className="text-white"><small>1 <span
                                        className="text-secondary"> | </span> Create
                                        Referral Handle</small></p>
                                    <p className="text-white"><small>2 <span
                                        className="text-secondary"> | </span> Invite
                                        Friends</small></p>
                                    <p className="text-white"><small>3 <span
                                        className="text-secondary"> | </span> Earn % Bonus
                                        every time your wins on Earns</small></p>
                                    <br/>
                                    <button type="button"
                                            className="btn border-0 font-13 fw-bold py-2 btn-hover color-1 px-4"
                                            onClick={() => {
                                                setStep(2);
                                            }} style={{backgroundColor: "#42dbcb"}} id="button-addon2">Next
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else if (step === 2) {
            return (
                <>
                    <div className="row">
                        <h4 className="py-1 m-3 text-white page-title text-uppercase">Create referral handle</h4>
                        <h6 className="text-secondary"
                            style={{fontSize: '14px', margin: '-15px 0px 0px 17px'}}>Welcome to
                            VirtuaCoin Referral program. we like to thank you for your support</h6>
                    </div>
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="row g-3 py-4 m-4">
                                <div className="col text-center">
                                    <img src={group4743} alt="" className="pe-2"
                                         style={{width: '150px'}}/>
                                    <br/><br/>
                                    <h6 className="text-warning">5%</h6>
                                    <h4 className="text-white"><b>Standard</b></h4>
                                    <p className="text-white"><small>for everyone</small></p>
                                </div>
                                <div className="col">
                                    <div className="d-flex" style={{height: '300px'}}>
                                        <div className="vr"></div>
                                    </div>
                                </div>
                                <div className="col text-center">
                                    <img src={group4765} alt="" className="pe-2" style={{width: '300px'}}/>
                                    <br/><br/>
                                    <h6 className="text-warning">10%</h6>
                                    <h4 className="text-white"><b>Influencer</b></h4>
                                    <p className="text-white"><small>5K followers in VirtuaCoin or 20K followers in
                                        any social media</small></p>
                                </div>
                                <div className="col">
                                    <div className="d-flex" style={{height: '300px'}}>
                                        <div className="vr"></div>
                                    </div>
                                </div>
                                <div className="col text-center">
                                    <img src={maskGroup4} alt="" className="pe-2"
                                         style={{height: '220px'}}/>
                                    <br/><br/>
                                    <h6 className="text-warning">20%</h6>
                                    <h4 className="text-white"><b>Ambassador</b></h4>
                                    <p className="text-white"><small>20K followers in VirtuaCoin or 1M followers in
                                        any social media</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="horizontal-divider border-top opacity-50"></div>
                    <div className="row">
                        <div className="form-check m-3">
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    terms: ''
                                }}
                                innerRef={inviteEmailFormRef}
                                validationSchema={Yup.object().shape({
                                    terms: Yup.string().required("Terms is required!")
                                })}
                                onSubmit={(values) => {
                                    _acceptTerms(values)
                                }}
                            >
                                {({values, errors, touched, handleReset, handleSubmit}) => (
                                    <Form>
                                        <Field type="checkbox" className="form-check-input bg-warning border-0"
                                               name="terms"
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label text-white" htmlFor="flexCheckChecked">
                                            I accept to the <span
                                            style={{color: '#42dbcb'}}>Terms and conditions</span>
                                        </label>
                                        <ErrorMessage name="terms" component="span" className="invalid-feedback">
                                            {msg => <div style={{color: 'red'}}>{msg}</div>}
                                        </ErrorMessage>
                                        <div className="col">
                                            <button type="submit"
                                                    className="btn font-13 fw-bold py-2 px-4 border-0 m-3 btn-hover color-1"
                                                    style={{backgroundColor: '#42dbcb'}}>Create
                                                handle
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                    <ApplyHandleModal isOpen={applyHandleModal} handleClose={closeApplyHandleModal}/>
                    <ReferralHandleSuccessModal isOpen={referralSuccessModal}
                                                handleClose={closeReferralSuccessModal} handleGo={setNextStep}/>
                </>
            )
        }
    }

}

export default Refer;
