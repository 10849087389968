import React, {useEffect, useRef, useState} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
import {authActions, profileActions} from "../../../_store";
import axios from "axios";
import {ErrorMessage, Field, Form, Formik} from 'formik';
import groupImg from "../../assets/images/icon/Group 2344.svg"
import svgRegisterImg from "../../assets/images/icon/register-right-arrow.svg"
import svgLoginImg from "../../assets/images/icon/login-grey.svg"
import personImg from "../../assets/images/icon/Icon ionic-ios-person-add.svg"
import * as Yup from "yup";

const API_URL = process.env.REACT_APP_API_URL;
export const PROFILE_URL = `${API_URL}/user/profile/create`;

const data = [
    {id: 1, icon: 'assets/images/avatar.png'},
    {id: 2, icon: 'assets/images/avatar3.png'},
    {id: 3, icon: 'assets/images/avatar2.png'},
    {id: 4, icon: 'assets/images/avatar.png'},
    {id: 5, icon: 'assets/images/avatar2.png'},
    {id: 6, icon: 'assets/images/avatar2.png'},
    {id: 7, icon: 'assets/images/avatar3.png'},
    {id: 8, icon: 'assets/images/avatar2.png'},
    {id: 9, icon: 'assets/images/avatar.png'},
    {id: 10, icon: 'assets/images/avatar3.png'},
    {id: 11, icon: 'assets/images/avatar2.png'},
    {id: 12, icon: 'assets/images/avatar3.png'},
    {id: 13, icon: 'assets/images/avatar2.png'},
    {id: 14, icon: 'assets/images/avatar.png'},
    {id: 15, icon: 'assets/images/avatar3.png'}
];


export default function RegisterProfileModal({isOpen, handleClose, handleBack}) {

    const [activeAvatar, setActiveAvatar] = useState();
    const [selectAvatar, setSelectAvatar] = useState('');
    const [profilePicture, setProfilePicture] = useState('');
    const [loading, setLoading] = useState(false);
    const [profileError, setProfileError] = useState('');

    const formRef = useRef();
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);

    const toggleClass = (item) => {
        if (item === null) {
            setActiveAvatar(null);
        } else {
            setActiveAvatar(item?.id);
            setSelectAvatar(item.icon);

            let file = window.location.origin+ '/'+item.icon;
            const toDataURL = url => fetch(url)
                .then(async response => {
                    const contentType = response.headers.get('content-type')
                    const blob = await response.blob()
                    return new File([blob], 'avatar.png', {contentType});
                })
               toDataURL(file).then(response => {
                   setProfilePicture(response);
               })


        }
    };

    useEffect(() => {
        if (isOpen) {
            const element = document.createElement("div");
            element.setAttribute("class", "modal-backdrop fade show");
            document.body.appendChild(element);
        }
    }, [dispatch, isOpen]);

    const goBackProfile = () => {
        return handleBack(true);
    }

    async function _saveProfile(values) {
        values.profile_picture = profilePicture;
        setLoading(true);
        const headers = {
            'Content-Type': 'multipart/form-data',
            'X-Authorization': `${authUser?.api_token}`,
            'authid': `${authUser?.plain_obj?.authid}`,
            'mainid': `${authUser?.plain_obj?.mainid}`,
            'accountid': `${authUser?.plain_obj?.accountid}`,
            'salt': `${authUser?.plain_obj?.salt}`,
            'valueverifier': `${authUser?.plain_obj.valueverifier}`,
            'appid': 'FLF2KVSBG72K6O'
        };
        let formData = new FormData();
        formData.append("first_name", values.first_name);
        formData.append("last_name", values.last_name);
        formData.append("nick_name", values.nickname);
        formData.append("date_of_birth", values.date_of_birth);
        formData.append("profile_picture", profilePicture);

        await axios.post(PROFILE_URL, formData, {headers: headers})
            .then((response) => {
                const values = authUser && authUser.user_id;

                dispatch(profileActions.getUserProfiles(values))
                formRef?.current?.resetForm();
                if (!response.data.error) {
                    setLoading(false);
                    return handleClose(true);
                } else {
                    setProfileError(response.data.message)
                    setLoading(false);
                }
            })
        //return dispatch(profileActions.newProfile({values}));
    }
    // if (profile) {
    //     //document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
    //     return handleClose(false);
    // }

    function renderMenuItems() {
        let groupSize = 5;
        let rows = data.map(function(item, index) {
            return (
                    <div key={index} onClick={() => toggleClass(item)}
                         className={"col-md-2 avatar-profile rounded " + (activeAvatar === item.id ? 'active' : '')}>
                        <input type="radio" id={item.id} name="check-substitution-2" />
                        <label className="btn d-flex justify-content-center p-0 rounded-circle" htmlFor="ANSI"  style={{width: '100%', height: '80px'}}>
                            <img src={item.icon} alt="" style={{width: '80px'}}/>
                            <span className="check-icon"></span>
                        </label>
                    </div>
            );
        }).reduce(function(r, element, index) {
            index % groupSize === 0 && r.push([]);
            r[r.length - 1].push(element);
            return r;
        }, []).map(function(rowContent, index) {
            // surround every group with 'row'
            return <div key={index} className="row justify-content-between" style={{paddingLeft: '5px', paddingRight: '5px'}}>{rowContent}</div>;
        });
        return rows;
    }

    return (
        <>
            <div className={"modal fade " + (isOpen ? 'show' : 'hide')} id="selectavatar" tabIndex={1} aria-modal='true'
                 style={{display: isOpen ? 'block' : 'none'}}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header row mx-0 border-0 shadow-none">
                            <div className="col-md-6 d-flex">
                                <img src={personImg} alt=""/>
                                <h4 className="modal-title text-white ms-2">Register</h4>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end">
                                <img className="virtualogin w-auto" src={require('../../assets/images/virtualogin.png')}
                                     alt="virtualogin"/>
                            </div>
                        </div>

                        <Formik
                            initialValues={{
                                first_name: '',
                                last_name: '',
                                nickname: '',
                                date_of_birth: ''
                            }}
                            enableReinitialize={true}
                            innerRef={formRef}
                            validationSchema={Yup.object().shape({
                                first_name: Yup.string().required("First Name is required"),
                                last_name: Yup.string().required("Last Name is required"),
                                nickname: Yup.string().required("Nick Name is required")
                            })}
                            onSubmit={(values => {
                                _saveProfile(values)
                            })}
                        >
                            {({values, setFieldValue}) => (
                                <>
                                    <div className="modal-body row mx-0">
                                        <Form>
                                            <div className="row g-3">
                                                <div className="col">
                                                    <fieldset>
                                                        <legend className="mx-3 px-1 mb-0">First Name</legend>
                                                        <Field
                                                            className="form-control bg-transparent shadow-none text-white py-2 font-13 "
                                                            type="text" name="first_name" placeholder="First Name"/>
                                                    </fieldset>
                                                    <ErrorMessage name="first_name" component="span"
                                                                  className="invalid-feedback">
                                                        {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                    </ErrorMessage>
                                                </div>
                                                <div className="col form-float btn-add">
                                                    <Field
                                                        className="form-control shadow-none text-white py-2 border-0 font-13 password"
                                                        type="text" name="last_name"
                                                        placeholder="Last Name"/>
                                                    <label className="floating-label font-13 text-white">Last
                                                        Name</label>
                                                    <ErrorMessage name="last_name" component="span"
                                                                  className="invalid-feedback">
                                                        {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="col mt-4 form-float btn-add">
                                                <fieldset>
                                                    <legend className="mx-4 px-1 mb-0">Nickname</legend>
                                                    <Field
                                                        className="form-control bg-transparent shadow-none font-13 py-2 text-white"
                                                        type="text" name="nickname" placeholder="NickName"/>
                                                </fieldset>
                                                <ErrorMessage name="nickname" component="span"
                                                              className="invalid-feedback">
                                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </div>
                                            <div className="col mt-4 form-float btn-add">
                                                <Field
                                                    className="mt-4 form-control font-13 py-2 text-white shadow-none border-0 fill"
                                                    type="date" id="date_of_birth" name="date_of_birth"
                                                    max={new Date().toISOString().split('T')[0]}
                                                    placeholder="Date of Birth"/>
                                                <label className="floating-label font-13 text-white">Date
                                                    Of Birth</label>
                                                <ErrorMessage name="date_of_birth" component="span"
                                                              className="invalid-feedback">
                                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </div>
                                            <p className="text-muted mt-4 fw-bold">
                                                <small>SELECT AVATAR</small>
                                            </p>
                                            {renderMenuItems()}
                                            <div className="row w-100 mt-4">
                                                <div className="col-md-12">
                                                    {profileError &&
                                                    <p className="text-center text-wrap text-danger mt-3 mb-0">{profileError}</p>}
                                                </div>
                                            </div>
                                                <div className="col-md-12 my-1 d-flex">
                                                    <button type="button" className="no-btn-small"  onClick={goBackProfile}>
                                                        <img src={groupImg} alt="go back"/>
                                                    </button>
                                                    <button type="submit" className="login-btn ms-3 btn-hover color-1">
                                                        {loading ?
                                                            <div
                                                                className="spinner-border spinner-border-sm text-warning"
                                                                role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div> : 'Next'}
                                                    </button>
                                                </div>
                                        </Form>
                                    </div>
                                    <div className="modal-footer border-0 mx-0">
                                        <div className="row w-100" data-bs-target="#loginModel" data-bs-toggle="modal">
                                            <div className="col-md-10 d-flex align-items-center">
                                                <img src={svgLoginImg} className="pe-3 w-auto" alt="Register"/>
                                                <div className="account">
                                                    <p className="text-white mb-0">Don't have an account?</p>
                                                    <h6>Login</h6>
                                                </div>
                                            </div>

                                            <div className="col-md-2 my-3 d-flex justify-content-end">
                                                <Link to="#"><img src={svgRegisterImg} alt="register-right-arrow"
                                                                  className="w-auto"/></Link>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            )}
                        </Formik>
                <button type="button" className="btn-close rounded-circle opacity-100" style={{bottom:"-80px"}} data-bs-dismiss="modal" onClick={handleClose}></button>
                    </div>
                </div>
            </div>
        </>
    );
}
{/*<div className="modal-footer border-0 mx-0">*/}
                                    {/*    <div className="row w-100" data-bs-target="#loginModel"*/}
                                    {/*         data-bs-toggle="modal">*/}
                                    {/*        <div className="col-md-10 d-flex align-items-center">*/}
                                    {/*            <img src={loginGreyImg}*/}
                                    {/*                 className="pe-3 w-auto" alt="Register"/>*/}
                                    {/*            <div className="account">*/}
                                    {/*                <p className="text-white mb-0">Already have an*/}
                                    {/*                    account?</p>*/}
                                    {/*                <h6>Login</h6>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <div className="col-md-2 my-2 d-flex justify-content-end">*/}
                                    {/*            <Link to="#"><img*/}
                                    {/*                src={registerImg}*/}
                                    {/*                alt="register-right-arrow" className="w-auto"/></Link>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}