import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';


// create slice

const name = 'friendRequest';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const friendRequestActions = { ...slice.actions, ...extraActions };
export const friendRequestsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        friendRequests: [],
        acceptRequest: null,
        declineRequest: null,
        error: null,
        loading: false
    }
}

function createReducers() {
    return {
        resetFriendRequest
    };

    function resetFriendRequest(state) {
        state.friendRequests = [];
        state.acceptRequest = null;
        state.declineRequest = null;
        state.error = null;
        state.loading = false;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/friends`;

    return {
        getFriendRequestsList: getFriendRequestsList(),
        postAcceptFriendRequest: postAcceptFriendRequest(),
        postDeclineFriendRequest: postDeclineFriendRequest()
    };

    function getFriendRequestsList() {
        return createAsyncThunk(
            `${name}/getFriendRequestsList`,
            async (data) => await fetchWrapper.post(`${baseUrl}/getFriendRequestList`, {profile: data.values.profile, appsId: data.values.appsId})
        );
    }

    function postAcceptFriendRequest() {
        return createAsyncThunk(
            `${name}/acceptFriendRequest`,
            async (data) => await fetchWrapper.post(`${baseUrl}/acceptFriendRequest`, {profile: data.values.profile, friend_id: data.values.friend_id, appsId: data.values.appsId})
        )
    }

    function postDeclineFriendRequest() {
        return createAsyncThunk(
            `${name}/declineFriendRequest`,
            async (data) => await fetchWrapper.post(`${baseUrl}/declineFriendRequest`, {profile: data.values.profile, friend_id: data.values.friend_id, appsId: data.values.appsId})
        )
    }

}

function createExtraReducers() {
    return {
        ...getFriendRequestsList(),
        ...postAcceptFriendRequest(),
        ...postDeclineFriendRequest()
    };

    function getFriendRequestsList() {
        var { pending, fulfilled, rejected } = extraActions.getFriendRequestsList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.friendRequests = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function postAcceptFriendRequest() {
        var { pending, fulfilled, rejected } = extraActions.postAcceptFriendRequest;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.acceptRequest = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

    function postDeclineFriendRequest() {
        var { pending, fulfilled, rejected } = extraActions.postDeclineFriendRequest;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.declineRequest = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

}
