import React from 'react';
import ReactDOM from 'react-dom';
import {createRoot} from "react-dom/client";
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';
import './index.css';
import App from './App';
import Errors from "./pages/errors";
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root'));
root.render(
      <BrowserRouter>
          <Routes>
                  <Route path='error/*' element={<Errors />} />
                  <Route path='/*' element={<App />} />
          </Routes>
      </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
