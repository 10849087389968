import React, {useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import {shopActions} from "../../_store";

import virtualCoin from "../../components/assets/images/icon/virtua-coin.svg";
import shop1 from "../../components/assets/images/icon/shop1.png";
import shop2 from "../../components/assets/images/icon/shop2.png";
import shop3 from "../../components/assets/images/icon/shop3.png";
import shop4 from "../../components/assets/images/icon/shop4.png";
import shopSingle from "../../components/assets/images/icon/shop-single.png";

function Shop() {

    // Redux Methods
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const packages = useSelector(x => x.shop.packages);

    useEffect( () => {
        if (authUser) {
            const values = {
                user_id: authUser?.user_id
            }
            dispatch(shopActions.getShopList({values}));
            dispatch(shopActions.getPackageList());
        }
    }, [])
    return (
        <>
            <div className="row mx-2 mt-4">
                <h4 className="text-white page-title">SHOP</h4>
                <p className="text-white px-2 font-16">Buy VirtualCoin</p>
            </div>
            <div className="row px-3 shop">
                <div className="col-md-8">
                    <div className="row gx-5 px-lg-5 px-0 mb-2">
                        {_.take(packages, 4).map((item,index)=> (
                            <div className={`col-lg-6 col-md-12 ${index > 1 ? "mt-5" : ""}`} key={index}>
                                <div className="card">
                                    <div className="card-body text-center py-4">
                                        {(item.discount !== 0) && (
                                            <button
                                                className="btn font-16 fw-bold border-0 py-2 card-btn"
                                                style={{
                                                    position: 'absolute',
                                                    backgroundColor: "#64DB42",
                                                    top: "-10%",
                                                    right: "38%",
                                                    border: "2px solid black"
                                                }}
                                            >
                                                {item.discount}% off
                                            </button>
                                        )}
                                        <div className="row">
                                            <h5 className="card-title text-white fw-bold font-22"><img
                                                src={virtualCoin} style={{width: "35px"}}/>{item.Vcoin.toLocaleString()}</h5>
                                        </div>
                                        <div className="row">
                                            <img src={shop2} className="img-fluid mx-auto mt-3"
                                                 style={{width: "100px"}}/>
                                        </div>
                                        <div className="row px-4 mt-3">
                                        <button
                                            className="btn font-16 btn-hover color-1 fw-bold border-0 py-2"
                                            style={{backgroundColor: "#42dbcb"}}
                                        > ${item.amount}
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="row pe-0 pe-md-5">
                        {_.last(packages) && (
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body text-center py-4">
                                        {(_.last(packages).discount !== 0) && (
                                            <button
                                                className="btn font-16 fw-bold border-0 py-2 card-btn"
                                                style={{
                                                    position: 'absolute',
                                                    backgroundColor: "#64DB42",
                                                    top: "-4%",
                                                    right: "38%",
                                                    border: "2px solid black"
                                                }}
                                            >
                                                {_.last(packages).discount}% off
                                            </button>
                                        )}
                                        <div className="row">
                                            <h5 className="card-title text-white fw-bold font-22"><img
                                                src={virtualCoin} style={{width: "35px"}}/>{_.last(packages).Vcoin.toLocaleString()}
                                            </h5>
                                        </div>
                                        <div className="row mt-5">
                                            <img src={shopSingle} className="img-fluid mx-auto my-5"
                                                 style={{width: "253px", height: "222px"}}/>
                                        </div>
                                        <div className="row px-4 mt-5">
                                            <button
                                                className="btn font-16 fw-bold btn-hover color-1 border-0 py-2"
                                                style={{backgroundColor: "#42dbcb"}}
                                            >
                                                ${_.last(packages).amount}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}


export default Shop;
