import React, {useEffect, useState} from 'react';
import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import "../../components/assets/css/leaderboardmodal.css"
import LeaderboardTab from "./leaderborad_tab/leaderboardTab";
import {useDispatch, useSelector} from "react-redux";
import {weeklyRewardActions} from "../../_store";

const LeaderboardModal = (props) => {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const [activeTab, setActiveTab] = useState('leaderboard-tab');
    const [subActiveTab, setSubActiveTab] = useState('top-players');

    useEffect(() => {
        dispatch(weeklyRewardActions.getWeeklyRewardsList(authUser?.user_id))
    }, [dispatch]);

    const handleClose = () => props.toggle();

    return (
        <div>
            <Modal
                className="text-white leaderboard_modal"
                size="xl"
                show={props.isOpen}
                onHide={handleClose}
                centered={true}
            >
                <ModalHeader closeButton>
                    <ul className="nav nav-tabs leaderboard leaderboard_header_tabs border-0 justify-content-end" id="myTab" role="tablist">
                        <li className="nav-item me-2" role="presentation">
                            <button
                                className={"nav-link border-0  text-white " + (activeTab === 'leaderboard-tab' ? 'active' : '')}
                                onClick={() => {
                                    setActiveTab('leaderboard-tab');
                                }}
                                id="friend-list-tab1" data-bs-toggle="tab" data-bs-target="#leaderboard-tab"
                                type="button" role="tab" aria-controls="leaderboard-tab"
                                aria-selected="true"
                            >
                                LEADERBOARD
                            </button>
                        </li>
                        <li className="nav-item me-2" role="presentation">
                            <button
                                className={"nav-link border-0 text-white " + (activeTab === 'missions-tab' ? 'active' : '')}
                                onClick={() => {
                                    setActiveTab('missions-tab');
                                }}
                                id="request-pending1" data-bs-toggle="tab"
                                data-bs-target="#request-pending" type="button" role="tab"
                                aria-controls="request-pending" aria-selected="false"
                            >
                                MISSIONS
                            </button>
                        </li>
                        <li className="nav-item me-2 d-flex align-items-center cursor-pointer" role="presentation">
                            <img src="/assets/images/icon/icon-info-circle.svg"/>
                        </li>
                    </ul>
                    <div className="d-flex w-100 justify-content-between">
                        <div>
                            <div className="leaderboard_title">LEADERBOARD AWARDS</div>
                            <p className="text-white" style={{fontSize: "13px"}}>Season Ends in: 10 Days</p>
                        </div>
                        <div>
                            <ul className="nav nav-tabs leaderboard border-0 justify-content-end" id="myTab"
                                role="tablist">
                                <li className="nav-item me-2" role="presentation">
                                    <button
                                        className={"nav-link border-0 bg-none border-left-radius text-white " + (subActiveTab === 'top-players' ? 'active' : '')}
                                        onClick={() => {
                                            setSubActiveTab('top-players');
                                        }}
                                        id="friend-list-tab1" data-bs-toggle="tab" data-bs-target="#friend-list-tab"
                                        type="button" role="tab" aria-controls="friend-list-tab"
                                        aria-selected="true">Top Players
                                    </button>
                                </li>
                                <li className="nav-item me-2" role="presentation">
                                    <button
                                        className={"nav-link border-0 bg-none text-white " + (subActiveTab === 'friends-rank' ? 'active' : '')}
                                        onClick={() => {
                                            setSubActiveTab('friends-rank');
                                        }}
                                        id="request-pending1" data-bs-toggle="tab"
                                        data-bs-target="#request-pending" type="button" role="tab"
                                        aria-controls="request-pending" aria-selected="false">FRIENDS RANK
                                    </button>
                                </li>
                                <li className="nav-item me-2" role="presentation">
                                    <button
                                        className={"nav-link border-0 bg-none border-right-radius text-white " + (subActiveTab === 'clubmates-rank' ? 'active' : '')}
                                        onClick={() => {
                                            setSubActiveTab('clubmates-rank');
                                        }}
                                        id="block-list1"
                                        data-bs-toggle="tab" data-bs-target="#block-list-tab" type="button" role="tab"
                                        aria-controls="block-list-tab" aria-selected="false">CLUBMATES RANK
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {activeTab === 'leaderboard-tab' && (
                        <LeaderboardTab/>
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
};

export default LeaderboardModal;