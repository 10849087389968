import React, {useEffect, useRef, useState} from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import settingIcon from "../../assets/images/icon/Icon ionic-md-settings.svg";
import * as Yup from "yup";
import {Modal} from "react-bootstrap";
import login1Img from "../../assets/images/icon/login1.svg";

export default function SettingsModal({isOpen, handleClose}) {
    function _saveSettings() {
        handleClose()
    }

    return (
        <>
            <Modal
                show={isOpen}
                onHide={handleClose}
                size="md"
                centered
            >
                <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                    <div className="col-md-12 d-flex">
                        <img src={settingIcon} alt="setting icon" style={{width: "30px"}}/>
                        <h4 className="modal-title text-white ms-2">Settings</h4>
                    </div>
                </Modal.Header>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        notification: true,
                        friendRequest: false,
                        music: 40,
                        sound: 40
                    }}
                    onSubmit={(values) => {
                        _saveSettings(values)
                    }}
                >
                    {({values, errors, touched, handleReset, handleSubmit }) => (
                        <Form>
                            <>
                                <div className="modal-body mx-0">
                                    <div className="switch_border_buttom pt-0">
                                        <div className="row align-items-center">
                                            <div className="col-md-8">
                                                <div className="notification">
                                                    <p className="text-white mb-0">Notification</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 text-end">
                                                <label className="switch">
                                                    <Field type="checkbox" name="notification"/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="switch_border_buttom">
                                        <div className="row align-items-center">
                                            <div className="col-md-6">
                                                <div className="notification">
                                                    <p className="text-white mb-0">Music</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 text-end">
                                                <div className="volume">
                                                    <div className="input-wrapper">
                                                        <Field type="range" name="music" value={values['music']} min="0"
                                                               max="100" step="1"
                                                               className="music_progress"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="switch_border_buttom">
                                        <div className="row align-items-center">
                                            <div className="col-md-6">
                                                <div className="notification">
                                                    <p className="text-white mb-0">Sound</p>
                                                </div>
                                            </div>
                                            <div className="col-md-6 text-end">
                                                <div className="sound">
                                                    <div className="input-wrapper">
                                                        <Field type="range" name="sound" value={values['sound']} min="0"
                                                               max="100" step="1"
                                                               className="sound_progress"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="switch_border_buttom border-0 pb-0">
                                        <div className="row align-items-center">
                                            <div className="col-md-8">
                                                <div className="notification">
                                                    <p className="text-white mb-0">Allow people to send friend request
                                                        in this game</p>
                                                </div>
                                            </div>
                                            <div className="col-md-4 text-end">
                                                <label className="switch">
                                                    <Field type="checkbox" name="friendRequest"/>
                                                    <span className="slider round"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer border-0 mx-0">
                                    <div className="row w-100">
                                        <div className="col-md-6 my-1">
                                            <button type="submit" className="login-btn btn-hover color-1">Done</button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    );
}
