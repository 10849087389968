import React, {useState, useEffect, useRef} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import RegOtpModal, {RESEND_OTP_URL} from "./RegOtpModal";
import svgPersonImg from "../../assets/images/icon/Icon ionic-ios-person-add.svg"
import svgLoginImg from "../../assets/images/icon/login-grey.svg"
import backIcon from "../../assets/images/back_icon.png"
import svgRegisterImg from "../../assets/images/icon/register-right-arrow.svg"
import {ErrorMessage, Form, Formik, Field} from "formik";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";
import login1Img from "../../assets/images/icon/login1.svg";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../../_store";

const API_URL = process.env.REACT_APP_API_URL;
export const SIGNUP_URL = `${API_URL}/user/signup`;

export default function RegModal({isOpen, handleClose, handleBack,backLogin}) {

    const avatarLists = useSelector(x => x.auth.avatarLists);
    const formRef = useRef();
    const dispatch = useDispatch();
    const [passwordType, setPasswordType] = useState('password')
    const [confirmPasswordType, setConfirmPasswordType] = useState('password')
    const [registerOtpModal, setRegisterOtpModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [responseError, setResponseError] = useState("");
    const [currentScreen, setCurrentScreen] = useState(1);
    const [selectedAvatar, setSelectedAvatar] = useState(null);

    useEffect(()=>{
        if (isOpen){
            dispatch(authActions.getUserAvatars())
        }
    },[isOpen])


    const closeRegisterOtpModal = () => {
        document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
        setRegisterOtpModal(false);
    }
    const backRegisterModal = () => {
        //document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
        setRegisterOtpModal(false);
        return handleBack(true)
    }


    const handleAvatarClick = (index) => {
        setSelectedAvatar(index);
    };

    const sendEmail = (items) => {
        axios.post(RESEND_OTP_URL, {email: items.email}).then((res) => {
            if (!res.data.error) {
            } else {
                setIsValid(res.data.message);
            }
        })
    }

    function _handleSubmit(values) {
        if (currentScreen === 1) {
            setCurrentScreen(2);
            setResponseError("");
        } else {
            setLoading(true);
            axios.post(SIGNUP_URL, {
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                password: values.password,
                confirm_password: values.confirm_password,
                profile_image_id: selectedAvatar,
            }).then(response => {
                if (!response.data.error) {
                    localStorage.setItem('password', values.password);
                    if (response.data && response.data.data) {
                        localStorage.setItem('register', JSON.stringify(response.data.data));
                    }
                    setRegisterOtpModal(true);
                    setLoading(false);
                    setResponseError("");
                    sendEmail(response.data.data);
                    formRef?.current?.resetForm();
                    toast.success(response.data.message);
                    return handleClose(false);
                } else {
                    setResponseError(response.data.message);
                    setLoading(false);
                    toast.error(response.data.message);
                }
            })
        }
    }

    return (
        <>
            <Modal
                show={isOpen}
                onHide={handleClose}
                size="md"
                centered
            >
                <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                    <div className="col-md-6 d-flex">
                        <img src={svgPersonImg} alt=""/>
                        <h4 className="modal-title text-white ms-2">Register</h4>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <img
                            className="virtualogin w-auto"
                            src={require('../../assets/images/virtualogin.png')}
                            alt="virtualogin"
                        />
                    </div>
                </Modal.Header>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        first_name: '',
                        last_name: '',
                        email: '',
                        password: '',
                        confirm_password: ''
                    }}
                    innerRef={formRef}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email("Invalid Email address").required("Email is required!"),
                        first_name: Yup.string().required("First Name is required"),
                        last_name: Yup.string().required("Last Name is required"),
                        password: Yup.string().required("Password is required"),
                        confirm_password:
                            Yup.string().required("Confirm password is required")
                                .when('password', {
                                    is: password => password && password.length > 0,
                                    then: Yup.string()
                                        .oneOf([Yup.ref('password'), null], 'Passwords must match')
                                        .required("Confirm password is required")
                                })
                    })}
                    onSubmit={(values) => {
                        _handleSubmit(values)
                    }}
                >
                        <>
                            <div className="modal-body row mx-0">
                                <Form>
                                    {responseError ? <p className="text-danger mb-3 d-block" role="alert">{responseError}</p> : ''}
                                    {currentScreen === 1 && (
                                        <>
                                            <div className="row mt-4">
                                                <div className="col">
                                                    <div className="material-textfield">
                                                        <Field
                                                            type="text"
                                                            name="first_name"
                                                            placeholder="First Name"
                                                        />
                                                        <label>First Name</label>
                                                    </div>
                                                    <ErrorMessage name="first_name" component="span" className="invalid-feedback">
                                                        {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                    </ErrorMessage>
                                                </div>
                                                <div className="col">
                                                    <div className="material-textfield">
                                                        <Field
                                                            type="text"
                                                            name="last_name"
                                                            placeholder="Last Name"
                                                        />
                                                        <label>Last Name</label>
                                                    </div>
                                                    <ErrorMessage name="last_name" component="span" className="invalid-feedback">
                                                        {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            <div className="col mt-4">
                                                <div className="material-textfield">
                                                    <Field
                                                        type="email" name="email"
                                                        placeholder="Email"
                                                    />
                                                    <label>Email</label>
                                                </div>
                                                <ErrorMessage name="email" component="span" className="invalid-feedback">
                                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </div>
                                            <div className="col mt-4">
                                                <div className="material-textfield">
                                                    <Field
                                                        type={passwordType}
                                                        name="password"
                                                        placeholder="Password"
                                                    />
                                                    <label>Password</label>
                                                    <i
                                                        className={"far toggle-password " + (passwordType === 'text' ? 'fa-eye' : 'fa-eye-slash')}
                                                        onClick={() => {
                                                            setPasswordType(passwordType === 'text' ? 'password' : 'text')
                                                        }}
                                                    />
                                                </div>
                                                <ErrorMessage name="password" component="span"
                                                              className="invalid-feedback">
                                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </div>
                                            <div className="col mt-4">
                                                <div className="material-textfield">
                                                    <Field
                                                        type={confirmPasswordType}
                                                        name="confirm_password"
                                                        placeholder="Confirm Password" autoComplete="off"
                                                    />
                                                    <label>Confirm Password</label>
                                                    <i
                                                        className={"far float-end toggle-password " + (confirmPasswordType === 'text' ? 'fa-eye' : 'fa-eye-slash')}
                                                        onClick={() => {
                                                            setConfirmPasswordType(confirmPasswordType === 'text' ? 'password' : 'text')
                                                        }}
                                                    />
                                                </div>
                                                <ErrorMessage name="confirm_password" component="span" className="invalid-feedback">
                                                    {msg => <div style={{color: 'red'}}>{msg}</div>}
                                                </ErrorMessage>
                                            </div>
                                            <div className="col-md-6 my-4">
                                                <button type="submit" className="login-btn btn-hover color-1">
                                                    {loading ? (
                                                        <div className="spinner-border spinner-border-sm text-warning" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    ) : 'Register'}
                                                </button>
                                            </div>
                                        </>
                                    )}
                                    {currentScreen === 2 && (
                                        <div className="row g-3">
                                            <div className="col">
                                                <div className="material-textfield">
                                                    <Field
                                                        type="text"
                                                        name="nick_name"
                                                        placeholder="Nickname"
                                                    />
                                                    <label>Nick Name</label>
                                                </div>
                                                <ErrorMessage name="nick_name" component="span" className="invalid-feedback">
                                                    {msg => <p className="text-danger">{msg}</p>}
                                                </ErrorMessage>
                                            </div>
                                            <div className="col">
                                                <div className="material-textfield">
                                                    <Field
                                                        type="date"
                                                        name="birth_date" placeholder="DOB"
                                                    />
                                                    <label>DOB</label>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    {avatarLists.map(({image, id}) => (
                                                        <div
                                                            key={id}
                                                            className={`col-md-2 mt-3 register_avatar ${selectedAvatar === id ? 'selected' : ''}`}
                                                            onClick={() => handleAvatarClick(id)}
                                                        >
                                                            {selectedAvatar === id && <span className="checkmark">✔</span>}
                                                            <img
                                                                src={image}
                                                                className="register_image"
                                                                alt={`avatar_${id + 1}`}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="col-md-6 my-4 gap-2 d-flex">
                                                {currentScreen === 2 && (
                                                    <div className="register_avatar cursor-pointer">
                                                        <img
                                                            src={backIcon}
                                                            alt="Back Button"
                                                            className="register_image"
                                                            onClick={() => setCurrentScreen(1)}
                                                        />
                                                    </div>
                                                )}
                                                <button type="submit" className="login-btn btn-hover color-1">
                                                    {loading ?
                                                        <div className="spinner-border spinner-border-sm text-warning"
                                                             role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                        : currentScreen === 2 ? 'Next' : 'Register'}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </Form>
                            </div>
                            <div className="modal-footer border-0 mx-0">
                                <div
                                    className="row w-100"
                                    onClick={()=>{
                                        handleClose();
                                        backLogin();
                                    }}
                                >
                                    <div className="col-md-10 d-flex align-items-center">
                                        <img src={svgLoginImg} className="pe-3 w-auto" alt="Register"/>
                                        <div className="account">
                                            <p className="text-white mb-0">Don't have an account?</p>
                                            <h6>Login</h6>
                                        </div>
                                    </div>

                                    <div className="col-md-2 my-3 d-flex justify-content-end">
                                        <Link to="#">
                                            <img src={svgRegisterImg} alt="register-right-arrow" className="w-auto"/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </>
                </Formik>
            </Modal>
            <RegOtpModal
                isOpen={registerOtpModal}
                handleClose={closeRegisterOtpModal}
                handleBack={backRegisterModal}
                modelData={{title: "Register"}}
            />

        </>
    )
}
