import React from 'react';
import chessIcon from "../../../components/assets/images/chess.png";
import starIcon from "../../../components/assets/images/icon/star.svg";
import playerName from "../../../components/assets/images/icon/player-name.svg";
import flagIcon from "../../../components/assets/images/icon/flag.svg";
import playerName1 from "../../../components/assets/images/icon/player-name1.svg";
import flagIcon1 from "../../../components/assets/images/icon/flag1.svg";
import playerName2 from "../../../components/assets/images/icon/player-name2.svg";
import flagIcon2 from "../../../components/assets/images/icon/flag2.svg";
import infoCircleIcon from "../../../components/assets/images/icon/icon-info-circle.svg";
import greenUpArrowIcon from "../../../components/assets/images/icon/green-up-arrow.svg";
import featherUserIcon from "../../../components/assets/images/icon/Icon-feather-users.svg";
import dotIcon from "../../../components/assets/images/icon/dot.svg";
import playerName4 from "../../../components/assets/images/icon/player-name4.svg";
import sendIcon from "../../../components/assets/images/icon/icon-send.svg";
import virtuaCoinIcon from "../../../components/assets/images/icon/virtua-coin.svg";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const GameInfoTab = () => {
    const applicationInfo = useSelector( (x) => x.application.applicationInfo);

    return (
        <div className="row px-3">
            <div className="col-lg-8 col-md-8">
                <div className="row">
                    <div className="col-lg-2 col-md-2">
                        {_.get(applicationInfo,"app_icon") && (
                            <img
                                src={"https://virtuagrid.com/" + _.get(applicationInfo,"app_icon")}
                                alt="applicationInfo"
                                className="img-fluid"
                                width="127"
                            />
                        )}
                    </div>
                    <div className="col-lg-10 col-md-10 ps-4 pt-1">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <h4 className="font-oval-single font-34 text-white">
                                    {_.get(applicationInfo,"app_name","")}
                                </h4>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 px-0">
                                        <p className="text-white font-13 text-end mb-0">
                                            {_.get(applicationInfo,"rating","")}
                                            {" "}
                                            <img
                                                src={starIcon}
                                                alt="starIcon"
                                                className="ms-1"
                                                style={{width: "12px"}}
                                            />
                                        </p>
                                        <p className="text-muted font-13 text-end">
                                            452 Reviews
                                        </p>
                                    </div>
                                    <div className="col-lg-5 col-md-5 text-end ps-0">
                                        <Link to="/game"
                                              className="btn border-0 btn-hover color-1 py-2 px-3 font-13 fw-bold"
                                              style={{backgroundColor: "#42dbcb"}}
                                        >
                                            Play
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <p className="font-13 text-white">
                                {_.get(applicationInfo,"description","")}
                                {/*<span className="yellow-heading">Read More</span>*/}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-lg-6 col-md-6 background-light-grey rounded-2">
                        <div className="row px-4 pt-3">
                            <p className="font-22 font-oval-single color-green p-0 mb-0">
                                Current Toppers
                            </p>
                        </div>
                        <div className="row px-4 pt-2">
                            <table className="table text-white font-13 border-secondary">
                                <tbody>
                                <tr>
                                    <td className="fw-bold">1.</td>
                                    <td>
                                        <img src={playerName} style={{width: "25px"}}/>
                                    </td>
                                    <td className="fw-bold">John</td>
                                    <td>
                                        <img src={flagIcon} style={{width: "25px"}}/>
                                    </td>
                                    <td>3515 pts.</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">2.</td>
                                    <td>
                                        <img
                                            src={playerName1}
                                            style={{width: "25px"}}
                                        />
                                    </td>
                                    <td className="fw-bold">Bruce</td>
                                    <td>
                                        <img src={flagIcon1} style={{width: "25px"}}/>
                                    </td>
                                    <td>3145 pts.</td>
                                </tr>
                                <tr>
                                    <td className="fw-bold">3.</td>
                                    <td>
                                        <img
                                            src={playerName2}
                                            style={{width: "25px"}}
                                        />
                                    </td>
                                    <td className="fw-bold">Adam</td>
                                    <td>
                                        <img src={flagIcon2} style={{width: "25px"}}/>
                                    </td>
                                    <td>2954 pts.</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 px-4">
                        <div className="row">
                            <div className="col-lg-8 col-md-8">
                                <p className="font-16 yellow-heading">
                                    Weekly Reward
                                    <span className="font-oswald ms-2 fw-bold text-white font-16">
                            <img
                                src={virtuaCoinIcon}
                                style={{width: "25px"}}
                            />{" "}
                                        3,515
                          </span>
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-4 text-end">
                        <span>
                          <img src={infoCircleIcon} style={{width: "20px"}}/>
                        </span>
                            </div>
                        </div>
                        <div className="row mt-2 px-3">
                            <div className="col-lg-12 col-md-12 border border-secondary rounded-3 py-2">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 border-secondary border-end text-center">
                                        <img src={playerName} style={{width: "100px"}}/>
                                        <p className="font-13 mb-0 text-white fw-bold mt-2">
                                            Williams
                                        </p>
                                    </div>
                                    <div className="col-lg-6 col-md-6 text-center">
                                        <p className="font-oval-single mb-0 font-60 text-white">
                                            21<sup>st</sup>
                                        </p>
                                        <p className="font-20 text-white">Position</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12 ps-1 pe-4 game-tabs">
                        <ul className="nav nav-tabs" id="game-tab" role="tablist">
                            <li>
                                <p className="font-22 font-oval-single color-green p-0 mb-0">
                                    Current Toppers
                                </p>
                            </li>
                            <li
                                className="nav-item ms-auto"
                                role="presentation"
                            >
                                <button
                                    className="nav-link active"
                                    id="quick-match-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#quick-match"
                                    type="button"
                                    role="tab"
                                    aria-controls="quick-match"
                                    aria-selected="false"
                                >
                                    Quick Match
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="tournament-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tournament"
                                    type="button"
                                    role="tab"
                                    aria-controls="tournament"
                                    aria-selected="false"
                                >
                                    Tournament
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContentGames">
                            <div
                                className="tab-pane fade show active px-2"
                                id="quick-match"
                                role="tabpanel"
                                aria-labelledby="quick-match-tab"
                            >
                                <div className="row mt-4">
                                    <div className="col-lg-3 col-md-3">
                                        <div className="card rounded-4 border-secondary">
                                            <div className="card-body text-center py-4">
                                                <button
                                                    className="btn font-13 fw-bold border-0 py-1 card-btn"
                                                    style={{
                                                        position: "absolute",
                                                        backgroundColor: "#FCD32C",
                                                        top: "-7%",
                                                        right: "30%",
                                                    }}
                                                >
                                                    <img
                                                        src={virtuaCoinIcon}
                                                        style={{width: "25px"}}
                                                    />
                                                    0{" "}
                                                    <span className="text-dark font-8">
                                    (FREE)
                                  </span>
                                                </button>
                                                <div className="row border-bottom border-secondary">
                                                    <p className="text-white font-oval-single font-60 mb-0">
                                                        30
                                                    </p>
                                                    <p className="fw-bold text-white font-16 mb-2">
                                                        MIN
                                                    </p>
                                                </div>
                                                <div className="row pt-3 pb-1">
                                                    <p className="text-white font-13 fw-bold mb-0">
                                                        NO PRIZE
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className="pt-2 pb-1 mx-auto popular-button"
                                            style={{display: "none"}}
                                        >
                                            POPULAR
                                        </button>
                                        <p className="text-muted text-center mt-4 font-13 fw-bold">
                                            Play Free
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="card rounded-4 border-secondary">
                                            <div className="card-body text-center py-4">
                                                <button
                                                    className="btn font-13 fw-bold border-0 py-1 card-btn"
                                                    style={{
                                                        position: "absolute",
                                                        backgroundColor: "#FCD32C",
                                                        top: "-7%",
                                                        right: "36%",
                                                    }}
                                                >
                                                    <img
                                                        src={virtuaCoinIcon}
                                                        style={{width: "25px"}}
                                                    />
                                                    20
                                                </button>
                                                <div className="row border-bottom border-secondary">
                                                    <p className="text-white font-oval-single font-60 mb-0">
                                                        30
                                                    </p>
                                                    <p className="fw-bold text-white font-16 mb-2">
                                                        MIN
                                                    </p>
                                                </div>
                                                <div className="row pt-3 pb-0">
                                                    <p className="text-white font-13 fw-bold mb-0">
                                                        PRIZE{" "}
                                                        <span className="yellow-heading">
                                      <img
                                          src={virtuaCoinIcon}
                                          style={{width: "25px"}}
                                      />{" "}
                                                            17{" "}
                                                            <img
                                                                src={greenUpArrowIcon}
                                                                className="ms-2"
                                                                style={{
                                                                    width: "15px",
                                                                    marginTop: "-5px",
                                                                }}
                                                            />
                                    </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className="pt-2 pb-1 mx-auto popular-button"
                                            style={{display: "none"}}
                                        >
                                            POPULAR
                                        </button>
                                        <p className="text-muted text-center mt-4 font-13 fw-bold">
                                            Play{" "}
                                            <span className="yellow-heading">
                                <img
                                    src={virtuaCoinIcon}
                                    style={{width: "20px"}}
                                />{" "}
                                                10
                              </span>
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="card rounded-4 border-secondary">
                                            <div className="card-body text-center py-4">
                                                <button
                                                    className="btn font-13 fw-bold border-0 py-1 card-btn"
                                                    style={{
                                                        position: "absolute",
                                                        backgroundColor: "#FCD32C",
                                                        top: "-7%",
                                                        right: "36%",
                                                    }}
                                                >
                                                    <img
                                                        src={virtuaCoinIcon}
                                                        style={{width: "25px"}}
                                                    />
                                                    10
                                                </button>
                                                <div className="row border-bottom border-secondary">
                                                    <p className="text-white font-oval-single font-60 mb-0">
                                                        15
                                                    </p>
                                                    <p className="fw-bold text-white font-16 mb-2">
                                                        MIN
                                                    </p>
                                                </div>
                                                <div className="row pt-3 pb-0">
                                                    <p className="text-white font-13 fw-bold mb-0">
                                                        PRIZE{" "}
                                                        <span className="yellow-heading">
                                      <img
                                          src={virtuaCoinIcon}
                                          style={{width: "25px"}}
                                      />{" "}
                                                            17{" "}
                                                            <img
                                                                src={greenUpArrowIcon}
                                                                className="ms-2"
                                                                style={{
                                                                    width: "15px",
                                                                    marginTop: "-5px",
                                                                }}
                                                            />
                                    </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className="pt-2 pb-1 mx-auto popular-button"
                                            style={{display: "none"}}
                                        >
                                            POPULAR
                                        </button>
                                        <p className="text-muted text-center mt-4 font-13 fw-bold">
                                            Play{" "}
                                            <span className="yellow-heading">
                                <img
                                    src={virtuaCoinIcon}
                                    style={{width: "20px"}}
                                />{" "}
                                                10
                              </span>
                                        </p>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="card rounded-4 border-secondary">
                                            <div className="card-body text-center py-4">
                                                <button
                                                    className="btn font-13 fw-bold border-0 py-1 card-btn"
                                                    style={{
                                                        position: "absolute",
                                                        backgroundColor: "#FCD32C",
                                                        top: "-7%",
                                                        right: "37%",
                                                    }}
                                                >
                                                    <img
                                                        src={virtuaCoinIcon}
                                                        style={{width: "25px"}}
                                                    />
                                                    5
                                                </button>
                                                <div className="row border-bottom border-secondary">
                                                    <p className="text-white font-oval-single font-60 mb-0">
                                                        5
                                                    </p>
                                                    <p className="fw-bold text-white font-16 mb-2">
                                                        MIN
                                                    </p>
                                                </div>
                                                <div className="row pt-3 pb-0">
                                                    <p className="text-white font-13 fw-bold mb-0">
                                                        PRIZE{" "}
                                                        <span className="yellow-heading">
                                      <img
                                          src={virtuaCoinIcon}
                                          style={{width: "25px"}}
                                      />{" "}
                                                            7{" "}
                                                            <img
                                                                src={greenUpArrowIcon}
                                                                className="ms-2"
                                                                style={{
                                                                    width: "15px",
                                                                    marginTop: "-5px",
                                                                }}
                                                            />
                                    </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className="pt-2 pb-1 mx-auto popular-button"
                                            style={{display: "none"}}
                                        >
                                            POPULAR
                                        </button>
                                        <p className="text-muted text-center mt-4 font-13 fw-bold">
                                            Play{" "}
                                            <span className="yellow-heading">
                                <img
                                    src={virtuaCoinIcon}
                                    style={{width: "20px"}}
                                />{" "}
                                                10
                              </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade px-2"
                                id="tournament"
                                role="tabpanel"
                                aria-labelledby="tournament-tab"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="col-lg-4 col-md-4 border mb-5 border-secondary rounded-4"
                style={{backgroundColor: "#161616"}}
            >
                <div className="row">
                    <div className="col-lg-12 col-md-12 border-bottom border-secondary px-4 py-3">
                        <div className="row">
                            <div className="col-lg-7 col-md-7">
                                <p className="font-22 font-oval-single color-green mb-0">
                                    Lobby
                                </p>
                            </div>
                            <div className="col-lg-5 col-md-5 text-end">
                                <p className="fw-bold font-13 mb-0 text-white pt-2">
                          <span>
                            <img
                                src={featherUserIcon}
                                style={{width: "15px"}}
                            />
                          </span>{" "}
                                    351
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 text-end">
                    <div className="col-lg-2 col-md-2 clearfix"></div>
                    <div className="col-lg-10 col-md-10 px-4">
                        <div className="row">
                            <p className="text-secondary font-10 mb-1 pe-0">
                                12:41 PM{" "}
                                <span className="text-muted">
                          <img src={dotIcon} style={{width: "5px"}}/> You
                        </span>
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 background-dark-green p-3 message-1">
                                <p className="text-white font-13">
                                    Neque porro quisquam est qui dolorem ipsum quia dolor
                                    sit ame
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-2 col-md-2 ps-4">
                        <img src={playerName} style={{width: "50px"}}/>
                    </div>
                    <div className="col-lg-10 col-md-10 px-4">
                        <div className="row">
                            <p className="text-secondary ps-0 font-10 mb-1">
                                Bruce Wayen{" "}
                                <span className="text-muted">
                          <img src={dotIcon} style={{width: "5px"}}/> 12:34
                          PM
                        </span>
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 background-dark-yellow p-3 message-2">
                                <p className="text-white font-13">
                                    Lorem ipsum dolor sit amet, consectetur{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-2 col-md-2 ps-4">
                        <img src={playerName2} style={{width: "50px"}}/>
                    </div>
                    <div className="col-lg-10 col-md-10 px-4">
                        <div className="row">
                            <p className="text-secondary ps-0 font-10 mb-1">
                                Adam D. Smith{" "}
                                <span className="text-muted">
                          <img src={dotIcon} style={{width: "5px"}}/> 12:34
                          PM
                        </span>
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 background-dark-yellow p-3 message-2">
                                <p className="text-white font-13">
                                    Lorem ipsum dolor sit amet, consectetur{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-2 col-md-2 ps-4">
                        <img src={playerName4} style={{width: "50px"}}/>
                    </div>
                    <div className="col-lg-10 col-md-10 px-4">
                        <div className="row">
                            <p className="text-secondary ps-0 font-10 mb-1">
                                Brayn Tyler{" "}
                                <span className="text-muted">
                          <img src={dotIcon} style={{width: "5px"}}/> 12:34
                          PM
                        </span>
                            </p>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 background-dark-yellow p-3 message-2">
                                <p className="text-white font-13">
                                    Lorem ipsum dolor sit amet, consectetur{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-12 col-md-12 border-top border-secondary px-4 pt-3">
                        <div className="row">
                            <div className="col-lg-7 col-md-7">
                                <p className="font-16 text-secondary font-roboto-bold mb-0">
                                    Type here...
                                </p>
                            </div>
                            <div className="col-lg-5 col-md-5 text-end">
                                <img src={sendIcon} style={{width: "25px"}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default GameInfoTab;