import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import AccountRecoveryModal from "./AccountRecoveryModal";
import svgBackImg from "../../assets/images/icon/Group 2344.svg";
import svgPersonImg from "../../assets/images/icon/Icon ionic-ios-person-add.svg";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import NewPasswordSetModal from "./NewPasswordSetModal";

const API_URL = process.env.REACT_APP_API_URL;
export const OTP_URL = `${API_URL}/user/otp/verify`;
export const RESEND_OTP_URL = `${API_URL}/user/otp`;

export default function RegOtpModal({
                                        isOpen,
                                        handleClose,
                                        handleBack,
                                        value,
                                        modelData
                                    }) {
    const [accountRecoveryModal, setAccountRecoveryModal] = useState(false);
    const [newPasswordModal, setNewPasswordModal] = useState(false);
    const [newPassvalue, setNewPassValue] = useState();
    const [forgotOtp, setForgotOtp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isValid, setIsValid] = useState("");
    const [email, setEmail] = useState("");
    // const [error, setError] = useState(false);
    // const [errMessage, setErrMessage] = useState('');
    const items = JSON.parse(localStorage.getItem("register"));
    const regOtpFormRef = useRef();
    const otpValue1Ref = useRef(null);
    const otpValue2Ref = useRef(null);
    const otpValue3Ref = useRef(null);
    const otpValue4Ref = useRef(null);

    useEffect(() => {
        if (otpValue1Ref.current) {
            otpValue1Ref.current.focus();
        }
    }, [otpValue1Ref]);
    useEffect(() => {
        if (items?.email) {
            setEmail(items?.email);
        } else {
            setEmail(value?.email);
        }
    }, [value, items]);

    const onChange = (event, valRef) => {

        if (/[0-9]/.test(event.target.value) && valRef) {
            valRef.current.focus();
        }
    };

    const resendEmail = () => {
        axios.post(RESEND_OTP_URL, {email: email}).then((res) => {
            if (!res.data.error) {
            } else {
                setIsValid(res.data.message);
            }
        });
    };
    const handleForgotModal = () => {
        setNewPasswordModal(false)
    }
    const closeAccRecoveryModal = (e) => {
        if (e) {
            setAccountRecoveryModal(false);
            return handleClose(true);
        } else {
            setAccountRecoveryModal(false);
            return handleClose(false);
        }
        // setAccountRecoveryModal(false);
    };
    const closeNewPasswordModal = (e) => {
        if (e) {
            setNewPasswordModal(false);
            return handleClose(true);
        } else {
            setNewPasswordModal(false);
            return handleClose(false);
        }
    };

    return (
        <>
            <div className={"modal fade " + (isOpen ? 'show' : 'hide')} style={{display: isOpen ? 'block' : 'none'}}
                 id="otpModal" role='dialog' tabIndex={1} aria-modal='true'
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <div className="modal-header row mx-0 border-0 shadow-none">
                            <div className="col-md-12 d-flex">
                                <img src={svgPersonImg} alt="" className="pe-2"/>
                                &nbsp;&nbsp;
                                <h4 className="modal-title text-white" id="otpModalLable">
                                    {modelData.title}
                                </h4>
                            </div>
                        </div>
                        <Formik
                            initialValues={{
                                otpValue1: "",
                                otpValue2: "",
                                otpValue3: "",
                                otpValue4: "",
                            }}
                            innerRef={regOtpFormRef}
                            enableReinitialize={true}
                            validationSchema={Yup.object().shape({
                                otpValue1: Yup.string().matches(
                                    /[0-9]/,
                                    "Please enter only digit"
                                ),
                                otpValue2: Yup.string().matches(
                                    /[0-9]/,
                                    "Please enter only digit"
                                ),
                                otpValue3: Yup.string().matches(
                                    /[0-9]/,
                                    "Please enter only digit"
                                ),
                                otpValue4: Yup.string().matches(
                                    /[0-9]/,
                                    "Please enter only digit"
                                ),
                            })}
                            onSubmit={async (values) => {
                                setLoading(true);
                                const authOtp = Number(
                                    `${values.otpValue1}${values.otpValue2}${values.otpValue3}${values.otpValue4}`
                                );
                                if (modelData.title === "Register") {
                                    const response = await axios.post(OTP_URL, {
                                        email: email,
                                        auth_otp: authOtp,
                                    });
                                    if (!response.data.error) {
                                        setAccountRecoveryModal(true);
                                        setLoading(false);
                                        regOtpFormRef?.current?.resetForm();
                                    } else {
                                        if (response.data.error === true) {
                                            setIsValid(response.data.message);
                                            setLoading(false);
                                        }
                                    }
                                } else {
                                    if (email && authOtp) {
                                        setLoading(false);
                                        setNewPassValue({email: value.email, auth_otp: authOtp});
                                        setNewPasswordModal(true);
                                    } else {
                                        setNewPasswordModal(false);
                                        setIsValid("Enter vaild OTP");
                                        setLoading(false);
                                    }
                                }
                            }}
                        >
                            {({values, errors, touched, handleReset, handleChange}) => (
                                <Form>
                                    <div className="modal-body row mx-0 py-4">
                                        {/*{error ? <p className="text-danger">{errMessage}</p> : ''}*/}
                                        <p className="text-white font-13 fw-bold">
                                            <small>Enter the code sent on your email</small>
                                        </p>
                                        {isValid ? <p className="text-danger">{isValid}</p> : ""}
                                        <div
                                            className="input-container d-flex flex-row justify-content-start mt-3 mb-3">
                                            <div className="col-2">
                                                <Field
                                                    type="text"
                                                    innerRef={otpValue1Ref}
                                                    className="text-center py-3 form-control rounded"
                                                    maxLength="1"
                                                    placeholder="-"
                                                    tabIndex={1}
                                                    name="otpValue1"
                                                    onInput={(event) => {
                                                        onChange(event, otpValue2Ref);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-2 ms-3">
                                                <Field
                                                    type="text"
                                                    innerRef={otpValue2Ref}
                                                    className="text-center py-3 form-control rounded"
                                                    maxLength="1"
                                                    placeholder="-"
                                                    tabIndex={2}
                                                    name="otpValue2"
                                                    onInput={(event) => {
                                                        onChange(event, otpValue3Ref);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-2 ms-3">
                                                <Field
                                                    type="text"
                                                    innerRef={otpValue3Ref}
                                                    className="text-center py-3 form-control rounded"
                                                    maxLength="1"
                                                    placeholder="-"
                                                    tabIndex={3}
                                                    name="otpValue3"
                                                    onInput={(event) => {
                                                        onChange(event, otpValue4Ref);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-2 ms-3">
                                                <Field
                                                    type="text"
                                                    innerRef={otpValue4Ref}
                                                    className="text-center py-3 form-control rounded"
                                                    maxLength="1"
                                                    placeholder="-"
                                                    tabIndex={4}
                                                    name="otpValue4"
                                                    onInput={(event) => {
                                                        onChange(event);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <ErrorMessage
                                            name="otpValue1"
                                            component="span"
                                            className="invalid-feedback"
                                        >
                                            {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                        </ErrorMessage>
                                        <ErrorMessage
                                            name="otpValue2"
                                            component="span"
                                            className="invalid-feedback"
                                        >
                                            {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                        </ErrorMessage>
                                        <ErrorMessage
                                            name="otpValue3"
                                            component="span"
                                            className="invalid-feedback"
                                        >
                                            {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                        </ErrorMessage>
                                        <ErrorMessage
                                            name="otpValue4"
                                            component="span"
                                            className="invalid-feedback"
                                        >
                                            {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                        </ErrorMessage>
                                        <p className="text-muted font-13">
                                            Didn't receive the code?{" "}
                                            <button
                                                type="button"
                                                className="color-light-blue fw-bold transparent-link p-0"
                                                onClick={resendEmail}
                                            >
                                                Resend Email
                                            </button>
                                        </p>
                                    </div>
                                    <div className="modal-footer border-0 mx-0">
                                        <div className="row w-100">
                                            <div className="col-md-12 my-1 d-flex">
                                                <button
                                                    type="button"
                                                    className="no-btn-small"
                                                    data-bs-target={`#${accountRecoveryModal ? 'registerModel' : 'forgotPassword'}`}
                                                    role="modal"
                                                    data-bs-toggle="modal"
                                                    onClick={() => {
                                                        handleBack(true)
                                                    }}
                                                >
                                                    <img src={svgBackImg} alt=""/>
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="login-btn ms-3 btn-hover color-1"
                                                >
                                                    {loading ? (
                                                        <div
                                                            className="spinner-border spinner-border-sm text-warning"
                                                            role="status"
                                                        >
                                                          <span className="visually-hidden">
                                                            Loading...
                                                          </span>
                                                        </div>
                                                    ) : (
                                                        "Verify"
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                        <button
                            type="button"
                            className="btn-close rounded-circle opacity-100"
                            data-bs-dismiss="modal"
                            onClick={() => {
                                regOtpFormRef?.current?.resetForm();
                                handleClose()
                            }}
                        ></button>
                    </div>
                </div>
            </div>
            {(accountRecoveryModal && modelData.title === "Register") && (
                <AccountRecoveryModal
                    isOpen={accountRecoveryModal}
                    handleClose={(e) => {
                        closeAccRecoveryModal(e)
                    }}
                />
            )}
            {(newPasswordModal && modelData.title !== "Register") && (
                <NewPasswordSetModal
                    isOpen={newPasswordModal}
                    handleClose={(e) => {
                        closeNewPasswordModal(e)
                    }}
                    handleBack={(e) => {
                        handleForgotModal(true)
                    }}
                    value={newPassvalue}
                    modelData={modelData}
                />
            )}
        </>
    );
}
