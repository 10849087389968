import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {questionActions} from '../../../_store';
import axios from "axios";
import {Formik, Field, Form, FieldArray,useFormik} from 'formik';
import * as Yup from "yup";
import SeedPhraseModal from "./SeedPhraseModal";
import svglayer from "../../assets/images/icon/layer1.svg"
import '../../assets/css/account-recovery.css'

const API_URL = process.env.REACT_APP_API_URL;
export const Recovery_Ans_URL = `${API_URL}/recovery_questions/set`;


export default function AccountRecoveryModal({isOpen, handleClose}) {
    const [seedPhraseModal, setSeedPhraseModal] = useState(false);
    const [seedPhrase, setSeedPhrase] = useState([]);
    const [isValid, setIsValid] = useState("");

    const dispatch = useDispatch();
    const questions = useSelector(x => x.questions.questions);


    useEffect(() => {
        if (questions.length === 0) {
            dispatch(questionActions.getAll());
        }
        if (isOpen) {
            const element = document.createElement("div");
            element.setAttribute("class", "modal-backdrop fade show");
            document.body.appendChild(element);
        }

    }, [dispatch, isOpen]);


    const initialValues = {
        questions
    };

    const validationSchema = Yup.object().shape({
        recovery_question_1: Yup.string().required('Question is required'),
        recovery_question_2: Yup.string().required('Question is required'),
        recovery_answer_1: Yup.string().required('Answer is required'),
        recovery_answer_2: Yup.string().required('Answer is required'),
    });

    const items = JSON.parse(localStorage.getItem('register'));

    const closeSeedPhraseModal = () => {
        setSeedPhraseModal(false);
        return handleClose(true)
    }
    return (
        <>
            <div
                className={"modal fade " + (isOpen ? 'show' : 'hide')}
                style={{display: isOpen ? 'block' : 'none'}}
                id="accountRecovery" role='dialog' tabIndex={3} aria-modal='true'
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header row mx-0 border-0 shadow-none">
                            <div className="col-md-12 d-flex">
                                <img src={svglayer} alt="" className="pe-2"/>&nbsp;&nbsp;
                                <h4 className="modal-title text-white">Account Recovery</h4>
                            </div>
                        </div>
                        {questions?.length > 0 ?
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={async (values) => {
                                    try {
                                        values.user_id = items.user_id;
                                        const response = await axios.post(Recovery_Ans_URL, values)
                                        if (!response.data.error) {
                                            setSeedPhraseModal(true);
                                            setSeedPhrase(response.data.data.seed_phrase);
                                        } else {
                                            setIsValid(response.data.data.message);
                                        }
                                    } catch (error) {
                                        console.error(error);
                                    }
                                }}
                            >
                                {({values, errors, touched,isSubmitting}) => (
                                    <Form>
                                        <div className="modal-body row mx-0">
                                            {isValid ? <p className="text-danger">{isValid}</p> : ''}
                                            <div className="account_recovery">
                                                <FieldArray
                                                    name="optionList"
                                                    render={({ remove, push }) => (
                                                        <div>
                                                            <label className="text-white fs-14 mb-2">Select Question</label>
                                                            <Field
                                                                as="select" className="account_recovery_select"
                                                                id={"recovery_question_1"}
                                                                name={"recovery_question_1"}
                                                            >
                                                                <option className="account_recovery_option">
                                                                    Select Question
                                                                </option>
                                                                {values?.questions.map((option) => (
                                                                    <option className="account_recovery_option"
                                                                            key={option.question_id}
                                                                            value={option.question_id}>
                                                                        {option.question}
                                                                    </option>
                                                                ))}
                                                            </Field>
                                                            {errors.recovery_question_1 && touched.recovery_question_1 && <div className="text-danger">{errors.recovery_question_1}</div>}
                                                                <div className="col my-3">
                                                                    <label className="text-white fs-14 mb-2">Your Answer</label>
                                                                    <div className="material-textfield">
                                                                        <Field
                                                                            type="text"
                                                                            placeholder="Your Answer"
                                                                            name={`recovery_answer_1`}
                                                                        />
                                                                    </div>
                                                                    {errors.recovery_answer_1 && touched.recovery_answer_1 &&
                                                                        <div className="text-danger">{errors.recovery_answer_1}</div>}
                                                                    </div>
                                                                </div>
                                                        )}
                                                />
                                            </div>
                                            <div className="account_recovery">
                                                <label className="text-white fs-14 mb-2">Select Question</label>
                                                <Field
                                                    as="select" className="account_recovery_select"
                                                    id={"recovery_question_2"} name={"recovery_question_2"}
                                                >
                                                    <option className="account_recovery_option">
                                                        Select Question
                                                    </option>
                                                    {values?.questions.map((option) => (
                                                        <option className="account_recovery_option"
                                                                key={option.question_id} value={option.question_id}>
                                                            {option.question}
                                                        </option>
                                                    ))}
                                                </Field>
                                                {errors.recovery_question_2 && touched.recovery_question_2 && <div className="text-danger">{errors.recovery_question_2}</div>}
                                                <div className="col my-3">
                                                    <label className="text-white fs-14 mb-2">Select Question</label>
                                                    <div className="material-textfield">
                                                        <Field
                                                            type="text"
                                                            placeholder="Your Answer"
                                                            name={`recovery_answer_2`}
                                                        />
                                                    </div>
                                                    {errors.recovery_answer_2 && touched.recovery_answer_2 && <div className="text-danger">{errors.recovery_answer_2}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer border-0 mx-0">
                                            <div className="row w-100">
                                                <div className="col-md-6 my-1">
                                                    <button
                                                        type="submit"
                                                        className="login-btn btn-hover color-1"
                                                        disabled={isSubmitting || Object.keys(errors).length !== 0}
                                                    >
                                                        Next
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                            : null}
                    </div>
                </div>
                <button type="button" className="btn-close rounded-circle opacity-100" data-bs-dismiss="modal" onClick={handleClose}></button>
            </div>
            <SeedPhraseModal isOpen={seedPhraseModal} handleClose={closeSeedPhraseModal} dataSeedPhrase={seedPhrase}/>
        </>
    );

}
