import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import virtualCoinBigIcon from "../../components/assets/images/icon/virtua-coin-big.svg";
import chessCardIcon from "../../components/assets/images/chess-card.svg";
import SplashScreen from "../../components/layout/splashScreen";
import {virtuaSwapActions} from "../../_store";

function VirtuaSwap() {
    const [activeTab, setActiveTab] = useState('insta-wallet');
    const [activeIndex, setActiveIndex] = useState(0);
    const authUser = useSelector(x => x.auth.user);

    const dispatch = useDispatch();
    const nftList = useSelector(state => state.virtuaSwap.nft);
    const walletDetails = useSelector(state => state.wallet.wallet);
    const walletLoading = useSelector(state => state.wallet.loading);
    const applicationList = useSelector((state) => state.application.applicationItem);
    const loading = useSelector((state) => state.virtuaSwap.loading);

    const handleItemClick = (index) => setActiveIndex(index);

    useEffect(() => {
        let data = {};
        if (activeIndex === 0) {
            data.user_id = authUser?.user_id;
        } else {
            data.application_id = activeIndex;
        }

        if (authUser) {
            dispatch(virtuaSwapActions.getNFTList(data))
        }
    }, [activeIndex]);

    return (
        <>
            <div className="row info">
                <div className="row mx-2 mt-4">
                    <h4 className="text-white page-title">VirtuaSwap</h4>
                </div>
                <div className="row mt-3">
                    <div className="col-12 ps-0 pe-4 game-tabs">
                        <ul className="nav nav-tabs ps-4" id="game-tab" role="tablist">
                            <li className={"nav-item"} role="presentation" onClick={() => setActiveTab('insta-wallet')}>
                                <button
                                    className="nav-link active" id="insta-wallet-tab" data-bs-toggle="tab"
                                    data-bs-target="#insta-wallet" type="button" role="tab"
                                    aria-controls="insta-wallet" aria-selected="false"
                                >
                                    Insta Wallet
                                </button>
                            </li>
                            <li className={"nav-item"} role="presentation" onClick={() => setActiveTab('nft')}>
                                <button
                                    className="nav-link" id="nft-tab" data-bs-toggle="tab" data-bs-target="#nft"
                                    type="button" role="tab" aria-controls="nft" aria-selected="false"
                                >
                                    NFT
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContentInstaWallet">
                            <div
                                className={"tab-pane fade px-2 " + (activeTab === 'insta-wallet' ? 'show active' : '')}
                                id="insta-wallet" role="tabpanel" aria-labelledby="insta-wallet-tab"
                            >
                                <div className="row mt-4 ps-3">
                                    {walletLoading && <SplashScreen isFullScreen={false}/>}
                                    {(_.isEmpty(walletDetails) && !walletLoading) && (
                                        <div>
                                            <p className="text-white font-13 fw-bold pt-3">No Data Available</p>
                                        </div>
                                    )}
                                    {(walletDetails || []).filter(wallet => wallet.account_for === "insta").map((wallet, index) => (
                                        <div className="col-lg-3 col-md-3" key={index}>
                                            <div className="card rounded-4">
                                                <div className="card-body text-center py-4">
                                                    <div className="row">
                                                        <img src={virtualCoinBigIcon} style={{maxWidth: "110px"}} className="mx-auto"/>
                                                    </div>
                                                    <div className="row pt-3 pb-1">
                                                        <p className="text-white font-14 fw-bold mb-0">{wallet.balance.toFixed(4)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-white text-center mt-4 font-18 fw-bold">
                                                {wallet.nick_name}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div
                                className={"tab-pane fade px-2 " + (activeTab === 'nft' ? 'show active' : '')} id="nft"
                                role="tabpanel" aria-labelledby="nft-tab"
                            >
                                <div className="row mt-4 ps-3 pb-4 border-bottom border-secondary">
                                    <div className="d-flex flex-row align-middle small-game-div">
                                        <div className="ps-4 pe-3">
                                            <p className="text-white font-13 fw-bold pt-3">Select Game</p>
                                        </div>
                                        <div className="px-2">
                                            <img
                                                onClick={() => handleItemClick(0)}
                                                alt="activeIndex"
                                                src="/assets/images/icon/allpreview.png"
                                                className={`img-fluid ${0 === activeIndex ? 'active' : ''}`}
                                                style={{width: "55px"}}
                                            />
                                        </div>
                                        {(applicationList || []).map((application, index) => (
                                            <div className="px-2" key={index}>
                                                <img
                                                    onClick={() => handleItemClick(application.id)}
                                                    src={application.app_icon ? "https://virtuagrid.com/" + application.app_icon : chessCardIcon}
                                                    className={`img-fluid ${application.id === activeIndex ? 'active' : ''}`}
                                                    style={{width: "55px"}}
                                                />
                                            </div>
                                        ))}
                                        {applicationList.length === 0 && (
                                            <div>
                                                <p className="text-white font-13 fw-bold pt-3">No Data Available</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="row mt-4 ps-3">
                                    {loading && <SplashScreen isFullScreen={false}/>}
                                    {(nftList || []).map((item, index) => {
                                        return (
                                            <div className="col-lg-2 col-md-2" key={index}>
                                                <div className="card rounded-4 bg-transparent">
                                                    <div className="card-body text-center pt-4 pb-2">
                                                        <img
                                                            src={item?.nft_file ? item?.nft_file : chessCardIcon}
                                                            alt={item?.nft_name}
                                                            className="img-fluid position-relative"
                                                        />
                                                        <button
                                                            className="btn background-light-green position-absolute font-14 fw-semibold p-2 py-0"
                                                            style={{top: "9%", right: "6%"}}
                                                        >
                                                            {item?.quantity || 0}
                                                        </button>
                                                    </div>
                                                </div>
                                                <p className="text-white text-center mt-2 font-14 fw-bold">{item?.nft_name}</p>
                                            </div>
                                        )
                                    })}
                                    {(!loading && (nftList || []).length === 0) && (
                                        <div>
                                            <p className="text-white font-13 fw-bold pt-3">No Data Available</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VirtuaSwap;
