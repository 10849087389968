import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import { questionsReducer } from "./questions.slice";
import { profilesReducer } from "./profiles.slice";
import { friendsReducer } from "./friends.slice";
import { friendRequestsReducer } from "./friendRequest.slice";
import { referEarnReducer } from "./referEarn.slice";
import { shopReducer } from "./shop.slice";
import { weeklyRewardsReducer } from "./weeklyReward.slice";
import { virtuaSwapsReducer } from "./virtuaSwap.slice";
import { appSettingReducer } from "./appSettings.slice";
import { gameReducer } from "./gameStore.slice";
import { walletReducer } from "./wallet.slice";
import { applicationReducer } from "./applicationStore.slice";
import { notificationStore } from "./notificationStore.slice";

export * from './auth.slice';
export * from './users.slice';
export * from './questions.slice';
export * from './profiles.slice';
export * from './friends.slice';
export * from './friendRequest.slice';
export * from './referEarn.slice';
export * from './shop.slice';
export * from './weeklyReward.slice';
export * from './virtuaSwap.slice';
export * from './appSettings.slice';
export * from './gameStore.slice';
export * from './wallet.slice';
export * from './applicationStore.slice';
export * from './notificationStore.slice';

const combinedReducer = combineReducers({
    auth: authReducer,
    users: usersReducer,
    questions: questionsReducer,
    profiles: profilesReducer,
    friends: friendsReducer,
    friendRequest: friendRequestsReducer,
    referEarn: referEarnReducer,
    shop: shopReducer,
    weeklyRewards: weeklyRewardsReducer,
    virtuaSwap: virtuaSwapsReducer,
    appSetting: appSettingReducer,
    games: gameReducer,
    wallet: walletReducer,
    application:applicationReducer,
    notification:notificationStore
});

const rootReducer = (state, action) => {

    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer
});
