import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import {authActions, userActions, friendRequestActions, friendActions, profileActions, questionActions, referEarnActions, appSettingActions, gameActions, walletActions, applicationActions} from "../../../_store";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import LoginModal from "../modal/LoginModal";
import RegModal from "../modal/RegisterModal";
import ProfileEditModal from "../modal/ProfileEditModal";
import ProfileModal from "../modal/ProfileModal";
import BuySellVirtuaCoinModal from "../modal/BuySellVirtuaCoinModal";
import SettingsModal from "../modal/SettingsModal";
import MaintenanceModeModal from "../../appSettings/MaintenanceModeModal";

import virtualCoin from '../../assets/images/icon/virtua-coin.svg';
import notificationIcon from '../../assets/images/icon/Notification.svg';
import statusIcon from '../../assets/images/icon/NotificationIcon.svg';
import notificationRectangle from '../../assets/images/icon/Rectangle 33.svg';
import group124 from '../../assets/images/icon/Group 124.svg';
import group127 from '../../assets/images/icon/Group 127.svg';
import userIcon from '../../assets/images/icon/icons.svg';
import navbarCoin from '../../assets/images/icon/navbar-coin.svg';
import userCircleYellow from '../../assets/images/icon/user-circle-yellow.svg';
import creditCardYellow from '../../assets/images/icon/credit-card-yellow.svg';
import settingYellow from '../../assets/images/icon/settings-yellow.svg';
import logoutYellow from '../../assets/images/icon/logout-yellow.svg';
import group87 from '../../assets/images/icon/Group 87.svg';
import metroCrossIcon from '../../assets/images/icon/Icon metro-cross.svg';
import smiley from '../../assets/images/icon/smiely.svg';
import awesomeCheckIcon from '../../assets/images/icon/Icon awesome-check.svg';
import CreateVirtualWalletModal from "../../CreateVirtualWalletModal";
import {notificationActions} from "../../../_store";


export default function NavbarMenu(){

    let navigate = useNavigate();
    const [loginModal, setLoginModal] = useState(false);
    const [registerModal, setRegisterModal] = useState(false);
    const [profileEditModal, setProfileEditModal] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [buySellVirtuaCoinModal, setBuySellVirtuaCoinModal] = useState(false);
    const [settingsModal, setSettingsModal] = useState(false);
    const [maintenanceModal, setMaintenanceModal] = useState(false);
    const loginLink = document.getElementById("loginLink");
    function openModal() {
        loginLink?.click(); // Trigger a click event on the link
    }

    // Call the openModal function to open the modal

    const closeLoginModal = (e) => {
        if(e){
            openModal();
        }else{
            setLoginModal(false)
        }
    }
    const backLogin = () =>{
        setLoginModal(true)
    }
    const closeMaintenanceModal = () => {
        setMaintenanceModal(false);
    }
    const closeRegisterModal = () => {
        setRegisterModal(false)
    }
    const backRegister = () => {
        setRegisterModal(true)
    }

    const closeProfileEditModal = () => {
        setProfileEditModal((prev)=>!prev);
    }

    const backProfileEditModal = () => {
        setProfileEditModal(true);
    }

    const closeProfileModal = () => {
        setProfileModal(false);
    }

    const closeBuySellVirtuaCoinModal = () => {
        setBuySellVirtuaCoinModal(false);
    }

    const closeSettingsModal = () => {
        setSettingsModal(false);
    }

    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authProfile = useSelector(x => x.auth.profile);
    const statusError = useSelector(x => x.users);
    const referEarn = useSelector(x => x.referEarn);
    let appSetting = useSelector(x => x.appSetting.appSettings);

    useEffect(() => {
        if (authUser){
            const values = authUser && authUser.user_id;
            dispatch(authActions.getUserDetails(values))
        }
    }, [dispatch]);

    const logout = () => {
        const values = {
            user_id: authUser
        }
        dispatch(authActions.logOut({values})).then((response) => {
            if (response.payload?.message) {
                toast.success(response.payload.message);
            }
            if (response?.error) {
                toast.error(response.error.message);
            }
        });
        dispatch(friendActions.resetFriends());
        dispatch(friendRequestActions.resetFriendRequest());
        dispatch(profileActions.resetProfiles());
        dispatch(questionActions.resetQuestions());
        dispatch(userActions.resetUsers());
        dispatch(referEarnActions.resetReferEarn());
        dispatch(appSettingActions.resetAppSetting());
        dispatch(gameActions.resetGame());
        dispatch(walletActions.resetWallet());
        navigate("/");
    }

    /* FriendRequest */
    const friendRequestLoading = useSelector(x => x.friendRequest.loading);
    const friendRequestResponse = useSelector(x => x.friendRequest.friendRequests)
    const notifications = useSelector(x => x.notification.notifications)

    useEffect(() => {
        if (authUser) {
            const values = {
                profile: authProfile?.profile_link,
                appsId: authUser?.appInfo?.id
            }
            dispatch(friendRequestActions.getFriendRequestsList({values}));
        }
        if (appSetting?.enable_maintenance_mode === 1) {
            setMaintenanceModal(true);
        }
    }, [dispatch]);

    useEffect(()=>{
        dispatch(applicationActions.getApplicationList());
        if (authUser) {
            dispatch(notificationActions.getNotification());
        }
    },[dispatch])

    useEffect(()=>{
        if(authUser){
            dispatch(walletActions.getWalletDetails(authUser?.user_id));
        }
    }, [dispatch])
    const changeStatus = () => {
        const values = {
            user_id: authUser.user_id,
            status: 0
        }
        return dispatch(userActions.postStatus({values}))
            .then((response) => {
                if (!response?.error && !response.payload?.error) {
                    toast.success(response.payload.message);
                }
                if (response?.error) {
                    toast.error(response?.error.message);
                }
            })
            .catch((error) => {
                console.error(error);
            })
    }

    /* Accept Friends Request */
    const acceptFriendRequest = (item) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id,
            friend_id: item.id
        };

        dispatch(friendRequestActions.postAcceptFriendRequest({values})).then(() => {
            const values = {
                profile: authProfile?.profile_link,
                appsId: authUser?.appInfo?.id
            }
            dispatch(friendRequestActions.getFriendRequestsList({values}));
        })
    };

    /* Decline Friends Request */
    const declineFriendRequest = (item) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id,
            friend_id: item.id
        }
        dispatch(friendRequestActions.postDeclineFriendRequest({values})).then(()=> {
            const values = {
                profile: authProfile?.profile_link,
                appsId: authUser?.appInfo?.id
            }
            dispatch(friendRequestActions.getFriendRequestsList({values}));
        })
    }

        return (
            <div className="row m-0">
                <nav className="navbar navbar-expand-sm navbar-dark bg-dark py-0">
                    <Link className="navbar-brand ps-4" to="/">
                        <img src={require('../../assets/images/logo/logo.png')} alt="logo"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#mynavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="mynavbar">
                        {!authUser ?
                            <>
                        <form className="d-flex ms-auto">
                            <div className="search_bar">
                                <div className="input-group align-items-center">
                                    <input type="text" className="form-control rounded text-white" placeholder="Search"/>
                                    <i className="fa-solid fa-magnifying-glass"></i>
                                </div>
                            </div>
                        </form>
                        <div className="login">
                            <Link to="/" className="text-white px-4 font-13" id="loginLink" data-bs-target="#loginModel" role="modal" data-bs-toggle="modal" onClick={() => {setLoginModal(true);}}>
                                Login
                            </Link>
                        </div>
                        <div className="register pe-4">
                            <Link to="/" className="text-white font-13" data-bs-target="#registerModel" role="modal" data-bs-toggle="modal" onClick={() => {setRegisterModal(true);}}>
                                Registration
                            </Link>
                        </div></>
                            :
                            <>
                                <div className="d-flex flex-row ms-auto">
                                    <div className="px-3 border-secondary border-end d-flex">
                                        <Link to="/weekly_reward" className="font-13 yellow-heading text-end pt-2">
                                            Weekly Reward
                                            <span className="fw-bold text-white font-13">
                                                <img src={virtualCoin} style={{width: '20px'}} alt="logo"/>
                                                {_.get(authProfile, "weekly_rewards", "0")}
                                            </span>
                                        </Link>
                                    </div>
                                    <div className="ps-2 pe-4 border-secondary border-end">
                                        <div className="row pt-2">
                                            <div className="col-lg-6 col-md-6 position-relative"
                                                 id="notificationDropdown" role="button" data-bs-toggle="dropdown"
                                                 aria-expanded="false">
                                                <img src={notificationIcon} id="refer-img" alt="logo"/>
                                                <span className="notification-pill rounded-circle font-10">5</span>
                                            </div>
                                            <ul className="dropdown-menu notification-dropdown py-4"
                                                aria-labelledby="notificationDropdown">
                                                <div className="row">
                                                    <div className="col-md-9">
                                                        <p><img src={notificationRectangle} alt="logo" className=""
                                                                style={{width: '3px'}}/><span
                                                            className="ps-4 fw-bold yellow-heading font-13">Notifications</span>
                                                        </p>
                                                    </div>
                                                    <div className="col-md-3 text-end pe-5">
                                                        <Link to="/"
                                                              className="font-13 fw-bold text-decoration-none color-light-blue">Clear</Link>
                                                    </div>
                                                </div>
                                                <div className="horizontal-divider" id="friend-request-divider"></div>
                                                <div className="row px-4 my-3">
                                                    <div className="col-md-2">
                                                        <img alt="person"
                                                             src={require('../../assets/images/person1.png')}
                                                             style={{width: '55px'}}/>
                                                    </div>
                                                    <div className="col-md-7 px-0">
                                                        <p className="font-13 text-white mb-0"><span
                                                            className="color-green">Bruce Wayne</span> has requested to
                                                            play Tuti Pool Game</p>
                                                        <p className="text-secondary font-13">3 min ago</p>
                                                    </div>
                                                    <div className="col-md-2 d-flex ms-2">
                                                        <img src={group124} alt="logo" style={{width: '30px'}}
                                                             className="me-2"/>&nbsp;&nbsp;
                                                        <img src={group127} alt="logo" style={{width: '30px'}}/>
                                                    </div>
                                                </div>
                                                <div className="horizontal-divider" id="friend-request-divider"></div>
                                                <div className="row px-4 my-3">
                                                    <div className="col-md-2">
                                                        <img src={require('../../assets/images/Rectangle 40@2x.png')}
                                                             alt="logo" style={{width: '55px'}}/>
                                                    </div>
                                                    <div className="col-md-7 px-0">
                                                        <p className="font-13 text-white mb-0"><span
                                                            className="color-green">Adam Wan</span> has requested to
                                                            play Tuti Pool Game</p>
                                                        <p className="text-secondary font-13">3 min ago</p>
                                                    </div>
                                                    <div className="col-md-2 d-flex ms-2">
                                                        <img src={group124} style={{width: '30px'}} alt="logo"
                                                             className="me-2"/>&nbsp;&nbsp;
                                                        <img src={group127} alt="logo" style={{width: '30px'}}/>
                                                    </div>
                                                </div>
                                                <div className="horizontal-divider" id="friend-request-divider"></div>
                                                <div className="row px-4 mt-3 text-center">
                                                    <Link to="/"
                                                          className="font-13 fw-bold text-decoration-none color-light-blue">View
                                                        All <i className="fa-solid fa-chevron-right"></i></Link>
                                                </div>
                                            </ul>

                                            <div className="col-lg-6 col-md-6 position-relative" id="requestDropdown"
                                                 role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                <img src={userIcon} alt="logo" id="refer-img"/>
                                                <span
                                                    className="notification-pill rounded-circle font-10">{!friendRequestLoading && friendRequestResponse.length}</span>
                                            </div>
                                            <ul className="dropdown-menu request-dropdown py-4"
                                                aria-labelledby="requestDropdown">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <p className="border-bottom border-secondary pb-3">
                                                            <img src={notificationRectangle} alt="logo" className=""
                                                                 style={{width: '3px'}}/>
                                                            <span className="ps-4 fw-bold yellow-heading font-13">Friend Requests</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                {!friendRequestLoading && friendRequestResponse?.length === 0 &&
                                                    <div className="row px-4 mt-3 text-center text-white-50">
                                                        <div className="col-lg-12">
                                                            No Friend Requests
                                                        </div>
                                                    </div>
                                                }
                                                {!friendRequestLoading && friendRequestResponse?.length > 0 &&
                                                    friendRequestResponse.map((item, index) => (
                                                        <div className="row px-4 my-3" key={index}>
                                                            <div className="col-lg-5 col-md-5 d-flex">
                                                                <img src={require('../../assets/images/person1.png')}
                                                                     alt="logo" style={{width: '35px'}}/>
                                                                <p className="text-white fw-bold font-13 m-2">{item.first_name} {item.last_name}</p>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <img src={require('../../assets/images/india1.png')}
                                                                     id="friend-request-body-flag" alt="logo"
                                                                     className="mt-2"/>
                                                            </div>
                                                            <div className="col-md-5">
                                                                <div className="row">
                                                                    <div className="col-auto"
                                                                         id="friend-request-body-accept-btn">
                                                                        <button type="button"
                                                                                className="transparent-link"
                                                                                onClick={() => acceptFriendRequest(item)}>
                                                                            <img
                                                                                src={require('../../assets/images/Group 114@2x.png')}
                                                                                alt="logo" style={{width: '70px'}}/>
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-auto"
                                                                         id="friend-request-body-accept-btn">
                                                                        <button type="button"
                                                                                className="transparent-link"
                                                                                onClick={() => declineFriendRequest(item)}>
                                                                            <img
                                                                                src={require('../../assets/images/Group 115@2x.png')}
                                                                                alt="logo" style={{width: '70px'}}/>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                {!friendRequestLoading && friendRequestResponse?.length > 0 &&
                                                    <div className="row px-4 mt-3 text-center">
                                                        <Link to="/friends"
                                                              className="font-13 fw-bold text-decoration-none color-light-blue">View
                                                            All <i className="fa-solid fa-chevron-right"></i></Link>
                                                    </div>}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="px-3 border-secondary border-end">
                                        <div className="pt-2 d-flex gap-1">
                                            <div className="d-flex align-items-center">
                                                <img src={navbarCoin} alt="logo" style={{width: '15px'}}/>
                                                <span className="text-white px-1" id="refer-hr">
                                                    {_.get(authProfile, "totalBalance", "0")}
                                                </span>
                                            </div>
                                            <div className="">
                                                <button className="btn border-0 btn-hover color-1 py-1 font-13"
                                                        style={{backgroundColor: "#42dbcb"}} id="button-addon2"
                                                        onClick={() => {
                                                            setBuySellVirtuaCoinModal(true)
                                                        }}>Buy/Sell
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="ps-3">
                                        <div className="row pt-1">
                                            <div className="col-lg-3 col-md-3">
                                                <img
                                                     src={_.get(authProfile,"profile_picture","")} alt="logo"
                                                     className="avatar"
                                                     id="avtar-img"
                                                />
                                            </div>
                                            <div className="col-lg-9 col-md-9">
                                                <div className="d-flex flex-row">
                                                    <span
                                                        className="text-white fw-bold font-13">{authProfile?.first_name} {authProfile?.last_name}</span>
                                                    <i className="fa-solid fa-chevron-down text-white font-13 px-1 py-1"
                                                       id="profileDropdown" role="button" data-bs-toggle="dropdown"
                                                       aria-expanded="false"></i>
                                                    <ul className="dropdown-menu mx-0 py-2"
                                                        aria-labelledby="profileDropdown">
                                                        <li className="pb-1"><Link onClick={() => {
                                                            setProfileEditModal(true);
                                                        }}
                                                                                   className="dropdown-item text-white font-13"><img
                                                            src={userCircleYellow}
                                                            className="me-2" alt="logo"
                                                            style={{width: '15px'}}/>&nbsp;Profile</Link></li>
                                                        <li className="pb-1"><Link onClick={() => {
                                                            setProfileModal(true);
                                                        }}
                                                                                   className="dropdown-item text-white font-13"><img
                                                            src={userCircleYellow}
                                                            className="me-2" alt="logo"
                                                            style={{width: '15px'}}/>&nbsp;Switch Profile</Link></li>
                                                        <li className="pb-1"><Link
                                                            className="dropdown-item text-white font-13"><img
                                                            src={creditCardYellow}
                                                            className="me-2" alt="logo"
                                                            style={{width: '15px'}}/>&nbsp;Transactions</Link></li>
                                                        <li className="pb-1"><Link onClick={() => {
                                                            setSettingsModal(true);
                                                        }}
                                                                                   className="dropdown-item text-white font-13"><img
                                                            src={settingYellow}
                                                            className="me-2" alt="logo"
                                                            style={{width: '15px'}}/>&nbsp;Settings</Link>
                                                        </li>
                                                        <li>
                                                            <button onClick={() => logout()}
                                                                    className="dropdown-item text-white font-13"><img
                                                                src={logoutYellow} className="me-2"
                                                                alt="logo" style={{width: '15px'}}/>&nbsp;Logout
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="d-flex flex-row">
                                                    <i className={"fa-solid fa-circle font-10 py-1 me-1 " + (authUser?.is_active === "Yes" ? 'text-success' : 'text-secondary')}></i>
                                                    <span className="text-secondary font-10" id="statusDropdown"
                                                          role="button" data-bs-toggle="dropdown"
                                                          aria-expanded="false">{authUser?.is_active === "Yes" ? 'Online' : 'Offline'}</span>
                                                    <ul className="dropdown-menu status-dropdown py-4"
                                                        aria-labelledby="statusDropdown">
                                                        <div className="row">
                                                            <p><img src={statusIcon} alt="logo"
                                                                    className="" style={{width: '3px'}}/><span
                                                                className="ps-4 fw-bold yellow-heading font-13">status</span>
                                                            </p>
                                                        </div>
                                                        <div className="row px-4">
                                                            <div className="col-lg-4 col-md-4 pe-1">
                                                                <Link
                                                                    className="btn font-13 text-white fw-bold py-2 grey-button w-100"
                                                                    to="/"><img src={group87}
                                                                                alt="logo" className="pe-2"
                                                                                style={{width: '22px'}}/>Auto</Link>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 px-1">
                                                                <button
                                                                    className="btn font-13 text-white fw-bold py-2 btn-outline-secondary w-100"
                                                                    onClick={changeStatus}>
                                                                    <i className="fa-solid fa-circle font-10 py-1 text-secondary me-1"></i> Offline
                                                                </button>
                                                            </div>
                                                            <div className="col-lg-4 col-md-4 ps-1">
                                                                <Link
                                                                    className="btn btn-outline-secondary text-white fw-bold py-2 font-13 w-100"
                                                                    to="/"><img
                                                                    src={metroCrossIcon} alt="logo"
                                                                    className="pe-2" style={{width: '18px'}}/>Don't Show</Link>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4">
                                                            <p><img src={statusIcon} alt="logo"
                                                                    style={{width: '3px'}}/><span
                                                                className="ps-4 fw-bold yellow-heading font-13">status Message</span>
                                                            </p>
                                                        </div>
                                                        <div className="row px-4">
                                                            <div className="col-md-2 pe-0">
                                                                <Link className="btn px-2 py-1 grey-button" to="/"><img
                                                                    src={smiley} alt="logo"
                                                                    style={{width: '15px'}}/> <i
                                                                    className="fa-solid fa-chevron-down text-white font-13 ms-1"></i>
                                                                </Link>
                                                            </div>
                                                            <div className="col-md-10">
                                                                <div className="input-group">
                                                                    <input type="text"
                                                                           className="form-control border-end-0 border-secondary bg-transparent"
                                                                           placeholder="I'm playing Tuti Games!"
                                                                           aria-label="Recipient's username"
                                                                           aria-describedby="button-addon2"/>
                                                                    <span
                                                                        className="input-group-text bg-transparent border-start-0 border-secondary"><img
                                                                        src={awesomeCheckIcon}
                                                                        alt="logo" className="pe-2"
                                                                        style={{width: '20px'}}/></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}

                        {!authUser && <LoginModal isOpen={loginModal} handleClose={(e) => {
                            closeLoginModal(e)
                        }} handleBack={backLogin} backRegister={backRegister}/>}

                        <MaintenanceModeModal isOpen={maintenanceModal} handleClose={closeMaintenanceModal}/>
                        <RegModal isOpen={registerModal} handleClose={closeRegisterModal} handleBack={backRegister}
                                  backLogin={backLogin}/>
                        <ProfileEditModal isOpen={profileEditModal} handleClose={closeProfileEditModal}
                                          handleBack={backProfileEditModal}/>
                        <ProfileModal isOpen={profileModal} handleClose={closeProfileModal}/>
                        <BuySellVirtuaCoinModal isOpen={buySellVirtuaCoinModal}
                                                handleClose={closeBuySellVirtuaCoinModal}/>
                        <SettingsModal isOpen={settingsModal} handleClose={closeSettingsModal}/>
                        <CreateVirtualWalletModal/>
                    </div>
                </nav>
            </div>

        );
}
