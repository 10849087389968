import React from "react";



export default function SplashScreen({isFullScreen = true}) {
    return(
        <>
            <div className={`d-flex align-items-center justify-content-center ${isFullScreen ? "vh-100" : ""}`}>
                <div className="text-center">
                    <div className="spinner-border text-warning"
                         id="spinner" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </>
    )
}
