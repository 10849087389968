import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {authActions, profileActions, userActions} from "../../../_store";
import { Formik, Field, Form } from "formik";
import ReactFlagsSelect from "react-flags-select";
import CompleteKycDoMoreModal from "./CompleteKycDoMoreModal";
import ResetPinFirstModal from "./ResetPinFirstModal";
import ResetPinSecondModal from "./ResetPinSecondModal";

import personAdd from "../../assets/images/icon/Icon ionic-ios-person-add.svg";
import group2318 from "../../assets/images/icon/Group 2318.svg";
import invisible from "../../assets/images/icon/invisible.svg";
import group2325 from "../../assets/images/icon/Group 2325.svg";
import group2324 from "../../assets/images/icon/Group 2324.svg";
import awesomeCheck from "../../assets/images/icon/Icon awesome-check.svg";
import ForgotPasswordModal from "./ForgotPasswordModal";
import CreateVirtualWalletModal from "../../CreateVirtualWalletModal";
import LeaderboardModal from "../../../pages/leaderboard";
import Cookies from "js-cookie";
import _ from "lodash";
import ActiveStepProfile from "../../ActiveStepProfile";
import {Modal} from "react-bootstrap";
import login1Img from "../../assets/images/icon/login1.svg";
import DeleteAccountConfirmation from "./DeleteAccountConfirmation";
const baseUrl = `${process.env.REACT_APP_API_URL}`;

export default function ProfileEditModal({ isOpen, handleClose, handleBack }) {

  const [completeKycModal, setCompleteKycModal] = useState(false);
  const [resetPinFirstModal, setResetPinFirstModal] = useState(false);
  const [resetPinSecondModal, setResetPinSecondModal] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [isOpenVirtualWallet, setVirtualWallet] = useState(false);
  const [deleteAccountConfirmation, setDeleteAccount] = useState(false);
  const [isOpenLeaderboard, setLeaderboard] = useState(false);
  const [select, setSelect] = useState("IN");
  const onSelect = (code) => setSelect(code);

  // Redux Methods
  const dispatch = useDispatch();
  const authUser = useSelector((x) => x.auth.user);
  const authProfile = useSelector((x) => x.auth.profile);
  const otpRequestLoading = useSelector((x) => x.profiles.loading);

  useEffect(() => {
    const values = authUser && authUser.user_id;
    dispatch(authActions.getUserDetails(values));
  }, [isOpen === true]);

  const closeCompleteKycModal = () => {
    setCompleteKycModal(false);
    return handleClose(true);
  };

  const closeResetPinFirstModal = (val) => {
    if (val) {
      setResetPinSecondModal(true);
    } else {
      setResetPinFirstModal(false);
      return handleBack(true);
    }
  };

  const closeResetPinSecondModal = (val) => {
    if (val) {
      document.querySelectorAll(".modal-backdrop").forEach((e) => e.remove());
    } else {
      setResetPinFirstModal(false);
      setResetPinSecondModal(false);
      return handleBack(true);
    }
  };

  const backProfileEditModal = () => {
    setCompleteKycModal(false);
    return handleBack(true);
  };

  const backResetPinFirstModal = (val) => {
    setResetPinFirstModal(false);
    return handleBack(true);
  };

  const backResetPinSecondModal = (val) => {
    setResetPinFirstModal(false);
    setResetPinSecondModal(false);
    return handleBack(true);
  };

  const handleChange = (values) => {
    console.log(values);
  };

  function sendResetPasswordOtp() {
    const values = {
      email: authUser && authUser.email,
    };
    dispatch(profileActions.getNewOtpByEmail({ values })).then(() => {
      handleClose(true);
      document.querySelectorAll(".modal-backdrop").forEach((e) => e.remove());
      setResetPinFirstModal(true);
    });
  }

  const openResetPasswordModal = () => {
    handleClose(true);
    setResetPasswordModal(true);
  };

  const openVirtualWalletModal = () => {
    handleClose(true);
    setVirtualWallet(true)
  };
  const openLeaderboardModal = () => {
    handleClose(true);
    setLeaderboard(true)
  };

  const closeResetPasswordModal = (e) => {
    if (e) {
      setResetPasswordModal(false);
      return handleClose(true);
    } else {
      setResetPasswordModal(false);
      return handleClose(false);
    }
    // setResetPasswordModal(false)
    //   return handleClose(true);
  };

  const backProfileModal = (e) => {
    setResetPasswordModal(false);
    return handleBack();
  };

  return (
    <>
      <Modal
          show={isOpen}
          onHide={handleClose}
          size="lg"
          centered
      >
        <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
          <div className="col-md-4 d-flex">
            <img src={personAdd} alt=""/>
            <h4 className="modal-title text-white ms-2">Profile</h4>
          </div>
          <div className="col-md-8" id="profile-header-right">
            <div className="row justify-content-between">
              <div className="col">
                <ActiveStepProfile
                    label="KYC 1"
                    isActive={_.get(authProfile, "kyc_1_status", false)}
                />
              </div>
              <img
                  src={group2318}
                  alt=""
                  id="profile-header-right-stepper-icon-arrow"
              />
              <div className="col">
                <ActiveStepProfile
                    label="KYC 2"
                    isActive={_.get(authProfile, "kyc_2_status", false)}
                />
              </div>
              <img
                  src={group2318}
                  alt=""
                  id="profile-header-right-stepper-icon-arrow"
              />
              <div className="col">
                <ActiveStepProfile
                    label="KYC 3"
                    isActive={_.get(authProfile, "kyc3_status", false)}
                />
              </div>
              <div className="col-sm-4 me-3">
                <button
                    className="btn"
                    id="profile-header-btn"
                    onClick={() => {
                      handleClose(true);
                      setCompleteKycModal(true);
                    }}
                >
                  Do More
                </button>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Formik
            initialValues={{
              nickname:
                  authProfile?.nickname !== null ? authProfile?.nickname : "",
              email: authUser?.email !== null ? authUser?.email : "",
              country:
                  authProfile?.country !== null ? authProfile?.country : "",
            }}
            onSubmit={(values) => {
              console.log("submitted");
            }}
            enableReinitialize
        >
          {({isValid, isSubmitting, values}) => (
              <Form>
                <>
                  <div className="modal-body row mx-0">
                    <div className="row justify-content-between pe-0">
                      <div className="col-md-8">
                        <div className="d-sm-flex text-black">
                          <div className="flex-shrink-0">
                            {authProfile?.profile_picture && (
                                <img
                                    src={_.get(authProfile,"profile_picture","")}
                                    alt="Profile Picture"
                                    className="img-fluid"
                                    style={{width: "75px", borderRadius: "10px"}}
                                />
                            )}
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <p className="mb-0 fw-bold font-25 text-white">
                              {authProfile?.first_name} {authProfile?.last_name}
                            </p>
                            <p
                                className="mb-2 pb-1 fw-bold text-warning"
                                style={{fontSize: "10px"}}
                            >
                              <span className="me-2">
                                <img
                                    alt="ins"
                                    src={invisible}
                                    style={{width: "15px"}}
                                />
                              </span>
                              Invisible Mode
                            </p>
                            <div className="d-flex pt-1">
                              <img
                                  src={group2325}
                                  alt=""
                                  style={{width: "40px"}}
                              />
                              &nbsp;&nbsp;
                              <img
                                  src={group2324}
                                  alt=""
                                  style={{width: "40px"}}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 py-2 text-end px-0">
                        <ReactFlagsSelect
                            style={{border: "1px soid white"}}
                            selected={select}
                            onSelect={onSelect}
                            countries={["AD", "AE", "AF", "AG", "AI", "AL", "AM", "AO", "AQ", "AR", "AS", "AT", "AU", "AW", "AX", "AZ", "BA", "BB", "BD", "BE", "BF", "BG", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ", "BR", "BS", "BT", "BV", "BW", "BY", "BZ", "CA", "CC", "CD", "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO", "CR", "CU", "CV", "CW", "CX", "CY", "CZ", "DE", "DJ", "DK", "DM", "DO", "DZ", "EC", "EE", "EG", "EH", "ER", "ES", "ET", "FI", "FJ", "FK", "FM", "FO", "FR", "GA", "GB", "GD", "GE", "GF", "GG", "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GR", "GS", "GT", "GU", "GW", "GY", "HK", "HM", "HN", "HR", "HT", "HU", "ID", "IE", "IL", "IM", "IN", "IO", "IQ", "IR", "IS", "IT", "JE", "JM", "JO", "JP", "KE", "KG", "KH", "KI", "KM", "KN", "KP", "KR", "KW", "KY", "KZ", "LA", "LB", "LC", "LI", "LK", "LR", "LS", "LT", "LU", "LV", "LY", "MA", "MC", "MD", "ME", "MF", "MG", "MH", "MK", "ML", "MM", "MN", "MO", "MP", "MQ", "MR", "MS", "MT", "MU", "MV", "MW", "MX", "MY", "MZ", "NA", "NC", "NE", "NF", "NG", "NI", "NL", "NO", "NP", "NR", "NU", "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PL", "PM", "PN", "PR", "PS", "PT", "PW", "PY", "QA", "RE", "RO", "RS", "RU", "RW", "SA", "SB", "SC", "SD", "SE", "SG", "SH", "SI", "SJ", "SK", "SL", "SM", "SN", "SO", "SR", "SS", "ST", "SV", "SX", "SY", "SZ", "TC", "TD", "TF", "TG", "TH", "TJ", "TK", "TL", "TM", "TN", "TO", "TR", "TT", "TV", "TW", "TZ", "UA", "UG", "UM", "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VI", "VN", "VU", "WF", "WS", "YE", "YT", "ZA", "ZM", "ZW"]}
                            placeholder="Select Country"
                        />
                      </div>
                    </div>
                    <div className="row justify-content-between mt-5 mb-4">
                      <div className="col-6">
                        <div className="material-textfield">
                          <Field
                              type="text"
                              name="nickname"
                              placeholder="Nick Name"
                              onKeyUp={() => handleChange(values)}
                          />
                          <label>Nick Name</label>
                          <button
                              className="bg-transparent border-0 shadow-none toggle-password"
                              style={{backgroundColor: "#3B3B3B"}}
                          >
                            <img
                                src={awesomeCheck}
                                alt=""
                                className="pe-2"
                                style={{width: "20px"}}
                            />
                          </button>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="material-textfield">
                          <Field
                              type="text"
                              name="email"
                              disabled={true}
                              onKeyUp={() => handleChange(values)}
                              placeholder="Email Address"
                          />
                          <label>Email Address</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal.Footer className="border-0 mx-0">
                    <div className="row justify-content-between w-100">
                      <div className="d-flex col-md-12 flex-wrap justify-content-center gap-2">
                        <button
                            className="btn border-0 bg-warning font-13 fw-bold me-2 py-2 btn-hover color-2"
                            id="button-addon1"
                            data-bs-toggle="modal"
                            data-bs-target="#forgotPassword"
                            onClick={openResetPasswordModal}
                        >
                          Reset Password
                        </button>
                        <button
                            className="btn border-0 bg-danger text-white fw-bold font-13 btn-hover color-3 py-2 me-2"
                            id="button-addon2"
                            onClick={() => {
                              handleClose();
                              setDeleteAccount(true);
                              // deleteAccount(authUser.user_id);
                            }}
                        >
                          Delete Account
                        </button>
                        <button
                            type="button"
                            className="btn border-0 font-13 fw-bold py-2 btn-hover color-1"
                            style={{backgroundColor: "#42dbcb"}}
                            id="button-addon3"
                            onClick={sendResetPasswordOtp}
                        >
                          {otpRequestLoading ? (
                              <div
                                  className="spinner-border spinner-border-sm text-warning"
                                  role="status"
                              >
                                <span className="visually-hidden">Loading...</span>
                              </div>
                          ) : (
                              "Reset Pin"
                          )}
                        </button>
                        <button
                            type="button"
                            className="btn border-0 font-13 fw-bold py-2 btn-hover color-1 ms-2"
                            style={{backgroundColor: "#42dbcb"}}
                            id="button-addon3"
                            onClick={openLeaderboardModal}
                        > LeaderBoard
                        </button>
                        {/*<button*/}
                        {/*    type="button"*/}
                        {/*    className="btn border-0 font-13 fw-bold py-2 btn-hover color-1 ms-2"*/}
                        {/*    style={{backgroundColor: "#42dbcb"}}*/}
                        {/*    id="button-addon3"*/}
                        {/*    onClick={openVirtualWalletModal}*/}
                        {/*> Virtual wallet*/}
                        {/*</button>*/}
                      </div>
                    </div>
                  </Modal.Footer>
                </>
              </Form>
          )}
        </Formik>
      </Modal>
      <CompleteKycDoMoreModal
          isOpen={completeKycModal}
          handleClose={closeCompleteKycModal}
          handleBack={backProfileEditModal}
      />
      <ResetPinFirstModal
          isOpen={resetPinFirstModal}
          handleClose={closeResetPinFirstModal}
          handleBack={backResetPinFirstModal}
      />
      <ResetPinSecondModal
          isOpen={resetPinSecondModal}
          handleClose={closeResetPinSecondModal}
          handleBack={backResetPinSecondModal}
      />
      {resetPasswordModal && (
          <ForgotPasswordModal
              isOpen={resetPasswordModal}
              handleClose={(e) => {
                closeResetPasswordModal(e);
              }}
              handleBack={(e) => {
                backProfileModal(e);
              }}
              modelData={{title: "Reset Password"}}
          />
      )}
      <CreateVirtualWalletModal isOpen={isOpenVirtualWallet} toggle={() => setVirtualWallet(false)}/>
      {isOpenLeaderboard && (
          <LeaderboardModal isOpen={isOpenLeaderboard} toggle={() => setLeaderboard(false)}/>
      )}
      <DeleteAccountConfirmation
          isOpen={deleteAccountConfirmation}
          handleClose={()=>setDeleteAccount(false)}
      />
    </>
  );
}
