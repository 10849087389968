import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import './components/assets/css/style2.css';
import './components/assets/css/input.css';
import Layout from "./components/layout";

// Redux Store
import { Provider} from "react-redux";
import { store } from './_store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function App() {
  return (
      <Provider store={store}>
          <div className="container-fluid overflow-hidden pe-0">
              <Layout/>
              <ToastContainer position="top-center" autoClose={2000} hideProgressBar={true} newestOnTop={true} rtl={false} theme="dark"/>
          </div>
      </Provider>
  );
}

export default App;
