import React, {useState, useRef, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {profileActions} from "../../../_store";
import {Formik, Field, Form, ErrorMessage} from 'formik';


import virtualCoin from '../../assets/images/icon/virtua-coin.svg';
import featherInfo from '../../assets/images/icon/Icon feather-info.svg';
import * as Yup from "yup";



export default function ProfileAuthPinModal({isOpen, handleClose}) {

    const [error, setError] = useState(false);
    const [errMessage, setErrMessage] = useState('');

    const authPinFormRef = useRef();
    const otpValue1Ref = useRef(null);
    const otpValue2Ref = useRef(null);
    const otpValue3Ref = useRef(null);
    const otpValue4Ref = useRef(null);
    const otpValue5Ref = useRef(null);
    const otpValue6Ref = useRef(null);
    const otpValue7Ref = useRef(null);
    const otpValue8Ref = useRef(null);

    // Redux Methods
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const loading = useSelector(x => x.profiles.loading);
    const pinError = useSelector(x => x.profiles.error);
    const pin = useSelector(x => x.profiles.pin);

    useEffect(() => {
        if (otpValue1Ref.current) {
            otpValue1Ref.current.focus();
        }
    }, [otpValue1Ref]);

    const onChange = (event, valRef) => {
        if (!/[0-9]/.test(event.key)) {
            //regOtpFormRef?.current?.setError(true);
        }
        if (/[0-9]/.test(event.target.value) && valRef) {
            valRef.current.focus();
        }
    }

    function _setAuthPin(values) {
        Object.entries(values).map(([key,value])=> {
            if (value === '') {
                setError(true);
                setErrMessage('Please enter pin');
                return false;
            }
        });
        const authOtpGroup1 = Number(`${values.otpValue1}${values.otpValue2}${values.otpValue3}${values.otpValue4}`);
        const authOtpGroup2 = Number(`${values.otpValue5}${values.otpValue6}${values.otpValue7}${values.otpValue8}`);
        if (authOtpGroup1 !== authOtpGroup2) {
            setError(true);
            setErrMessage('Authorized pin & confirm Authorized Pin are Not same')
        } else {
            const values = {
                user_id: authUser.user_id,
                auth_pin: authOtpGroup1,
                confirm_pin: authOtpGroup2,
            }
            return dispatch(profileActions.setPin({values}));
        }
    }

    if (pin) {
        document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
        return handleClose(true);
    }
    return (
        <>
            <div className={"modal fade " + (isOpen ? 'show' : 'hide')} id="CreateVirtualCoinWallet" tabIndex={1} aria-modal='true' style={{display : isOpen ? 'block' : 'none'}}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header row mx-0 border-0 shadow-none">
                            <div className="col-md-12 d-flex">
                                <img src={virtualCoin} alt="" style={{width: '44px'}}/>
                                    <h4 className="modal-title text-white ms-2">Create VirtualCoin Wallet</h4>
                            </div>
                        </div>
                        <Formik
                                initialValues={{
                                    otpValue1 : "",
                                    otpValue2 : "",
                                    otpValue3 : "",
                                    otpValue4 : "",
                                    otpValue5 : "",
                                    otpValue6 : "",
                                    otpValue7 : "",
                                    otpValue8 : "",
                                }}
                                innerRef={authPinFormRef}
                                enableReinitialize={true}
                                validationSchema={Yup.object().shape({
                                    otpValue1: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                    otpValue2: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                    otpValue3: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                    otpValue4: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                    otpValue5: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                    otpValue6: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                    otpValue7: Yup.string().matches(/[0-9]/, "Please enter only digit"),
                                    otpValue8: Yup.string().matches(/[0-9]/, "Please enter only digit")
                                })}
                                onSubmit={values => {
                                    _setAuthPin(values);
                                }}
                                >
                                {({ values, errors, touched, handleReset, handleChange}) => (
                                        <Form>
                                            <div className="modal-body row mx-0 py-4">

                                                    {error ? <p className="text-danger">{errMessage}</p> : ''}
                                                    <p className="text-white"><small>Enter Authorized Pin</small></p>
                                                    <div className="input-container d-flex flex-row justify-content-start mb-3">
                                                        <div className="col-2">
                                                            <Field type="text" innerRef={otpValue1Ref} className="m-1 text-center py-3 form-control rounded"
                                                                   maxLength="1" placeholder="-" tabIndex={1} name="otpValue1" onInput={(event) => {onChange(event, otpValue2Ref)}}/>
                                                        </div>
                                                        &nbsp;&nbsp;
                                                        <div className="col-2">
                                                            <Field type="text" innerRef={otpValue2Ref} className="m-1 text-center py-3 form-control rounded"
                                                                   maxLength="1" placeholder="-" tabIndex={2} name="otpValue2" onInput={(event) => {onChange(event, otpValue3Ref)}}/>
                                                        </div>
                                                        &nbsp;&nbsp;
                                                        <div className="col-2">
                                                            <Field type="text" innerRef={otpValue3Ref} className="m-1 text-center py-3 form-control rounded"
                                                                   maxLength="1" placeholder="-" tabIndex={3} name="otpValue3" onInput={(event) => {onChange(event, otpValue4Ref)}}/>
                                                        </div>
                                                        &nbsp;&nbsp;
                                                        <div className="col-2">
                                                            <Field type="text" innerRef={otpValue4Ref} className="m-1 text-center py-3 form-control rounded"
                                                                   maxLength="1" placeholder="-" tabIndex={4} name="otpValue4" onInput={(event) => {onChange(event, otpValue5Ref)}}/>
                                                        </div>
                                                    </div>
                                                    <br/>
                                                    <p className="text-white"><small>Confirm Authorized Pin</small></p>
                                                    <div className="input-container d-flex flex-row justify-content-start mb-3">
                                                            <div className="col-2">
                                                                <Field type="text" innerRef={otpValue5Ref} className="m-1 text-center py-3 form-control rounded"
                                                                       maxLength="1" placeholder="-" tabIndex={5} name="otpValue5" onInput={(event) => {onChange(event, otpValue6Ref)}}/>
                                                            </div>
                                                            &nbsp;&nbsp;
                                                            <div className="col-2">
                                                                <Field type="text" innerRef={otpValue6Ref} className="m-1 text-center py-3 form-control rounded"
                                                                       maxLength="1" placeholder="-" tabIndex={6} name="otpValue6" onInput={(event) => {onChange(event, otpValue7Ref)}}/>
                                                            </div>
                                                            &nbsp;&nbsp;
                                                            <div className="col-2">
                                                                <Field type="text" innerRef={otpValue7Ref} className="m-1 text-center py-3 form-control rounded"
                                                                       maxLength="1" placeholder="-" tabIndex={7} name="otpValue7" onInput={(event) => {onChange(event, otpValue8Ref)}}/>
                                                            </div>
                                                            &nbsp;&nbsp;
                                                            <div className="col-2">
                                                                <Field type="text" innerRef={otpValue8Ref} className="m-1 text-center py-3 form-control rounded"
                                                                       maxLength="1" placeholder="-" tabIndex={8} name="otpValue8" onInput={(event) => {onChange(event)}}/>
                                                            </div>
                                                        </div>
                                                        <br/>
                                                            <div className="horizontal-divider border-top opacity-50"></div>
                                                            <div className="row justify-content-between pt-2">
                                                                <div className="col-1">
                                                                    <img src={featherInfo} alt=""
                                                                         className="pe-2 pt-3" style={{width: '30px'}}/>
                                                                </div>
                                                                <div className="col-11">
                                                                    <p className="text-white"><small>Remember this pin, you need this for
                                                                        all payment authorization</small></p>
                                                                </div>
                                                                {pinError && <p className="text-center text-wrap text-danger mt-3 mb-0">{pinError.message}</p>}
                                                            </div>
                                                            <div className="row">
                                                                <ErrorMessage name="otpValue1" component="span" className="invalid-feedback">
                                                                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                                                </ErrorMessage>
                                                                <ErrorMessage name="otpValue2" component="span" className="invalid-feedback">
                                                                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                                                </ErrorMessage>
                                                                <ErrorMessage name="otpValue3" component="span" className="invalid-feedback">
                                                                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                                                </ErrorMessage>
                                                                <ErrorMessage name="otpValue4" component="span" className="invalid-feedback">
                                                                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                                                </ErrorMessage>
                                                                <ErrorMessage name="otpValue5" component="span" className="invalid-feedback">
                                                                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                                                </ErrorMessage>
                                                                <ErrorMessage name="otpValue6" component="span" className="invalid-feedback">
                                                                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                                                </ErrorMessage>
                                                                <ErrorMessage name="otpValue7" component="span" className="invalid-feedback">
                                                                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                                                </ErrorMessage>
                                                                <ErrorMessage name="otpValue8" component="span" className="invalid-feedback">
                                                                    { msg => <div style={{ color: 'red' }}>{msg}</div> }
                                                                </ErrorMessage>
                                                            </div>
                                            </div>
                                            <div className="modal-footer border-0 mx-0">
                                                <div className="row w-100">
                                                    <div className="col-md-6 my-1">
                                                        <button type="submit" className="login-btn btn-hover color-1" >
                                                            {loading ?
                                                                <div className="spinner-border spinner-border-sm text-warning" role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div> : 'Create' }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                )}
                        </Formik>
                    </div>
                </div>
                <button type="button" className="btn-close rounded-circle opacity-100" data-bs-dismiss="modal" onClick={handleClose}></button>
            </div>
        </>
    );
}
