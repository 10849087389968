import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';


// create slice

const name = 'games';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const gameActions = { ...slice.actions, ...extraActions };
export const gameReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        gameItem: [],
        gameCategory: [],
        error: null,
        loading: false
    }
}

function createReducers() {
    return {
        resetGame
    };

    function resetGame(state) {
        state.gameItem = [];
        state.gameCategory = [];
        state.error = null;
        state.loading = false;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/game_store`;

    return {
        getGameList: getGameList(),
        getGameCategories: getGameCategories(),
    };

    function getGameList() {
        return createAsyncThunk(
            `${name}/gameStoreDetails`,
            async () => await fetchWrapper.post(`${baseUrl}/details`, {game_store_id: 1})
        );
    }
    function getGameCategories() {
        return createAsyncThunk(
            `${name}/getGameCategoryDetails`,
            async () => await fetchWrapper.get(`${process.env.REACT_APP_API_URL}/games/categories`)
        );
    }

}

function createExtraReducers() {
    return {
        ...getGameList(),
        ...getGameCategories(),
    };

    function getGameList() {
        var { pending, fulfilled, rejected } = extraActions.getGameList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.gameItem = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state) => {
                state.loading = false;
            }
        };
    }
    function getGameCategories() {
        var { pending, fulfilled, rejected } = extraActions.getGameCategories;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.gameCategory = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state) => {
                state.loading = false;
            }
        };
    }

}
