import React, {useRef, useEffect} from 'react';
import {Formik, Field, Form, ErrorMessage} from 'formik';
import _ from "lodash";

import blockDisabledIcon from "../../assets/images/icon/3994435_ban_banned_block_disabled_stop_icon.svg"
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {friendActions} from "../../../_store";
import {Modal} from "react-bootstrap";
import userPlusIcon from "../../assets/images/icon/Icon feather-user-plus.svg";

export default function BlockFriendModal({isOpen, handleClose}) {
    const dispatch = useDispatch();
    const friends = useSelector(x => x.friends.friends);
    const authUser = useSelector(x => x.auth.profile);
    const user = useSelector(x => x.auth.user);
    const blockFriendFormRef = useRef();

    useEffect(() => {
        const values = {
            profile: authUser?.profile_link,
            appsId: user?.appInfo?.id
        }
        if (authUser) {
            dispatch(friendActions.getFriendsList({values}));
        }
    }, [isOpen]);

    function _handleSubmit(values) {
        dispatch(friendActions.addToBlockList(values)).then(()=>{
            const blockFriendsPayload = {
                profile: authUser?.profile_link,
                appsId: user?.appInfo?.id
            }
            dispatch(friendActions.getBlockFriendsList({values:blockFriendsPayload}));
            handleClose();
        })
    }

    return (
        <Modal
            show={isOpen}
            onHide={handleClose}
            size="md"
            centered
        >
            <Modal.Header closeButton className="row mx-0 border-0 shadow-none">
                <div className="col-md-12 d-flex">
                    <img src={blockDisabledIcon} alt="" style={{width: "30px"}}/>
                    <h4 className="modal-title text-white ms-2">Block Friend</h4>
                </div>
            </Modal.Header>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    block_user_id: '',
                    profile: _.get(authUser,"profile_link",""),
                    appsId: _.get(user,"appInfo.id",""),
                }}
                innerRef={blockFriendFormRef}
                validationSchema={Yup.object().shape({
                    block_user_id: Yup.string().required("Friend Field is required!")
                })}
                onSubmit={(values) => {
                    _handleSubmit(values)
                }}
            >
                <Form>
                    <div className="modal-body row mx-0">
                        <div className="col mx-2 mt-2 form-float btn-add">
                            <Field
                                as="select"
                                name="block_user_id"
                                className="form-select font-13 py-2 text-white refer-select mb-3"
                            >
                                <option value="">Select Friend</option>
                                {friends.map((friend,index) => (
                                    <option
                                        value={friend.id} className="text-white"
                                        key={index}
                                    >
                                        {`${friend.first_name} ${friend.last_name}`}
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage name="block_user_id" component="span" className="invalid-feedback">
                                {msg => <div style={{color: 'red'}}>{msg}</div>}
                            </ErrorMessage>
                        </div>
                    </div>
                    <div className="modal-footer border-0 mx-0">
                        <div className="row w-100">
                            <div className="col-md-6 my-2">
                                <button type="submit" className="danger-btn btn-hover color-5">Submit</button>
                            </div>
                        </div>
                    </div>
                </Form>
            </Formik>
        </Modal>
    );

}
