import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';
import Cookies from "js-cookie";
import {Buffer} from "buffer";

// create slice

const name = 'profiles';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const profileActions = { ...slice.actions, ...extraActions };
export const profilesReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        profile: null,
        profiles: null,
        switchProfile: null,
        pin: null,
        otp: null,
        verify: null,
        error: null,
        loading: false,
        userProfileData: false,
    }
}

function createReducers() {
    return {
        resetProfiles
    };

    function resetProfiles(state) {
        state.profile = null;
        state.profiles = null;
        state.switchProfile = null;
        state.pin = null;
        state.otp = null;   
        state.verify = null;
        state.error = null;
        state.loading = false;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/user`;

    return {
        getAllProfile: getAllProfile(),
        newProfile: newProfile(),
        setPin: setPin(),
        switchProfile: switchProfile(),
        getNewOtpByEmail: getNewOtpByEmail(),
        verifyOtp: verifyOtp(),
        getUserProfiles: getUserProfiles(),
    };

    function getAllProfile() {
        return createAsyncThunk(
            `${name}/getAllProfile`,
            async () => await fetchWrapper.get(`${baseUrl}/profile/details`)
        );
    }

    function newProfile() {
        return createAsyncThunk(
            `${name}/createProfile`,
            async (data) => await fetchWrapper.post(`${baseUrl}/profile/create`, { first_name: data.values.first_name, last_name: data.values.last_name, nick_name: data.values.nickname, date_of_birth: data.values.date_of_birth, profile_picture: data.values.profile_picture})
        );
    }

    function setPin() {
        return createAsyncThunk(
            `${name}/setAuthPin`,
            async (data) => await fetchWrapper.post(`${baseUrl}/set_pin`, { user_id: data.values.user_id, auth_pin: data.values.auth_pin, confirm_pin: data.values.confirm_pin})
        );
    }

    function switchProfile() {
        return createAsyncThunk(
            `${name}/switchProfile`,
            async (data) => await fetchWrapper.post(`${baseUrl}/profile/switch`, { user_id: data.values.user_id, account_id: data.values.account_id, email: data.values.email, user_profile_id: data.values.user_profile_id, first_name: data.values.first_name, last_name: data.values.last_name, auth_token: data.values.auth_token})
        );
    }

    function getNewOtpByEmail() {
        return createAsyncThunk(
            `${name}/getNewOtp`,
            async (data) => await fetchWrapper.post(`${baseUrl}/otp`, {email: data.values.email})
        );
    }

    function verifyOtp() {
        return createAsyncThunk(
            `${name}/verifyOtp`,
            async (data) => await fetchWrapper.post(`${baseUrl}/otp/verify`, {email: data.values.email, auth_otp: data.values.auth_otp})
        );
    }

    function getUserProfiles() {
        return createAsyncThunk(
            `${name}/profiles/get`,
            async (data) => await fetchWrapper.post(`${baseUrl}/profiles/get`, {user_id: data})
        );
    }
}

function createExtraReducers() {
    return {
        ...getProfiles(),
        ...newProfile(),
        ...setPin(),
        ...switchProfile(),
        ...getNewOtpByEmail(),
        ...verifyOtp(),
        ...getUserProfiles(),
    };

    function getProfiles() {
        var {pending, fulfilled, rejected} = extraActions.getAllProfile;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.profiles = action.payload;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }

    function newProfile() {
        var {pending, fulfilled, rejected} = extraActions.newProfile;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.profile = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }

    function setPin() {
        var {pending, fulfilled, rejected} = extraActions.setPin;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.pin = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }

    function switchProfile() {
        var {pending, fulfilled, rejected} = extraActions.switchProfile;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                let outProfileB64 = Buffer.from(JSON.stringify(action.payload.data)).toString("base64");
                state.switchProfile = action.payload.data;
                state.loading = false;
                // localStorage.setItem('register', JSON.stringify(action.payload.data));
                Cookies.set('jwt', action.payload.data.api_token, {expires: 7});
                localStorage.setItem('profile', JSON.stringify(outProfileB64));
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }

    function getNewOtpByEmail() {
        var {pending, fulfilled, rejected} = extraActions.getNewOtpByEmail;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.otp = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }

    function verifyOtp() {
        var {pending, fulfilled, rejected} = extraActions.verifyOtp;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.verify = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }

    function getUserProfiles() {
        var {pending, fulfilled, rejected} = extraActions.getUserProfiles;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.userProfileData = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }
}
