import React from 'react';
import appStoreIcon from "../assets/images/icon/appstore_white.svg";
import playStoreIcon from "../assets/images/icon/playstore_white.svg";

const DownloadVCoinApp = ({height = 130}) => {
    return (
        <>
            <img
                src={appStoreIcon} alt="app store"
                className="pe-2 cursor-pointer" style={{width: height}}
                onClick={()=>window.open("https://apps.apple.com/in/app/virtuaswap/id1626400888","_blank")}
            />
            <img
                src={playStoreIcon} alt="play store"
                className="pe-2 cursor-pointer" style={{width: height}}
                onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.vt.virtuacoin","_blank")}
            />
        </>
    );
};

export default DownloadVCoinApp;