import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Buffer} from 'buffer';
import {fetchWrapper} from '../_helpers';
import Cookies from 'js-cookie';
// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({name, initialState, extraReducers});

// exports

export const authActions = {...slice.actions, ...extraActions};
export const authReducer = slice.reducer;

// implementation

function createInitialState() {
    const userData = JSON.parse(localStorage.getItem("register"));
    const profileData = localStorage.hasOwnProperty("profile") ? Buffer.from(JSON.parse(localStorage.getItem('profile')), "base64").toString() : null;
    return {
        // initialize state from local storage to enable user to stay logged in
        user: userData,
        profile: JSON.parse(profileData),
        error: null,
        loading: false,
        userData: null,
        avatarLists: []
    }
}

function createReducers() {
    return {
        logout
    };

    function logout(state) {
        state.user = null;
        state.profile = null;
        localStorage.removeItem('profile');
        //history.navigate('/login');
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/user`;
    const AccountUrl = `${process.env.REACT_APP_API_URL}/account`;

    return {
        login: login(),
        logOut: logOut(),
        getUserDetails: getUserDetails(),
        getUserAvatars: getUserAvatars(),
        deleteAccount: deleteAccount(),
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async (data) => {
                const response = await fetchWrapper.post(`${baseUrl}/signin`, {
                    email: data.values.email,
                    password: data.values.password,
                    app_id: 'FLF2KVSBG72K6O'
                })
                return {...response,remember_me:data.values.remember_me};
            }
        )
    }

    function logOut() {
        return createAsyncThunk(
            `${name}/logout`,
            async (data) => await fetchWrapper.post(`${baseUrl}/signout`, {user_id: data.values.user_id})
        )
    }

    function getUserDetails() {
        return createAsyncThunk(
            `${name}/user/details`,
            async (data) => await fetchWrapper.post(`${baseUrl}/details`, {user_id: data})
        )
    }

    function getUserAvatars() {
        return createAsyncThunk(
            `${name}/user/avatar/get`,
            async () => await fetchWrapper.get(`${baseUrl}/avatar/get`)
        )
    }

    function deleteAccount() {
        return createAsyncThunk(
            `${name}/deleteAccount`,
            async (data) => await fetchWrapper.post(`${AccountUrl}/delete`, data)
        );
    }
}

function createExtraReducers() {
    return {
        ...login(),
        ...logout(),
        ...getUserDetailData(),
        ...getUserAvatars(),
        ...deleteAccount(),
    };

    function login() {
        var {pending, fulfilled, rejected} = extraActions.login;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                let user = action.payload.data;
                let active_profile = user.user_profiles.filter(function (value) {
                    if (value.default_profile === "Yes") {
                        return value
                    }
                });
                const api_token = user.api_token
                let profileJsonData = JSON.stringify(active_profile[0]);
                let outProfileB64 = Buffer.from(profileJsonData).toString("base64");

                Cookies.set('jwt', api_token, {expires: action.payload.remember_me ? 365 : 7});
                localStorage.setItem('register', JSON.stringify(user));
                localStorage.setItem('profile', JSON.stringify(outProfileB64));
                state.user = user;
                state.profile = active_profile[0];
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }

    function logout() {
        var {pending, fulfilled, rejected} = extraActions.logOut;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                const user = action.payload.data;
                state.user = null;
                state.profile = null;
                state.loading = false;
                localStorage.removeItem('profile');
                localStorage.removeItem('register');
                Cookies.remove('jwt');
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }

    function getUserDetailData() {
        var {pending, fulfilled, rejected} = extraActions.getUserDetails;
        return {
            [pending]: (state) => {
                state.error = null;
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.profile = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.error = action.error;
                state.loading = false;
            }
        };
    }
    function getUserAvatars() {
        var {pending, fulfilled, rejected} = extraActions.getUserAvatars;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.avatarLists = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }
    function deleteAccount() {
        var {pending, fulfilled, rejected} = extraActions.deleteAccount;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.user = null;
                state.profile = null;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }
}
