import React, {useState, useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';
import {friendActions} from "../../_store";

import AddFriendModal from "../../components/layout/modal/AddFriendModal";
import BlockFriendModal from "../../components/layout/modal/BlockFriendModal";
import { toast } from 'react-toastify';

import userIconPlus from "../../components/assets/images/icon/icon-user-plus.svg";
import playerName from "../../components/assets/images/icon/player-name.svg";
import kingIcon from "../../components/assets/images/icon/king-icon.svg";
import flagIcon from "../../components/assets/images/icon/flag.svg";
import userIconWhite from "../../components/assets/images/icon/icon-user-white.svg";
import playerName1 from "../../components/assets/images/icon/player-name1.svg";
import masterIcon from "../../components/assets/images/icon/master.svg";
import flagIcon1 from "../../components/assets/images/icon/flag1.svg";
import playerName2 from "../../components/assets/images/icon/player-name2.svg";
import rookieIcon from "../../components/assets/images/icon/rookie.svg";
import flagIcon2 from "../../components/assets/images/icon/flag2.svg";
import playerName3 from "../../components/assets/images/icon/player-name3.svg";
import flagIcon3 from "../../components/assets/images/icon/flag3.svg";
import playerName4 from "../../components/assets/images/icon/player-name4.svg";
import flagIcon4 from "../../components/assets/images/icon/flag4.svg";
import flagIcon5 from "../../components/assets/images/icon/flag5.svg";
import flagIcon6 from "../../components/assets/images/icon/flag6.svg";
import acceptIcon from "../../components/assets/images/icon/accept.svg";
import rejectIcon from "../../components/assets/images/icon/reject.svg";
import addBlockPlus from "../../components/assets/images/icon/add-block-plus.svg";
import unBlockIcon from "../../components/assets/images/icon/unblock.svg";

export default  function Friends() {

    const [activeTab, setActiveTab] = useState('friend-list-tab');
    const [addFriendModal, setAddFriendModal] = useState(false);
    const [blockFriendModal, setBlockFriendModal] = useState(false);

    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authProfile = useSelector(x => x.auth.profile);
    const loading = useSelector(x => x.friends.loading);
    const friends = useSelector(x => x.friends.friends);
    const pendingRequestFriends = useSelector(x => x.friends.pendingRequestFriends);
    const blockFriends = useSelector(x => x.friends.friendBlockList);

    useEffect(() => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id
        }

        if (authUser) {
            dispatch(friendActions.getFriendsList({values}));
        }

    }, [dispatch]);
    const getActiveData = (tab) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id
        }
        if (tab === 'friend-list-tab') {
            dispatch(friendActions.getFriendsList({values}));
        } else if (tab === 'request-pending') {
            dispatch(friendActions.getPendingRequestFriendsList({values}));
        } else if (tab === 'block-list-tab') {
            dispatch(friendActions.getBlockFriendsList({values}))
        } else {
            return false
        }
    }

    const closeAddFriendModal = () => {
        setAddFriendModal(false);
    }

    const closeBlockFriendModal = () => {
        setBlockFriendModal(false);
    }

    /* Unfriend Request */
    const unFriendRequest = (item) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id,
            friend_id: item.id
        }
        return dispatch(friendActions.removeFriend({values}))
            .then(()=>getActiveData('friend-list-tab'))
    }

    /*  Request Pending Start*/
    const acceptFriendRequest = (item) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id,
            friend_id: item.id
        }

        dispatch(friendActions.acceptFriendRequest({values})).then((response) => {
            if (response.payload?.message) {
                toast.success(response.payload.message);
                getActiveData("request-pending");
            }
            if (response?.error) {
                toast.error(response.error.message);
            }
        });
    }
    const rejectFriendRequest = (item) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id,
            friend_id: item.id
        }

        dispatch(friendActions.declineFriendRequest({values})).then((response) => {
            if (response.payload?.message) {
                toast.success(response.payload.message);
                getActiveData("request-pending");
            }
            if (response?.error) {
                toast.error(response.error.message);
            }
        });
    }
    /*  Request Pending End */

    /* Unblock Request */
    const unBlockFriendRequest = (item) => {
        const values = {
            profile: authProfile?.profile_link,
            appsId: authUser?.appInfo?.id,
            block_user_id: item.id
        }
        return dispatch(friendActions.removeBlockListFriend({values}))
            .then(()=>getActiveData("block-list-tab"))
    }

    return (
        <>
        <div className="friend-body-content">
            <div className="row py-4">
                <div className="col-md-3">
                    <div className="friend-heading text-white text-uppercase ms-2">
                        <h1>Friends</h1>
                    </div>
                </div>
                <div className="col-md-9">
                    <ul className="nav nav-tabs leaderboard border-0 justify-content-end" id="myTab" role="tablist">
                        <li className="nav-item me-2" role="presentation">
                            <button className={"nav-link border-0 border-left-radius text-white " + (activeTab === 'friend-list-tab' ? 'active' : '')}  onClick={() => {setActiveTab('friend-list-tab');getActiveData('friend-list-tab')}}
                                    id="friend-list-tab1" data-bs-toggle="tab" data-bs-target="#friend-list-tab"
                                    type="button" role="tab" aria-controls="friend-list-tab"
                                    aria-selected="true">Friend List
                            </button>
                        </li>
                        <li className="nav-item me-2" role="presentation">
                            <button className={"nav-link border-0 text-white " + (activeTab === 'request-pending' ? 'active' : '')}  onClick={() => {setActiveTab('request-pending');getActiveData('request-pending')}}
                                    id="request-pending1" data-bs-toggle="tab"
                                    data-bs-target="#request-pending" type="button" role="tab"
                                    aria-controls="request-pending" aria-selected="false">Request Pending
                            </button>
                        </li>
                        <li className="nav-item me-2" role="presentation">
                            <button className={"nav-link border-0 border-right-radius text-white " + (activeTab === 'block-list-tab' ? 'active' : '')}  onClick={() => {setActiveTab('block-list-tab');getActiveData('block-list-tab')}}
                                    id="block-list1"
                                    data-bs-toggle="tab" data-bs-target="#block-list-tab" type="button" role="tab"
                                    aria-controls="block-list-tab" aria-selected="false">Block List
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="tab-content px-2" id="myTabContent">
                <div className={"tab-pane fade show " + (activeTab === 'friend-list-tab' ? 'active' : '')}  id="friend-list-tab" role="tabpanel" aria-labelledby="friend-list-tab1" tabIndex="0">
                    <div className="modal-body border-0 mx-0 d-block">
                        <div className="row">
                            <div className="col-md-12 table-responsive">
                                <div className="table-top-search">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <div className="table-search">
                                                <div className="input-group align-items-center">
                                                    <input
                                                        type="text"
                                                        className="form-control rounded text-white border-0"
                                                        placeholder="Search"
                                                    />
                                                    <i className="fa-solid fa-magnifying-glass mt-0"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="add-friend-btn text-center">
                                                <button
                                                    type="button" className="btn-hover color-1 d-flex gap-2 align-items-center justify-content-center"
                                                    onClick={() => setAddFriendModal(true)}
                                                >
                                                    <img className="w-auto" src={userIconPlus} alt="add friend icon"/>
                                                    Add Friend
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table className="table w-100 text-white plalyer-list">
                                    <tbody className="align-middle">
                                    {friends.length === 0 &&
                                        <tr>
                                            <td className="text-center text-white-50">No friends available</td>
                                        </tr>
                                    }
                                    {friends && friends.map((item,index) => (
                                        <tr key={index}>
                                            <td className="player-name ps-3 col-5">
                                                <img className="me-2" src={playerName} alt=""/> {item.first_name} {item.last_name}
                                            </td>
                                            <td className="green-color col-2">Level 11</td>
                                            <td className="pink-color col-2">
                                                <img src={kingIcon} className="w-auto" alt=""/> King</td>
                                            <td className="col-2">
                                                <img className="w-auto" src={flagIcon} alt=""/></td>
                                            <td className="unfriend-btn col-2">
                                                <button className="text-white text-center btn-hover color-3" onClick={() => unFriendRequest(item)}>
                                                    <img className="w-auto" src={userIconWhite} alt=""/> Unfriend
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"tab-pane fade show " + (activeTab === 'request-pending' ? 'active' : '')} id="request-pending" role="tabpanel"
                     aria-labelledby="request-pending1" tabIndex="0">
                    <div className="modal-body border-0 mx-0 d-block">
                        <div className="row">
                            <div className="col-md-12 table-responsive">
                                <table className="table w-100 text-white plalyer-list">
                                    <tbody className="align-middle">
                                    {pendingRequestFriends.length === 0 &&
                                        <tr>
                                            <td className="text-center text-white-50">No Pending Request available</td>
                                        </tr>
                                    }
                                    {pendingRequestFriends && pendingRequestFriends.map((item,index) => (
                                      <tr key={index}>
                                        <td className="player-name ps-3 col-3"><img className="me-2" src={playerName} alt=""/> {item.first_name} {item.last_name}</td>
                                        <td className="green-color col-7">Level 11</td>
                                        <td className="accept-btn">
                                            <button className="text-center btn-hover color-4" onClick={() => acceptFriendRequest(item)}>
                                                <img className="w-auto" src={acceptIcon} alt="accept btn"/> Accept
                                            </button>
                                        </td>
                                            <td className="reject-btn">
                                                <button className="text-white text-center btn-hover color-3" onClick={() => rejectFriendRequest(item)}>
                                                    <img className="w-auto" src={rejectIcon} alt="reject btn"/> Reject
                                                </button>
                                            </td>
                                       </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"tab-pane fade show " + (activeTab === 'block-list-tab' ? 'active' : '')} id="block-list-tab" role="tabpanel"
                     aria-labelledby="block-list-tab1" tabIndex="0">
                    <div className="modal-body border-0 mx-0 d-block">
                        <div className="row">
                            <div className="col-md-12 table-responsive">
                                <div className="table-top-search">
                                    <div className="row">
                                        <div className="col-md-10">
                                            <div className="table-search">
                                                <div className="input-group align-items-center">
                                                    <input type="text"
                                                           className="form-control rounded text-white border-0"
                                                           placeholder="Search"/>
                                                    <i className="fa-solid fa-magnifying-glass mt-0"></i>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="add-block-list text-center">
                                                <button
                                                    type="button" className="btn-hover color-5 d-flex gap-2 align-items-center justify-content-center"
                                                    onClick={() => setBlockFriendModal(true)}
                                                >
                                                    <img className="w-auto" src={addBlockPlus} alt="add block list icon"/>
                                                    Add to Block List
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <table className="table w-100 text-white plalyer-list">

                                    <tbody className="align-middle">
                                    {blockFriends.length === 0 &&
                                    <tr>
                                        <td className="text-center text-white-50">No block user available</td>
                                    </tr>
                                    }
                                    {blockFriends && blockFriends.map((item,index) => (
                                        <tr key={index}>
                                            <td className="player-name ps-3 col-3">
                                                <img className="me-2" src={playerName} alt=""/> John Smith
                                            </td>
                                            <td className="green-color w-50">Level 11</td>
                                            <td className="unblock-btn">
                                                <button className="text-center btn-hover color-1" onClick={() => unBlockFriendRequest(item)}>
                                                    <img className="w-auto" src={unBlockIcon} alt=""/> Unblock
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <AddFriendModal isOpen={addFriendModal} handleClose={closeAddFriendModal} />
        <BlockFriendModal isOpen={blockFriendModal} handleClose={closeBlockFriendModal}/>
        </>
    );
}


