import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '../_helpers';


// create slice

const name = 'shop';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const shopActions = { ...slice.actions, ...extraActions };
export const shopReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        shopItem: [],
        packages: [],
        error: null,
        loading: false
    }
}

function createReducers() {
    return {
        resetShop
    };

    function resetShop(state) {
        state.shopItem = [];
        state.packages = [];
        state.error = null;
        state.loading = false;
    }
}

function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/user/shop`;
    const baseUrlPackages = `${process.env.REACT_APP_API_URL}/packages`;

    return {
        getShopList: getShopList(),
        getPackageList: getPackageList(),
    };

    function getShopList() {
        return createAsyncThunk(
            `${name}/getShopDetails`,
            async (data) => await fetchWrapper.post(`${baseUrl}/details`, {user_id: data.values.user_id})
        );
    }
    function getPackageList() {
        return createAsyncThunk(
            `${name}/getPackages`,
            async () => await fetchWrapper.get(`${baseUrlPackages}/get`)
        );
    }
}

function createExtraReducers() {
    return {
        ...getShopList(),
        ...getPackageList(),
    };

    function getShopList() {
        var { pending, fulfilled, rejected } = extraActions.getShopList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.shopItem = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }
    function getPackageList() {
        var { pending, fulfilled, rejected } = extraActions.getPackageList;
        return {
            [pending]: (state) => {
                state.loading = true;
            },
            [fulfilled]: (state, action) => {
                state.packages = action.payload.data;
                state.loading = false;
            },
            [rejected]: (state, action) => {
                state.loading = false;
            }
        };
    }

}
