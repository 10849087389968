import React, {useRef, useEffect, useState} from "react";
import {Formik, Field, Form, ErrorMessage} from "formik";
import RegOtpModal, {RESEND_OTP_URL} from "./RegOtpModal";
import svgBackImg from "../../assets/images/icon/Group 2344.svg";
import svgPersonImg from "../../assets/images/lock_icon.png";
import awesomeLockIcon from "../../assets/images/icon/Icon awesome-lock.svg";
import * as Yup from "yup";
import axios from "axios";
import {toast} from "react-toastify";

export default function ForgotPasswordModal({
                                                isOpen,
                                                handleClose,
                                                handleBack,
                                                modelData
                                            }) {
    const [forgotOtpModal, setForgotOtpModal] = useState(false);
    const forgotFormRef = useRef();
    const forgotModalRef = useRef();
    const [value, setValue] = useState();

    const closeForgotOtpModal = (e) => {
        if (e) {
            // document.querySelectorAll(".modal-backdrop").forEach((e) => e.remove());
            setForgotOtpModal(false);
            return handleClose(true);
        } else {
            document.querySelectorAll(".modal-backdrop").forEach((e) => e.remove());
            setForgotOtpModal(false);
            return handleClose(false);
        }
    };
    const backLoginModal = () => {
        document.querySelectorAll(".modal-backdrop").forEach(e => e.remove());
        setForgotOtpModal(false);
    };
    const _handleSubmit = (values) => {
        return axios.post(RESEND_OTP_URL, {email: values.email}).then((res) => {
            if (!res.data.error) {
                setValue({email: values.email});
                setForgotOtpModal(true);
            } else {
                toast.error(res.data.message)
                setForgotOtpModal(false);
            }
        });
    };
    return (
        <>
            <div
                className={"modal fade " + (isOpen ? "show" : "hide")}
                style={{display: isOpen ? "block" : "none"}}
                id="forgotPassword"
                role="dialog"
                tabIndex={10}
                aria-labelledby="forgotPasswordMailModalLable"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className={`modal-content ${forgotOtpModal ? "d-none" : ""}`}
                         onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header row mx-0 border-0 shadow-none">
                            <div className="col-md-6 d-flex align-items-center">
                                <img src={svgPersonImg} height="23px" alt=""/>
                                <h4
                                    className="modal-title text-white ms-2"
                                    id="forgotPasswordMailModalLable"
                                >
                                    {modelData.title}
                                </h4>
                            </div>
                            <div className="col-md-6 d-flex justify-content-end">
                                <img
                                    className="virtualogin w-auto"
                                    src={require("../../assets/images/virtualogin.png")}
                                    alt="virtualogin"
                                />
                            </div>
                        </div>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                email: "",
                            }}
                            innerRef={forgotFormRef}
                            validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email("Invalid Email address")
                                    .required("Email is required!"),
                            })}
                            onSubmit={_handleSubmit}
                        >
                            {({values, errors, touched, handleReset, isSubmitting}) => (
                                <Form>
                                    <>
                                        <div className="modal-body row mx-0">
                                            <div className="col mt-2">
                                                <div className="material-textfield">
                                                    <Field
                                                        type="email"
                                                        name="email"
                                                        placeholder="Enter your email to reset your password"
                                                    />
                                                    <label> Enter your email to reset your password </label>
                                                </div>
                                                <ErrorMessage
                                                    name="email"
                                                    component="span"
                                                    className="invalid-feedback"
                                                >
                                                    {(msg) => <div style={{color: "red"}}>{msg}</div>}
                                                </ErrorMessage>
                                            </div>
                                        </div>
                                        <div className="modal-footer border-0 mx-0">
                                            <div className="row w-100">
                                                <div className="col-md-12 my-1 d-flex">
                                                    <button
                                                        type="button"
                                                        className="no-btn-small me-4"
                                                        data-bs-target={`${modelData.title === "Reset Password" ? "#profileEditModal" : "#loginModel"}`}
                                                        role="modal"
                                                        data-bs-toggle="modal"
                                                        onClick={() => {
                                                            forgotFormRef?.current?.resetForm();
                                                            return handleBack(true)
                                                        }}
                                                    >
                                                        <img src={svgBackImg} alt=""/>
                                                    </button>

                                                    <button
                                                        type="submit"
                                                        id="sendPasswordMail"
                                                        className="login-btn btn-hover color-1"
                                                    >
                                                        {isSubmitting ? (
                                                            <div
                                                                className="spinner-border spinner-border-sm text-warning"
                                                                role="status"
                                                            >
                                                              <span className="visually-hidden">
                                                                Loading...
                                                              </span>
                                                            </div>
                                                        ) : "Submit"}
                                                    < /button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </Form>
                            )}
                        </Formik>
                        <button
                            type="button"
                            className="btn-close rounded-circle opacity-100"
                            onClick={() => {
                                forgotFormRef?.current?.resetForm();
                                return handleBack(true)
                            }}
                        ></button>
                    </div>
                </div>

                <RegOtpModal
                    isOpen={forgotOtpModal}
                    handleClose={(e) => {
                        closeForgotOtpModal(e)
                    }}
                    handleBack={backLoginModal}
                    value={value}
                    modelData={modelData}
                />
            </div>
        </>
    );
}
