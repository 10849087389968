import React from "react";
import { Link, useLocation } from "react-router-dom";

function SidebarMenu() {
    const pathname = useLocation().pathname;
    return (
        <div className="col-lg-1 col-md-1 px-0 pt-5 sidebarMenu">
            <nav className="side-nav align-items-start pt-5">
                {/*<ul className="nav-menu mb-0">*/}
                {/*    <SidebarMenuItem to='/' title={"Home"}/>*/}
                {/*    /!*<SidebarMenuIte  m to='/chat.html' title='ABOUT'/>*!/*/}
                {/*</ul>*/}
                <ul className="nav-menu mb-0">
                    <li className={"nav-item " + (pathname === '/' || pathname === '/home' ? 'active' : '')}>
                        <Link to="/">
                            <img className="mx-auto icon-white" src="/assets/images/icon/home.svg" alt="" />
                                <img className="mx-auto icon-green" src="/assets/images/icon/home-green.svg" alt="" />
                                    <span className="font-10">Home</span>
                        </Link>
                    </li>
                    <li className={"nav-item " + (pathname === '/favorite' ? 'active' : '')}>
                        <Link to="/favorite">
                            <img className="mx-auto icon-white" src="/assets/images/icon/star_icon.svg" alt="" />
                                <img className="mx-auto icon-green" src="/assets/images/icon/star-green.svg" alt="" />
                                    <span className="font-10">Favorite</span>
                        </Link>
                    </li>
                    <li className={"nav-item " + (pathname === '/friends' ? 'active' : '')}>
                        <Link to="/friends">
                            <img className="mx-auto icon-white" src="/assets/images/icon/users-icon.svg" alt="" />
                                <img className="mx-auto icon-green" src="/assets/images/icon/users-green.svg" alt="" />
                                    <span className="font-10">Friends</span>
                        </Link>
                    </li>
                    <li className={"nav-item " + (pathname === '/chat' ? 'active' : '')}>
                        <Link to="/chat">
                            <span className="message rounded-circle font-10">5</span>
                            <img className="mx-auto icon-white" src="/assets/images/icon/chat_icon.svg" alt="" />
                                <img className="mx-auto icon-green" src="/assets/images/icon/chat-icon-green.svg" alt="" />
                                    <span className="font-10">Chat</span>
                        </Link>
                    </li>
                    <li className={"nav-item " + (pathname === '/shop' ? 'active' : '')}>
                        <Link to="/shop">
                            <img className="mx-auto icon-white" src="/assets/images/icon/shopping_icon.svg" alt="" />
                                <img className="mx-auto icon-green" src="/assets/images/icon/shopping_icon-green.svg"
                                     alt="" />
                                    <span className="font-10">Store</span>
                        </Link>
                    </li>
                    <li className={"nav-item " + (pathname === '/refer_earn' ? 'active' : '')}>
                        <Link to="/refer_earn">
                            <img className="mx-auto icon-white" src="/assets/images/icon/refer-icon.svg" alt="" />
                                <img className="mx-auto icon-green" src="/assets/images/icon/refer-icon-green.svg"
                                     alt="" />
                                    <span className="font-10">Refer & Earn</span>
                        </Link>
                    </li>
                    <li className={"nav-item " + (pathname === '/virtua_swap' ? 'active' : '')}>
                        <Link to="/virtua_swap">
                            <img className="mx-auto" src="/assets/images/icon/virtua.svg" alt="" />
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>

    );
}

export default SidebarMenu;
